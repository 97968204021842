import { ExtendedPatient } from "@aspire/common";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { PatientCardExtended } from "~/components/design-system/index.js";

export type SearchResultData = {
  type: "existing-patient";
  patient: ExtendedPatient;
};

type SelectedProps = {
  selected: boolean;
  setSelected: () => void;
};

export type SearchResultProps = SearchResultAspireLocalPatientProps &
  SelectedProps;

export const SearchResult = ({
  searchResult,
  selected,
  setSelected,
}: SearchResultProps) => {
  switch (searchResult.type) {
    case "aspire-patient":
      return (
        <SearchResultAspireLocalPatient
          searchResult={searchResult}
          selected={selected}
          setSelected={setSelected}
        />
      );
    default: {
      console.error("Unknown search result type", searchResult);
      return null;
    }
  }
};

/**
 * A SearchResult for an Aspire Patient
 */
export type SearchResultAspireLocalPatientProps = {
  searchResult: { type: "aspire-patient"; patient: ExtendedPatient };
};

export const SearchResultAspireLocalPatient = ({
  searchResult,
  selected,
  setSelected,
}: SearchResultAspireLocalPatientProps & SelectedProps) => {
  const patientId = searchResult.patient.id;
  const patientName = `${searchResult.patient.name.given}  ${searchResult.patient.name.family}`;
  const address = searchResult.patient.address.address;
  const patientDateOfBirth = useFormattedDate(searchResult.patient.dateOfBirth);
  const patientAge = useAgeFromDateOfBirth(searchResult.patient.dateOfBirth);

  return (
    <PatientCardExtended
      patientId={patientId}
      patientName={patientName}
      address={address}
      patientDateOfBirth={patientDateOfBirth}
      patientAge={patientAge}
      isSelected={selected}
      setIsSelected={() => setSelected()}
    />
  );
};

const useFormattedDate = (date: string | null | undefined) => {
  const { t } = useTranslation();

  return date ? dayjs(date).format("DD/MM/YYYY") : t("common.unknown");
};

const useAgeFromDateOfBirth = (dateOfBirth: string | null | undefined) => {
  const { t } = useTranslation();

  return dateOfBirth
    ? dayjs().diff(dateOfBirth, "year").toString()
    : t("common.unknown");
};
