import { ExtendedThalamosUser, isIndependentUserSession } from "@aspire/common";
import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Banner, BannerList } from "~/components/design-system/index.js";
import { routeFns } from "../../routes.js";
import { WorkMode } from "./types.js";

export default function NoResultsBanners({
  mode,
  noSearchOrFilterResults,
  user,
}: {
  mode: WorkMode;
  noSearchOrFilterResults: boolean;
  user: ExtendedThalamosUser;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginBottom: "2em",
          mt: 2,
        }}
      >
        No Results
      </Box>
      <Box>
        {mode === "caseload" && (
          <>
            {noSearchOrFilterResults ? (
              <>
                {isIndependentUserSession(user) && (
                  <Banner
                    title={t("pages.caseload.noResultsWhenSearchBanner")}
                    bannerType={BannerList.WARNING}
                    iconOff
                    onRightButton={() => navigate(routeFns.patientSearch())}
                    rightButtonLabel={t("buttonLabels.createPatient")}
                  ></Banner>
                )}
                {!isIndependentUserSession(user) && (
                  <Banner
                    title={t("pages.caseload.noResultsSearchBannerTeam")}
                    bannerType={BannerList.WARNING}
                    iconOff
                    onRightButton={() => navigate(routeFns.patientSearch())}
                    rightButtonLabel={t("buttonLabels.searchPatient")}
                  ></Banner>
                )}
              </>
            ) : (
              <>
                {isIndependentUserSession(user) && (
                  <Banner
                    title={t("pages.caseload.noResultsSearchBanner")}
                    bannerType={BannerList.WARNING}
                    iconOff
                    onRightButton={() => navigate(routeFns.patientCreate())}
                    rightButtonLabel={t("buttonLabels.createPatient")}
                  ></Banner>
                )}
                {!isIndependentUserSession(user) && (
                  <Banner
                    title={t("pages.caseload.noResultsWhenSearchBannerTeam")}
                    bannerType={BannerList.WARNING}
                    iconOff
                    onRightButton={() => navigate(routeFns.patientSearch())}
                    rightButtonLabel={t("buttonLabels.searchPatient")}
                  ></Banner>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}
