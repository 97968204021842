import { isAdminUser } from "@aspire/common";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Button,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { Base64FileUpload } from "~/components/form/index.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { routeFns } from "~/routes.js";
import { api } from "../../api.js";
import { decodeBase64 } from "../../util.js";
import { OrganisationPicker } from "./OrganisationPicker.js";

function UserMembershipConfiguration({}: {}) {
  const [dryRunResult, setDryRunResult] = React.useState<AxiosResponse<
    void,
    any
  > | null>(null);

  const [csvData, setCsvData] = React.useState<string | null>(null);
  const [submitting, setSubmitting] = React.useState(false);

  const isValid = dryRunResult?.status === 204;
  return (
    <Box>
      <Typography sx={{ marginBottom: "0.3em" }} variant="h4">
        Bulk User Configuration
      </Typography>
      <Typography sx={{ marginBottom: "1em" }} variant={"body2"}>
        Bulk configuration of team memberships for existing users (doesn't allow
        creation of users yet!). Upload a CSV with an 'email' column for the
        user email and one or more 'team' columns for the team names. The team
        names must match the names of existing teams. Place an X in the cell to
        add the user to the team. All existing team memberships will be removed
        before adding the user to the specified teams!! A dry run will be
        performed before submitting the changes.
      </Typography>
      <Base64FileUpload
        accept={".csv"}
        onDone={async (uploadedFiles) => {
          if (uploadedFiles.length === 1) {
            const csvData = decodeBase64(
              (uploadedFiles[0].base64 as string).split(",")[1]!,
            );

            const response = await api.users.bulkConfigure(csvData, true);

            setCsvData(csvData);
            setDryRunResult(response);
          }
        }}
        label={"Upload a CSV"}
      />
      <Box>
        {dryRunResult && (
          <Box
            sx={{
              mt: "1em",
              mb: "1em",
              border: isValid ? "1px solid black" : "1px solid red",
              padding: "0.5em",
            }}
          >
            <Typography>{isValid ? "Valid File" : "Errors:"}</Typography>
            <Typography>{(dryRunResult.data as any).reason}</Typography>
          </Box>
        )}
        {isValid && (
          <Button
            label={"Submit"}
            disabled={submitting}
            onClick={async () => {
              setSubmitting(true);
              const result = await api.users.bulkConfigure(csvData!, false);

              setSubmitting(false);
              setCsvData(null);
              setDryRunResult(null);

              if (result.status === 204) {
                renderSuccessToast({
                  message: "Successfully configured users",
                });
              } else {
                renderErrorToast({
                  message: `Failed to configure users: ${
                    (result.data as any).reason
                  }`,
                });
              }
            }}
          />
        )}
      </Box>
    </Box>
  );
}

function UserCreation({}: {}) {
  const [dryRunResult, setDryRunResult] = React.useState<AxiosResponse<
    void,
    any
  > | null>(null);

  const [data, setData] = useState<{
    managingOrganisationId?: string | null;
    managingOrganisationName?: string | null;
  }>({});
  const [csvData, setCsvData] = React.useState<string | null>(null);
  const [submitting, setSubmitting] = React.useState(false);

  const isValid = dryRunResult?.status === 204;
  return (
    <Box>
      <Typography sx={{ marginBottom: "0.3em" }} variant="h4">
        Bulk User Creation
      </Typography>
      <Typography sx={{ marginBottom: "1em" }} variant={"body2"}>
        Bulk configuration of team memberships for creating users. Upload a CSV
        with an 'email' column, a 'name' column and an 'mfa' column. The 'mfa'
        column should be left blank for users without MFA and set to 'X' if it
        is. A dry run will be performed before submitting the changes to
        validate the file and ensure the email addresses are unique and not
        already in use.
      </Typography>
      <OrganisationPicker
        error={null}
        idField={"managingOrganisationId"}
        nameField={"managingOrganisationName"}
        values={data}
        setValues={setData}
        label={"User managing organisation ID (optional)"}
        filterFn={(o) => !!o.isUserManagementOrganisation}
      />
      <Base64FileUpload
        accept={".csv"}
        onDone={async (uploadedFiles) => {
          if (uploadedFiles.length === 1) {
            const csvData = decodeBase64(
              (uploadedFiles[0].base64 as string).split(",")[1]!,
            );

            const response = await api.users.bulkCreate(
              csvData,
              true,
              data.managingOrganisationId ?? undefined,
            );

            setCsvData(csvData);
            setDryRunResult(response);
          }
        }}
        label={"Upload a CSV"}
      />
      <Box>
        {dryRunResult && (
          <Box
            sx={{
              mt: "1em",
              mb: "1em",
              border: isValid ? "1px solid black" : "1px solid red",
              padding: "0.5em",
            }}
          >
            <Typography>{isValid ? "Valid File" : "Errors:"}</Typography>
            <Typography>{(dryRunResult.data as any).reason}</Typography>
          </Box>
        )}
        {isValid && (
          <Button
            label={"Submit"}
            disabled={submitting}
            onClick={async () => {
              setSubmitting(true);
              const result = await api.users.bulkCreate(
                csvData!,
                false,
                data.managingOrganisationId ?? undefined,
              );

              setSubmitting(false);
              setCsvData(null);
              setDryRunResult(null);

              if (result.status === 204) {
                renderSuccessToast({
                  message: "Successfully created users",
                });
              } else {
                renderErrorToast({
                  message: `Failed to create users: ${
                    (result.data as any).reason
                  }`,
                });
              }
            }}
          />
        )}
      </Box>
    </Box>
  );
}

export function BulkUserConfigurationPage({}: {}) {
  const [tab, setTab] = useState(0);

  const navigate = useNavigate();
  const userContext = React.useContext(LoggedInUserContext);

  useEffect(() => {
    if (!isAdminUser(userContext?.user)) {
      navigate(routeFns.home());
    }
  }, [userContext?.user]);
  const { pathname } = useLocation();

  React.useEffect(() => {
    switch (pathname) {
      case routeFns.adminBulkCreateUsersPage():
        setTab(0);
        break;
      case routeFns.adminBulkUsersPage():
        setTab(1);
        break;
    }
  }, [pathname]);

  return (
    <Box>
      <Tabs
        sx={{ marginBottom: "1em" }}
        value={tab}
        onChange={(ev, value) => {
          if (value === 0) {
            navigate(routeFns.adminBulkCreateUsersPage());
          } else if (value === 1) {
            navigate(routeFns.adminBulkUsersPage());
          }
        }}
      >
        <Tab label="Create Users" value={0} />
        <Tab label="Configure User Memberships" value={1} />
      </Tabs>
      <Box>
        {tab === 0 && <UserCreation />}
        {tab === 1 && <UserMembershipConfiguration />}
      </Box>
    </Box>
  );
}
