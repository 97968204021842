import { css } from "@emotion/react";
import { Sync as SyncIcon } from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import React from "react";

export const LoadingSpinner = ({
  color,
  label,
}: {
  color?: string;
  label?: string;
}) => {
  const theme = useTheme();
  const styles = {
    animatedLoading: css`
      animation: spinner 2s linear infinite;

      @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `,
  };
  return (
    <Box
      display="flex"
      gap={theme.spacing(1)}
      color={color}
      data-testid="loading-spinner"
    >
      <SyncIcon css={styles.animatedLoading} htmlColor={color} />
      {label ? ` ${label}` : ""}
    </Box>
  );
};
