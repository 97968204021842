import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routeFns } from "../../routes.js";
import { PatientCreateEdit } from "./PatientCreateEdit.js";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { WithPatient } from "../../hoc/WithPatient.js";
import { BannerDialog } from "../FormProgressPage/helpers/BannerDialog.js";

export type editPatientDialog = "editPatientDialog";

export function PatientEditPage({
  mode,
  refetchDraft,
}: {
  mode?: editPatientDialog;
  refetchDraft?: () => void;
}) {
  const { patientId, formDraftId } = useParams();
  const { t } = useTranslation();

  const [showUpdateSuccessDialog, setShowUpdateSuccessDialog] =
    React.useState(false);

  const navigate = useNavigate();
  const refetchAndRedirect = () => {
    navigate(routeFns.patientEditDialogSuccess(formDraftId!, patientId!));
    refetchDraft && refetchDraft();
  };
  return (
    <>
      <WithPatient patientId={patientId}>
        {(patient) => {
          return (
            <Box>
              <PatientCreateEdit
                refetchAndRedirect={refetchAndRedirect}
                setShowUpdateSuccessDialog={setShowUpdateSuccessDialog}
                mode={mode ?? "edit"}
                existingPatientId={patientId}
                expectedVersion={patient.version}
                exitPatientCreate={() => {
                  if (patientId) {
                    return mode === "editPatientDialog"
                      ? navigate(
                          routeFns.formDraftsComplete(formDraftId!, patientId!),
                        )
                      : navigate(routeFns.patientHome(patientId));
                  }
                }}
                initialState={{
                  givenName: patient.name.given,
                  familyNameAndAddress: {
                    name: patient.name.family,
                    postalCode: patient.address.postalCode,
                    address: patient.address.address,
                    isConfirmed: true,
                  },
                  isPatientDateOfBirth: patient.dateOfBirth ? true : false,
                  dateOfBirth: patient.dateOfBirth ?? null,
                }}
              />
            </Box>
          );
        }}
      </WithPatient>
      {showUpdateSuccessDialog && mode !== "editPatientDialog" && (
        <BannerDialog
          title={t("pages.patientEditCreate.updateSuccess")}
          message={t("pages.patientEditCreate.updateSuccessDialogMessage")}
          onClose={() => {
            return navigate(routeFns.patientHome(patientId!));
          }}
        />
      )}
    </>
  );
}
