import { UsePatientBulkImportTokenResponse } from "@aspire/common";
import { Box, Typography } from "@mui/material";
import { Result } from "neverthrow";
import React from "react";
import {
  Banner,
  BannerList,
  Button,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { Base64FileUpload } from "~/components/form/index.js";
import { decodeBase64 } from "~/util.js";
import { UploadPatientBulkImportCSVProps } from "./types.js";

export function UseValidToken({
  uploadCsvFn,
  patientBulkImportToken,
  onSuccess,
}: UploadPatientBulkImportCSVProps) {
  const [validatedFile, setValidatedFile] = React.useState<string | null>(null);

  const [dryRunResult, setDryRunResult] = React.useState<Result<
    UsePatientBulkImportTokenResponse,
    string
  > | null>(null);

  const [isProcessing, setIsProcessing] = React.useState(false);

  return (
    <Box data-testid="use-valid-token">
      <Typography variant="h4">Upload Patient Bulk Import CSV</Typography>
      <Typography>
        Upload a .csv file matching the bulk patient import specification given
        to you by the Thalamos programme team
      </Typography>
      <Box sx={{ marginTop: "1em", marginBottom: "1em" }}>
        <Base64FileUpload
          disabled={isProcessing}
          label="Upload CSV"
          accept=".csv"
          onDone={async (files) => {
            // Reset all the values
            setValidatedFile(null);
            setDryRunResult(null);
            setIsProcessing(true);

            // Should be impossible but just to confirm validity of files
            if (files.length !== 1) {
              renderErrorToast({
                message: "Please upload precisely one CSV file",
              });
              return;
            }

            const csvData = decodeBase64(
              (files[0].base64 as string).split(",")[1]!,
            );

            // Always do a dry run on initial upload
            const result = await uploadCsvFn(
              patientBulkImportToken.id,
              csvData,
              true,
            );

            setDryRunResult(result);
            setIsProcessing(false);

            if (result.isOk()) {
              setValidatedFile(csvData);
            } else {
              renderErrorToast({
                message: "File upload and validation failed",
              });
            }
          }}
        />
      </Box>
      {dryRunResult?.isErr() && (
        <Box data-testid="file-invalid-banner">
          <Banner
            title={"File upload failed"}
            body={dryRunResult.error}
            bannerType={BannerList.ERROR}
          />
        </Box>
      )}
      {validatedFile && dryRunResult?.isOk() && (
        <>
          <Box sx={{ marginBottom: "1em" }} data-testid="file-validated">
            <Banner
              // TODO: add message containing more details here from dry run
              title="File uploaded and validated, ready to import. Please confirm the import using the button below"
              bannerType={BannerList.CONFIRMATION}
            />
          </Box>

          <Button
            label="Confirm Import"
            disabled={isProcessing}
            testId="confirm-import-button"
            onClick={async () => {
              setIsProcessing(true);

              const result = await uploadCsvFn(
                patientBulkImportToken.id,
                validatedFile,
                false,
              );

              if (result.isOk()) {
                setValidatedFile(null);

                renderSuccessToast({
                  message:
                    "File uploaded successfully. Processing of patients may take up to one hour.",
                });

                onSuccess();
              } else {
                renderErrorToast({
                  message: "File upload failed",
                });
              }

              setIsProcessing(false);
            }}
          />
        </>
      )}
    </Box>
  );
}
