import React, { useEffect } from "react";

// Auto-scroll to the first form error on submit
export function useScrollFirstErrorIntoViewEffect(deps?: React.DependencyList) {
  return useEffect(() => {
    const element = document.querySelector(
      `.thalamosErrorMessage :first-of-type`,
    );

    if (element !== null) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
