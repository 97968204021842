import { Box } from "@mui/material";

export function DateSeparator({ date }: { date: string }) {
  return (
    <Box
      sx={{
        mt: "2em",
        fontSize: "small",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {date}
    </Box>
  );
}

export function TeamSeparator({
  team,
  myTeam,
}: {
  team: string;
  myTeam: boolean;
}) {
  return (
    <Box
      sx={{
        mt: "1em",
        mb: "0.5em",
        fontSize: "small",
        display: "flex",
        justifyContent: myTeam ? "flex-end" : "flex-start",
        width: "100%",
      }}
    >
      {team}
    </Box>
  );
}
