import { CheckboxFormBuilderField } from "@aspire/common";
import { css } from "@emotion/react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { FieldProps } from "../../pages/FieldProps.js";
import { HelperText } from "../design-system/HelperText/HelperText.js";

export type ConfirmationCheckboxFormFieldProps<
  Data extends { [k: string]: any },
> = {
  field: CheckboxFormBuilderField<Data>;
  fieldProps: FieldProps<Data>;
};

export function ConfirmationCheckboxFormField<
  Data extends { [k: string]: any },
>({ field, fieldProps }: ConfirmationCheckboxFormFieldProps<Data>) {
  const theme = useTheme();

  const isError =
    fieldProps.errors[field.field] && fieldProps.touched[field.field];
  return (
    <Box sx={{ mb: 3 }}>
      <FormGroup>
        <Box>
          {field.label && (
            <Typography
              css={css`
                font-weight: bold;
                font-size: ${theme.spacing(1.875)};
                color: ${isError && theme.palette.error.main};
              `}
            >
              {field.label}
            </Typography>
          )}
          {field.labelBullets && (
            <ul>
              {field.labelBullets.map((bullet, index) => (
                <li>{bullet}</li>
              ))}
            </ul>
          )}
        </Box>
        <Box display="inline-block">
          <FormControlLabel
            control={
              <Checkbox
                name={field.field}
                onBlur={fieldProps.handleBlur}
                sx={{ color: "primary.main" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  fieldProps.setValues({
                    ...fieldProps.values,
                    [field.field]: e.target.checked,
                  });
                }}
                checked={!!fieldProps.values[field.field]}
              />
            }
            label={
              <Typography style={{ fontSize: theme.spacing(1.75) }}>
                {field.checkboxLabel}
              </Typography>
            }
          />
        </Box>

        {isError && (
          <HelperText errorMessage={fieldProps.errors[field.field] as string} />
        )}
      </FormGroup>
    </Box>
  );
}
