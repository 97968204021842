import { emptyImage } from "@aspire/common";
import { css } from "@emotion/react";
import { Close, ModeEditOutlined } from "@mui/icons-material";
import {
  Box,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import { FormLabel } from "../../design-system/FormLabel.js";
import { FormTitle } from "../../design-system/FormTitle/FormTitle.js";
import { HelperText } from "../../design-system/HelperText/HelperText.js";
import { IconType } from "../../types/subText.js";
import { HorizontalLine } from "../HorizontalLine.js";
import {
  SignatureDialog,
  SignatureTabsOptions,
} from "../SignatureDialog/SignatureDialog.js";
import { SignatureTabs } from "./SignatureTabs.js";

import rsc_pkg from "react-signature-canvas";

import { UploadedFile } from "./Base64FileUpload/Base64FileUpload.js";
import { UploadedFileSection } from "./UploadedFileSection.js";

export type SignatureProps = {
  name: string;
  accountSignature?: string;
  formSignature?: string;
  label?: string;
  checkboxLabel?: string;
  useCheckbox?: boolean;
  saveSignature: (signature: string | undefined) => void;
  confirmButtonLabel?: string;
  clearButtonLabel?: string;
  useExistingSignature?: boolean;
  changeUseExistingSignature?: (useExistingSignature: boolean) => void;
  errorMessage?: string;
  subtext?: string;
  subtextIcon?: IconType;
  userDefaultSignature?: string;
  hideSavedSignature?: boolean;
  showSaveDefaultSignatureOption?: boolean;
  allowUseOfDefaultSignature?: boolean;
};

const defaultProps: Partial<SignatureProps> = {
  useCheckbox: false,
  useExistingSignature: false,
  confirmButtonLabel: "I AM HAPPY WITH MY SIGNATURE",
  clearButtonLabel: "CLEAR",
  allowUseOfDefaultSignature: true,
};

export const Signature = (props: SignatureProps) => {
  const {
    formSignature,
    accountSignature,
    label,
    useExistingSignature,
    useCheckbox,
    name,
    saveSignature,
    checkboxLabel,
    confirmButtonLabel,
    clearButtonLabel,
    changeUseExistingSignature,
    errorMessage,
    subtext,
    userDefaultSignature,
    hideSavedSignature,
    showSaveDefaultSignatureOption,
    allowUseOfDefaultSignature,
  } = { ...defaultProps, ...props };

  const theme = useTheme();
  const [useDialogSignature, changeUseDialogSignature] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = React.useState(
    SignatureTabsOptions.DRAW,
  );
  const [fileError, setFileError] = React.useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = React.useState<UploadedFile | null>(
    null,
  );

  const signPad = React.useRef<rsc_pkg.default>({} as any);

  const handleClear = () => {
    setFileError(null);
    signPad?.current?.clear();
    setUploadedFile(null);
  };

  const { t } = useTranslation();

  const getControl = () => (
    <Checkbox
      checked={useExistingSignature}
      onClick={() => {
        changeUseDialogSignature(false);
      }}
      onChange={(e) => {
        if (!e.target.checked && !useDialogSignature) {
          saveSignature(emptyImage);
        }

        changeUseExistingSignature &&
          changeUseExistingSignature(!useExistingSignature);

        !useExistingSignature &&
          accountSignature &&
          saveSignature(accountSignature);
      }}
      name={`${name}-useExistingSignature`}
    />
  );

  const onHappyWithSignature = async (signature: string | undefined) => {
    saveSignature(signature);
    changeUseDialogSignature(true);
    changeUseExistingSignature && changeUseExistingSignature(false);
  };

  const imageSignatureCss = css`
    background-color: ${theme.palette.background.default};
    border-radius: 2px 2px 0 0;
    height: ${theme.spacing(15.5)};
    object-fit: contain;
    object-position: center;
  `;

  return (
    <Element name={name}>
      <FormControl
        css={css`
          width: 100%;
        `}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <FormTitle
            useReducedTopPadding
            hasTitleBottomMargin={false}
            subtitleText={t("pages.common.signature.label")}
          />
          {formSignature && (
            <IconButton>
              <ModeEditOutlined
                sx={{ color: "primary.hint" }}
                onClick={() => setIsOpen(true)}
              />
            </IconButton>
          )}
        </Box>
        {label && <FormLabel label={label} error={false} />}
        {useCheckbox && (
          <FormControlLabel
            control={getControl()}
            label={checkboxLabel}
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          />
        )}
        {isOpen && (
          <SignatureDialog
            onClose={() => setIsOpen(false)}
            onHappyWithSignature={onHappyWithSignature}
            confirmButtonLabel={confirmButtonLabel!}
            clearButtonLabel={clearButtonLabel!}
            userDefaultSignature={userDefaultSignature}
            hideSavedSignature={hideSavedSignature}
            showSaveDefaultSignatureOption={showSaveDefaultSignatureOption}
            allowUseOfDefaultSignature={allowUseOfDefaultSignature}
          />
        )}

        {formSignature && (
          <>
            <Box
              css={css`
                padding: ${theme.spacing(2, 3.25, 2, 3.25)};
                border: 1px dashed ${theme.palette.common.lightGray};
                border-radius: 6px;
              `}
            >
              <Box sx={{ mx: 4 }}>
                <CardMedia
                  component="img"
                  src={formSignature}
                  css={imageSignatureCss}
                  onClick={() => setIsOpen(true)}
                />
              </Box>

              <Box display="flex" alignItems="center">
                <Close
                  fontSize="small"
                  sx={{ color: theme.palette.primary.hint, mr: 1 }}
                />
                <HorizontalLine
                  noMargin
                  customBottomBorderColor={theme.palette.primary.hint}
                />
              </Box>
            </Box>
          </>
        )}

        {!formSignature && (
          <>
            <SignatureTabs
              selectedTab={selectedTab}
              handleClear={() => handleClear}
              setSelectedTab={setSelectedTab}
              addMarginBottom
              allowUseOfDefaultSignature={allowUseOfDefaultSignature}
            />
            {selectedTab === SignatureTabsOptions.UPLOAD && (
              <>
                <Box
                  css={css`
                    padding: ${theme.spacing(5)};
                    border: 1px dashed ${theme.palette.common.lightGray};
                    border-radius: 6px;
                  `}
                >
                  <UploadedFileSection
                    uploadedFile={uploadedFile!}
                    setUploadedFile={(callback) => {
                      const previouslyUploadedFiles = null;
                      const files = callback(previouslyUploadedFiles);
                      if (files.length > 0) {
                        const file = files[0];
                        setUploadedFile(file);

                        // TODO: Consider transcoing this
                        saveSignature(file?.base64 as string);
                      }
                    }}
                    setFileError={setFileError}
                    fileError={fileError!}
                  />
                </Box>
                <Box sx={{ mx: 3 }}>
                  <HelperText
                    subtext={t(
                      "components.signatureDialog.uploadedFileHelperText",
                    )}
                  />
                </Box>
              </>
            )}
            {selectedTab === SignatureTabsOptions.DRAW && (
              <Box
                css={css`
                  padding: ${theme.spacing(5)};
                  border: 1px dashed ${theme.palette.common.lightGray};
                  border-radius: 6px;
                `}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  justifyContent="center"
                  onClick={() => setIsOpen(true)}
                >
                  <Typography
                    css={css`
                      color: ${theme.palette.primary.hint};
                      font-style: italic;
                      &:hover {
                        cursor: grab;
                      }
                    `}
                  >
                    {t("pages.common.signature.drawSignatureLabel")}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Close
                    fontSize="small"
                    sx={{ color: theme.palette.primary.hint, mr: 1 }}
                  />
                  <HorizontalLine
                    noMargin
                    customBottomBorderColor={theme.palette.primary.hint}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
        {(subtext || errorMessage) && (
          <HelperText subtext={subtext} errorMessage={errorMessage} />
        )}
      </FormControl>
    </Element>
  );
};
