import { MultiSelectFormBuilderField } from "@aspire/common";
import { css } from "@emotion/react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { FieldProps } from "../../pages/FieldProps.js";
import { HelperText } from "../design-system/HelperText/HelperText.js";

export type MultiSelectFormFieldFieldProps<Data extends { [k: string]: any }> =
  {
    field: MultiSelectFormBuilderField<Data>;
    fieldProps: FieldProps<Data>;
  };

export function MultiSelectFormField<Data extends { [k: string]: any }>({
  field,
  fieldProps,
}: MultiSelectFormFieldFieldProps<Data>) {
  const theme = useTheme();

  const allFields = field.options.map((o) => o.field);
  const selectedFields = allFields.filter((f) => fieldProps.values[f]);
  const errors = allFields
    .map((f) => fieldProps.touched[f] && fieldProps.errors[f])
    .filter((x) => x);

  return (
    <>
      <Box sx={{ mb: 3, ml: field.indent ? field.indent : 0 }}>
        <FormGroup>
          {field.label && (
            <Typography
              css={css`
                margin-bottom: ${theme.spacing(0.5)};
                font-weight: bold;
                font-size: ${theme.spacing(1.875)};
                color: ${errors.length > 0 && theme.palette.error.main};
              `}
            >
              {field.label}
            </Typography>
          )}

          {field.options.map((o) => (
            <Box display="inline-block" data-testid={`field:${o.field}`}>
              <FormControlLabel
                data-testid="multiselect-item-label"
                control={
                  <Checkbox
                    name={o.field}
                    onBlur={fieldProps.handleBlur}
                    disabled={field.disabled}
                    sx={{ color: "primary.main" }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      fieldProps.setValues({
                        ...fieldProps.values,
                        [o.field]: e.target.checked,
                      });
                    }}
                    checked={selectedFields.includes(o.field)}
                  />
                }
                label={
                  <Typography style={{ fontSize: theme.spacing(1.75) }}>
                    {o.label}
                  </Typography>
                }
              />
            </Box>
          ))}
          {errors.length > 0 ? (
            <HelperText errorMessage={errors[0] as string} />
          ) : (
            field.afterLabel && (
              <Box sx={{ mb: 0.5 }}>
                <HelperText subtext={field.afterLabel} />
              </Box>
            )
          )}
        </FormGroup>
      </Box>
    </>
  );
}
