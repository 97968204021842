import { isEqual } from "lodash-es";
import React, { Component } from "react";
import DropdownTreeSelect, {
  DropdownTreeSelectProps,
} from "react-dropdown-tree-select";

export default class DropdownTreeSelectContainer extends Component<
  DropdownTreeSelectProps,
  { data: DropdownTreeSelectProps["data"] }
> {
  constructor(props: DropdownTreeSelectProps) {
    super(props);
    this.state = { data: props.data };
  }

  componentWillReceiveProps = (nextProps: DropdownTreeSelectProps) => {
    if (!isEqual(nextProps.data, this.state.data)) {
      this.setState({ data: nextProps.data });
    }
  };

  shouldComponentUpdate = (nextProps: DropdownTreeSelectProps) => {
    return !isEqual(nextProps.data, this.state.data);
  };

  render() {
    const { data, ...rest } = this.props;
    return <DropdownTreeSelect data={this.state.data} {...rest} />;
  }
}
