import { TimeFormBuilderField } from "@aspire/common";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { FieldProps } from "../../pages/FieldProps.js";
import { HelperText } from "../design-system/HelperText/HelperText.js";
import { TimePicker } from "../design-system/TimePicker/TimePicker.js";

export type TimeFormFieldProps<Data extends { [k: string]: any }> = {
  field: TimeFormBuilderField<Data>;
  fieldProps: FieldProps<Data>;
};

export function TimeFormField<Data extends { [k: string]: any }>({
  field,
  fieldProps,
}: TimeFormFieldProps<Data>) {
  const isError = !!(
    fieldProps.touched[field.field] && fieldProps.errors[field.field]
  );

  const { t } = useTranslation();

  const hhmmValue = fieldProps.values[field.field];
  const timeValue = hhmmValue ? dayjs(hhmmValue, "HH:mm") : null;
  return (
    <>
      <TimePicker
        errorMessage={
          isError ? (fieldProps.errors[field.field] as string) : undefined
        }
        label={field.label}
        onChange={(value: Dayjs | null) => {
          fieldProps.setFieldTouched(field.field, true, false);
          fieldProps.setValues({
            ...fieldProps.values,
            [field.field]: value ? value.format("HH:mm") : null,
          });
        }}
        value={timeValue}
        name={field.field}
      />
      {!isError && <HelperText subtext={t("forms.ukTimeWarning")} />}
    </>
  );
}
