import { Box } from "@mui/material";
import React from "react";

interface AlertBarProps {
  children: React.ReactNode;
  backgroundColor: string;
  color: string;
}

export function AlertBar({ children, backgroundColor, color }: AlertBarProps) {
  return (
    <Box
      sx={{
        zIndex: 100000,
        width: "100%",
        backgroundColor: backgroundColor,
        fontSize: "1em",
        position: "fixed",
        top: 0,
        left: 0,
        padding: "3px 1em",
        color: color,
      }}
    >
      {children}
    </Box>
  );
}

export default AlertBar;
