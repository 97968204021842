import {
  AmhpLocalAuthorityFormBuilderField,
  LocalAuthorityFormBuilderField,
} from "@aspire/common";
import {
  RadioButtonChecked as RadioButtonCheckedIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from "@mui/icons-material";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ManagedLocationsContext } from "../../Contexts.js";
import { FieldProps } from "../../pages/FieldProps.js";
import { FormLabel } from "../design-system/FormLabel.js";
import { HelperText } from "../design-system/HelperText/HelperText.js";
import { Typeahead } from "../design-system/Typeahead/Typeahead.js";

export type AmhpLocalAuthorityFormFieldProps<
  Data extends { [k: string]: any },
> = {
  field: AmhpLocalAuthorityFormBuilderField<Data>;
  fieldProps: FieldProps<Data>;
};

interface LocalAuthorityTypeaheadInnerProps {
  name: string;
  label?: string;
  inputValue: string | null | undefined;
  setInputValue: (newValue: string | null) => void;
  errorMessage: string | false | undefined;
}

export function LocalAuthorityTypeaheadInner({
  name,
  label,
  inputValue,
  setInputValue,
  errorMessage,
}: LocalAuthorityTypeaheadInnerProps) {
  const { t } = useTranslation();
  const managedLocationsContext = React.useContext(ManagedLocationsContext);
  const localAuthorities = managedLocationsContext.managedLocations
    .filter((location) => location.type === "localAuthority")
    .sort((a, b) => a.name.localeCompare(b.name));
  return (
    <>
      {!!label && <FormLabel label={label} />}
      <Typeahead
        placeholder={t(
          "forms.amhpLocalAuthorityFormField.typeaheadPlaceholder",
        )}
        enableFuse={false}
        options={localAuthorities}
        inputValue={inputValue || ""}
        optionsKey={"name"}
        name={name}
        getOptionLabel={(la: any) => {
          if (typeof la === "string") {
            return la;
          } else {
            return la.name;
          }
        }}
        onInputChange={(value: string | null) => {
          const la = value
            ? localAuthorities.find((h) => h.name === value)
            : null;
          const localAuthority = la?.name ?? value ?? null;
          setInputValue(localAuthority);
        }}
      />
      {!!errorMessage && <HelperText errorMessage={errorMessage} />}
    </>
  );
}

interface StandardLocalAuthorityFormFieldProps<
  Data extends { [k: string]: any },
> {
  fieldProps: FieldProps<Data>;
  field: LocalAuthorityFormBuilderField<Data>;
  label?: string;
}

export function StandardLocalAuthorityFormField<
  Data extends { [k: string]: any },
  Context extends object,
>({ fieldProps, field, label }: StandardLocalAuthorityFormFieldProps<Data>) {
  const { t } = useTranslation();

  const errorMessage =
    fieldProps.touched[field.field] &&
    (fieldProps.errors[field.field] as string);

  return (
    <LocalAuthorityTypeaheadInner
      name={field.field}
      label={field.label}
      inputValue={fieldProps.values[field.field]}
      setInputValue={(newValue: string | null) => {
        fieldProps.setValues({
          ...fieldProps.values,
          [field.field]: newValue,
        });
      }}
      errorMessage={errorMessage}
    />
  );
}

export function AmhpLocalAuthorityFormField<Data extends { [k: string]: any }>({
  field,
  fieldProps,
}: AmhpLocalAuthorityFormFieldProps<Data>) {
  const { t } = useTranslation();

  const checkboxError =
    fieldProps.touched[field.amActingOnBehalfOfApprovingAuthorityField] &&
    (fieldProps.errors[
      field.amActingOnBehalfOfApprovingAuthorityField
    ] as string);

  const laWhoApprovedUser = fieldProps.values["user"].localAuthority;
  const laWhoApprovedUserError =
    (fieldProps.touched.user as any)?.localAuthority &&
    ((fieldProps.errors.user as any)?.localAuthority as string);

  return (
    <Box sx={{ mb: 3 }}>
      <FormGroup>
        <LocalAuthorityTypeaheadInner
          name={field.userActingOnBehalfOfField}
          label={t("forms.amhpLocalAuthorityFormField.enterLaWhoApprovedYou")}
          inputValue={laWhoApprovedUser}
          setInputValue={(newValue: string | null) => {
            fieldProps.setValues({
              ...fieldProps.values,
              user: {
                ...fieldProps.values.user,
                localAuthority: newValue,
              },
            });
          }}
          errorMessage={laWhoApprovedUserError}
        />

        <Box
          data-testid={`field:${field.amActingOnBehalfOfApprovingAuthorityField}`}
          display="flex"
          flexDirection="column"
        >
          <FormLabel
            label={t(
              "forms.amhpLocalAuthorityFormField.areYouActingOnBehalfOfLaWhoApprovedYou",
            )}
          />
          <FormControlLabel
            control={
              <Checkbox
                name={`${field.amActingOnBehalfOfApprovingAuthorityField}-true`}
                data-testid={"am-acting-on-behalf-of-approving-authority-field"}
                icon={
                  <RadioButtonUncheckedIcon sx={{ color: "primary.main" }} />
                }
                checkedIcon={
                  <RadioButtonCheckedIcon sx={{ color: "primary.main" }} />
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  fieldProps.setValues({
                    ...fieldProps.values,
                    [field.amActingOnBehalfOfApprovingAuthorityField]: true,
                    [field.userActingOnBehalfOfField]: null,
                  });
                }}
                checked={
                  fieldProps.values[
                    field.amActingOnBehalfOfApprovingAuthorityField
                  ]
                }
              />
            }
            label={t("common.yes")}
          />
          <FormControlLabel
            control={
              <Checkbox
                name={`${field.amActingOnBehalfOfApprovingAuthorityField}-false`}
                icon={
                  <RadioButtonUncheckedIcon sx={{ color: "primary.main" }} />
                }
                checkedIcon={
                  <RadioButtonCheckedIcon sx={{ color: "primary.main" }} />
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  fieldProps.setValues({
                    ...fieldProps.values,
                    [field.amActingOnBehalfOfApprovingAuthorityField]: false,
                  });
                }}
                checked={
                  fieldProps.values[
                    field.amActingOnBehalfOfApprovingAuthorityField
                  ] === false
                }
              />
            }
            label={t("common.no")}
          />
        </Box>

        {checkboxError && <HelperText errorMessage={checkboxError} />}

        {fieldProps.values[field.amActingOnBehalfOfApprovingAuthorityField] ===
          false && (
          <StandardLocalAuthorityFormField
            fieldProps={fieldProps}
            field={{
              type: "local-authority-picker",
              label: t(
                "forms.amhpLocalAuthorityFormField.enterLaActingOnBehalfOf",
              ),
              field: field.userActingOnBehalfOfField,
            }}
          />
        )}
      </FormGroup>
    </Box>
  );
}
