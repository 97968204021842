import {
  Box,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  FormControlLabel,
  IconButton,
  DialogTitle as MuiDialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";

import * as SignatureCanvasNameSpace from "react-signature-canvas";
const SignatureCanvas = SignatureCanvasNameSpace.default as any;

import { css } from "@emotion/react";
import {
  Close,
  FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
  FavoriteOutlined as FavoriteOutlinedIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useScreenDetection } from "../../../hooks/ScreenDetection/useScreenDetection.js";
import { breakpoints } from "../../../styles/themeCommon.js";
import { Button } from "../../design-system/Button/Button.js";
import { FormTitle } from "../../design-system/FormTitle/FormTitle.js";
import { HelperText } from "../../design-system/HelperText/HelperText.js";
import { HorizontalLine } from "../HorizontalLine.js";
import { UploadedFile } from "../Signature/Base64FileUpload/Base64FileUpload.js";
import { SignatureTabs } from "../Signature/SignatureTabs.js";
import { UploadedFileSection } from "../Signature/UploadedFileSection.js";

export const SignatureTabsOptions = {
  SAVED: 0,
  DRAW: 1,
  UPLOAD: 2,
};

export type SaveDefaultSignatureProps = {
  saveDefaultSignature?: boolean;
  setSaveDefaultSignature?: (value: any) => void;
};

export type UserDefaultSignatureProps = {
  userDefaultSignature?: string;
};

export type UserHideSavedSignatureProps = {
  hideSavedSignature?: boolean;
};

export type SignatureDialogTitleProps = {
  selectedTab: number;
  handleClear: () => void;
  setSelectedTab: (value: number) => void;
  setIsSignatureConfirmed: (value: boolean) => void;
  onClose: () => void;
  allowUseOfDefaultSignature?: boolean;
} & UserDefaultSignatureProps &
  UserHideSavedSignatureProps;

export type SignatureDialogProps = {
  onClose: () => void;
  onHappyWithSignature: (signature: string | undefined) => Promise<void> | void;
  confirmButtonLabel: string;
  clearButtonLabel: string;
  showSaveDefaultSignatureOption?: boolean;
  allowUseOfDefaultSignature?: boolean;
} & SaveDefaultSignatureProps &
  UserDefaultSignatureProps &
  UserHideSavedSignatureProps;

const defaultProps: Partial<SignatureDialogProps> = {
  userDefaultSignature: undefined,
  saveDefaultSignature: false,
  setSaveDefaultSignature: () => {},
  showSaveDefaultSignatureOption: true,
  allowUseOfDefaultSignature: true,
};

export const SignatureDialog = (props: SignatureDialogProps) => {
  const {
    onClose,
    onHappyWithSignature,
    confirmButtonLabel,
    clearButtonLabel,
    userDefaultSignature,
    saveDefaultSignature,
    setSaveDefaultSignature,
    hideSavedSignature,
    showSaveDefaultSignatureOption,
    allowUseOfDefaultSignature,
  } = { ...defaultProps, ...props };

  // Generic hooks
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobileView } = useScreenDetection();

  // Signature pad ref
  const signaturePadRef = useRef<SignatureCanvasNameSpace.default | null>(null);

  // Local component state
  const [selectedTab, setSelectedTab] = useState(
    userDefaultSignature && !hideSavedSignature && allowUseOfDefaultSignature
      ? SignatureTabsOptions.SAVED
      : SignatureTabsOptions.DRAW,
  );
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);
  const [isSignatureConfirmed, setIsSignatureConfirmed] = useState(false);
  const [isPadEmpty, setPadIsEmpty] = useState<boolean>(true);

  const handlePadEmptyCheck = (event: any) => {
    if (typeof signaturePadRef.current?.isEmpty === "function") {
      const isEmpty = signaturePadRef.current.isEmpty();
      setPadIsEmpty(isEmpty);
    }
  };

  const handleConfirm = async () => {
    if (selectedTab === SignatureTabsOptions.DRAW) {
      const trimmedCanvas = signaturePadRef.current!.getTrimmedCanvas();
      const base64Signature = trimmedCanvas?.toDataURL();
      await onHappyWithSignature(base64Signature);
      onClose();
    } else if (selectedTab === SignatureTabsOptions.SAVED) {
      await onHappyWithSignature(userDefaultSignature);
      onClose();
    } else {
      await onHappyWithSignature(uploadedFile?.base64 as string);
      handleClear();
      onClose();
    }
  };

  const clearSignatureData = () => {
    setFileError(null);
    if (typeof signaturePadRef?.current?.clear === "function") {
      signaturePadRef.current.clear();
      setPadIsEmpty(true);
    }
    setUploadedFile(null);
  };

  const handleClear = () => {
    setSaveDefaultSignature && setSaveDefaultSignature(false);
    clearSignatureData();
    setPadIsEmpty(true);
    setIsSignatureConfirmed(false);
    if (typeof signaturePadRef?.current?.on === "function") {
      signaturePadRef.current.on();
    }
  };

  const showSaveDefaultSignatureCheckbox =
    !userDefaultSignature &&
    selectedTab === SignatureTabsOptions.DRAW &&
    showSaveDefaultSignatureOption &&
    allowUseOfDefaultSignature;

  const confirmButtonIsDisabled =
    !isSignatureConfirmed ||
    (selectedTab === SignatureTabsOptions.DRAW && isPadEmpty) ||
    (selectedTab === SignatureTabsOptions.UPLOAD && uploadedFile === null);

  const imageSignatureCss = css`
    background-color: ${theme.palette.background.default};
    border-radius: 2px 2px 0 0;
    height: ${theme.spacing(15.5)};
    object-fit: contain;
    object-position: center;
  `;

  return (
    <Dialog
      PaperProps={
        {
          style: { borderRadius: 6 },
          "data-testid": "signature-dialog",
        } as any
      }
      fullScreen={isMobileView}
      fullWidth
      maxWidth="lg"
      open
    >
      <SignatureDialogTitle
        selectedTab={selectedTab}
        handleClear={() => handleClear}
        setSelectedTab={(value: number) => {
          setSelectedTab(value);
          setIsSignatureConfirmed(false);
        }}
        setIsSignatureConfirmed={setIsSignatureConfirmed}
        userDefaultSignature={userDefaultSignature}
        onClose={onClose}
        hideSavedSignature={hideSavedSignature}
        allowUseOfDefaultSignature={allowUseOfDefaultSignature}
      />
      <Box sx={{ px: 3 }}>
        <Box
          css={css`
            padding: ${theme.spacing(2, 3.25, 2, 3.25)};
            border: 1px dashed ${theme.palette.common.lightGray};
            border-radius: 6px;
          `}
        >
          {showSaveDefaultSignatureCheckbox && (
            <SaveDefaultSignatureCheckbox
              isMobileView={isMobileView}
              saveDefaultSignature={saveDefaultSignature}
              setSaveDefaultSignature={setSaveDefaultSignature}
            />
          )}
          <Box
            css={css`
              min-height: ${theme.spacing(15.5)};
              @media (min-width: ${breakpoints.values.sm}px) {
                min-height: ${theme.spacing(12.2)};
              }
            `}
          >
            {/* show saved signature */}
            {selectedTab === SignatureTabsOptions.SAVED && (
              <CardMedia
                component="img"
                src={userDefaultSignature}
                data-testid={"saved-signature-image"}
                css={imageSignatureCss}
              />
            )}

            {/* show standard draw signature */}
            {selectedTab === SignatureTabsOptions.DRAW && (
              <SignatureCanvas
                ref={signaturePadRef}
                clearOnResize={false}
                canvasProps={{
                  style: {
                    width: "100%",
                    minHeight: theme.spacing(20.5),
                  },
                  onPointerUp: handlePadEmptyCheck,
                  onPointerOut: handlePadEmptyCheck,

                  // @ts-ignore
                  "data-testid": "signature-canvas",
                }}
              />
            )}

            {/* show upload signature */}
            {selectedTab === SignatureTabsOptions.UPLOAD && (
              <UploadedFileSection
                uploadedFile={uploadedFile!}
                setUploadedFile={(callback) => {
                  const previouslyUploadedFiles = null;
                  const files = callback(previouslyUploadedFiles);
                  setUploadedFile(files?.[0] ?? null);
                }}
                setFileError={setFileError}
                fileError={fileError!}
              />
            )}
          </Box>
          {selectedTab !== SignatureTabsOptions.UPLOAD && (
            <Box display="flex" alignItems="center">
              <Close
                fontSize="small"
                sx={{ color: theme.palette.primary.hint, mr: 1 }}
              />
              <HorizontalLine
                noMargin
                customBottomBorderColor={theme.palette.primary.hint}
              />
            </Box>
          )}
        </Box>
      </Box>
      <>
        {selectedTab === SignatureTabsOptions.UPLOAD && !uploadedFile && (
          <Box sx={{ mx: 3, mb: 3 }}>
            <HelperText
              subtext={t("components.signatureDialog.uploadedFileHelperText")}
            />
          </Box>
        )}

        <FormControlLabel
          sx={{ mx: 3, my: 1 }}
          css={css`
            display: flex;
          `}
          data-testid={"confirm-signature-checkbox"}
          control={
            <Checkbox
              sx={{ color: "primary.main" }}
              onChange={() => {
                setIsSignatureConfirmed(!isSignatureConfirmed);
              }}
              checked={isSignatureConfirmed}
            />
          }
          label={t("components.signatureDialog.confirmSignature")}
        />
        <Box sx={{ px: 3 }}>
          <HorizontalLine customMargin={2} noMargin />
        </Box>
        <DialogActions sx={{ mx: 2, my: 1, gap: 1 }}>
          <Button
            testId={"clear-button"}
            label={clearButtonLabel}
            disabled={
              selectedTab === SignatureTabsOptions.SAVED ||
              selectedTab === SignatureTabsOptions.UPLOAD
            }
            onClick={handleClear}
            color="primary"
            variant="outlined"
          />
          <Button
            testId="confirm-button"
            color="primary"
            disabled={confirmButtonIsDisabled}
            label={confirmButtonLabel}
            onClick={handleConfirm}
          />
        </DialogActions>
      </>
    </Dialog>
  );
};

function SignatureDialogTitle({
  selectedTab,
  handleClear,
  setSelectedTab,
  userDefaultSignature,
  setIsSignatureConfirmed,
  onClose,
  hideSavedSignature,
  allowUseOfDefaultSignature,
}: SignatureDialogTitleProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <MuiDialogTitle sx={{ p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <FormTitle
          hasContainerMarginBottom={false}
          hasTitleBottomMargin
          subtitleText={t("pages.common.signature.label")}
        />
      </Box>
      <SignatureTabs
        selectedTab={selectedTab}
        handleClear={() => handleClear}
        setSelectedTab={(value: number) => {
          setSelectedTab(value);
          setIsSignatureConfirmed(false);
        }}
        userDefaultSignature={userDefaultSignature}
        hideSavedSignature={hideSavedSignature}
        allowUseOfDefaultSignature={allowUseOfDefaultSignature}
      />
      <IconButton
        css={css`
          position: absolute;
          right: ${theme.spacing(2)};
          top: ${theme.spacing(2)};
          color: ${theme.palette.primary.hint};
        `}
        onClick={onClose}
      >
        <Close />
      </IconButton>
    </MuiDialogTitle>
  );
}

function SaveDefaultSignatureCheckbox({
  isMobileView,
  saveDefaultSignature,
  setSaveDefaultSignature,
}: SaveDefaultSignatureProps & { isMobileView: boolean }) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      {isMobileView ? (
        <Box
          css={css`
            display: flex;
            justify-content: flex-end;
            margin: 0;
            padding: 0;
          `}
          onClick={() => {
            setSaveDefaultSignature &&
              setSaveDefaultSignature(!saveDefaultSignature);
          }}
        >
          {saveDefaultSignature ? (
            <FavoriteOutlinedIcon sx={{ color: "primary.main" }} />
          ) : (
            <FavoriteBorderOutlinedIcon />
          )}
        </Box>
      ) : (
        <>
          <FormControlLabel
            css={css`
              display: flex;
              justify-content: flex-end;
              margin: 0;
              padding: 0;
            `}
            sx={{ color: "text.border" }}
            control={
              <Checkbox
                sx={{ color: "text.border" }}
                onChange={() => {
                  setSaveDefaultSignature &&
                    setSaveDefaultSignature(!saveDefaultSignature);
                }}
                checked={saveDefaultSignature}
                data-testid={"save-default-checkbox"}
              />
            }
            label={
              <Typography
                css={css`
                  font-weight: 600;
                  font-size: ${theme.spacing(1.625)};
                `}
              >
                {t("components.signatureDialog.defaultCheckboxLabel")}
              </Typography>
            }
          />
        </>
      )}
    </>
  );
}
