import { PATIENT_BULK_IMPORT_TOKEN_MAX_AGE_MONTHS, UUID } from "@aspire/common";
import dayjs from "dayjs";
import { errAsync, okAsync, ResultAsync } from "neverthrow";
import * as React from "react";
import { useNavigate, useParams } from "react-router";
import { api, apiHooks } from "~/api.js";
import { LoadingSpinner } from "~/components/design-system/LoadingSpinner.js";
import { routeFns } from "~/routes.js";
import { TokenExpired } from "./errorPages/TokenExpired.js";
import { TokenRevoked } from "./errorPages/TokenRevoked.js";
import { TokenUsed } from "./errorPages/TokenUsed.js";
import { UploadPatientBulkImportCSVProps } from "./types.js";
import { UseValidToken } from "./UseValidToken.js";

export function UploadPatientBulkImportCSVInner({
  uploadCsvFn,
  patientBulkImportToken,
  onSuccess,
}: UploadPatientBulkImportCSVProps) {
  const isExpired = dayjs(patientBulkImportToken.created).isBefore(
    dayjs().subtract(PATIENT_BULK_IMPORT_TOKEN_MAX_AGE_MONTHS, "months"),
  );

  if (isExpired) {
    return <TokenExpired patientBulkImportToken={patientBulkImportToken} />;
  }

  if (patientBulkImportToken.status === "used") {
    return <TokenUsed />;
  }

  if (patientBulkImportToken.status === "deleted") {
    return <TokenRevoked />;
  }

  return (
    <UseValidToken
      patientBulkImportToken={patientBulkImportToken}
      uploadCsvFn={uploadCsvFn}
      onSuccess={onSuccess}
    />
  );
}

export function UploadPatientBulkImportCSV({}: {}) {
  const { token } = useParams();
  const navigate = useNavigate();

  const [
    { data: patientBulkImportToken, loading: loadingPatientBulkImportToken },
  ] = apiHooks.patientBulkImport.get(token ?? "");

  React.useEffect(() => {
    if (!token || (!loadingPatientBulkImportToken && !patientBulkImportToken)) {
      navigate(routeFns.notFound());
    }
  }, [token, loadingPatientBulkImportToken, patientBulkImportToken]);

  return patientBulkImportToken ? (
    <UploadPatientBulkImportCSVInner
      patientBulkImportToken={patientBulkImportToken}
      onSuccess={() => navigate(routeFns.home())}
      uploadCsvFn={(tokenId: UUID, csvData: string, dryRun: boolean) => {
        return ResultAsync.fromPromise(
          api.patientBulkImport.use(tokenId, csvData, dryRun),
          (_error: unknown) => {
            return "Upload request failed. Please try again later";
          },
        ).andThen((response) => {
          if (response.status === 200) {
            return okAsync(response.data);
          } else {
            return errAsync(
              (response.data as unknown as { reason: string })?.reason ??
                "Unknown error - please contact the Thalamos programme team",
            );
          }
        });
      }}
    />
  ) : (
    <LoadingSpinner />
  );
}
