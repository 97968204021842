import { css, cx } from "@emotion/css";
import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  Typography,
  useTheme,
} from "@mui/material";
import { find, map } from "lodash-es";
import React from "react";
import { Element } from "react-scroll";
import { IconType } from "../../types/subText.js";
import { FormLabel } from "../FormLabel.js";
import { HelperText } from "../HelperText/HelperText.js";
import { ReadOnlyContent } from "../ReadOnlyContent/ReadOnlyContent.js";

export interface DropdownProps {
  name: string;
  label?: string;
  values: { [key: string]: any };
  selectedValue?: any;
  onChange: (e: any) => void;
  errorMessage?: string;
  variant?: SelectProps["variant"];
  disabled?: boolean;
  subtext?: string;
  subtextIcon?: IconType;
  placeholder?: string;
  multiple?: boolean;
  readOnly?: boolean;
  renderedValue?: string;
}

const defaultProps: Partial<DropdownProps> = {
  label: undefined,
  selectedValue: "",
  disabled: false,
  variant: "outlined",
  multiple: false,
  readOnly: false,
};

export const Dropdown = (props: DropdownProps) => {
  const allProps: DropdownProps = { ...defaultProps, ...props };
  const {
    name,
    label,
    values,
    selectedValue,
    onChange,
    errorMessage,
    disabled,
    variant,
    subtext,
    subtextIcon,
    placeholder,
    multiple,
    readOnly,
    renderedValue,
  } = allProps;
  const theme = useTheme();

  const renderIconColor = () => {
    if (disabled) return theme.palette.text.disabled;
    if (errorMessage) return theme.palette.error.main;
    return theme.palette.primary.main;
  };

  const styles = {
    root: css`
      background-color: ${theme.palette.background.default};
    `,
    select: css`
      padding: ${theme.spacing(2, 6, 2, 2)};
      background-color: ${theme.palette.common.paleGray};
    `,
    icon: css`
      font-size: ${theme.spacing(5)};
      top: calc(50% - ${theme.spacing(2.5)}px);
      right: ${theme.spacing(2)};
      color: ${renderIconColor()};
    `,
    paper: css`
      border-radius: 0;
    `,
    menuItems: css`
      background-color: ${theme.palette.background.default};
      color: ${theme.palette.text.primary};
      padding: ${theme.spacing(1, 0)};
    `,
    menuItem: css`
      padding: ${theme.spacing(1, 2)};
    `,
    placeholder: css`
      font-style: "italic";
      color: ${theme.palette.text.hint};
    `,
  };

  const handleChange = (event: any) => {
    onChange && onChange(event.target.value);
  };

  const MenuProps = {
    classes: {
      paper: cx(styles.paper),
      list: cx(styles.menuItems),
    },
  };

  const renderStringValue = (value?: string): string => {
    const getLabel = (v: any) => find(values, { value: v })?.label;
    const labelToRender = multiple
      ? map(value, getLabel)?.join(", ")
      : getLabel(value);
    return labelToRender;
  };

  const renderValueOrPlaceHolder = (value?: string) => {
    return (
      renderStringValue(value) || (
        <Box
          style={{
            fontStyle: "italic",
            color: theme.palette.text.border,
          }}
        >
          {placeholder}
        </Box>
      )
    );
  };

  if (readOnly) {
    return (
      <ReadOnlyContent
        label={label}
        content={[renderStringValue(selectedValue)]}
        subtextIcon={subtextIcon!}
        dense
      />
    );
  }

  return (
    <Element name={name}>
      <FormControl
        fullWidth={true}
        style={{
          width: "100%",
          marginBottom: theme.spacing(1),
        }}
      >
        {label && <FormLabel label={label} error={!!errorMessage} />}
        <Select
          data-testid="select-dropdown"
          classes={{
            select: cx(styles.select),
            icon: cx(styles.icon),
          }}
          inputProps={{
            id: "select-id",
            "data-testid": "select-id",
          }}
          css={css`
            ${placeholder &&
            (!selectedValue || (multiple && !selectedValue.length)) &&
            styles.placeholder}
          `}
          value={selectedValue}
          onChange={handleChange}
          IconComponent={ExpandMore}
          variant={variant}
          error={!!errorMessage}
          disabled={disabled}
          displayEmpty={!!placeholder}
          MenuProps={MenuProps}
          multiple={multiple}
          renderValue={
            renderedValue ? () => renderedValue : renderValueOrPlaceHolder
          }
        >
          {placeholder && (
            <MenuItem
              key="placeholder"
              value=""
              disabled
              css={css`
                display: none;
              `}
            >
              {placeholder}
            </MenuItem>
          )}
          {values.map((v: any) => (
            <MenuItem
              key={v.value}
              value={v.value}
              disabled={v.disabled}
              classes={{ root: cx(styles.menuItem) }}
            >
              <Box display="flex" alignItems="start">
                {multiple && (
                  <Checkbox checked={selectedValue.indexOf(v.value) > -1} />
                )}
                <Box display="flex" flexDirection="column" pt="9px" gap="4px">
                  <Typography>{v.label}</Typography>
                  {!!v.sublabel && (
                    <Typography
                      fontSize="0.8em"
                      whiteSpace="normal"
                      lineHeight="1.2"
                    >
                      {v.sublabel}
                    </Typography>
                  )}
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Select>
        {(errorMessage || subtext) && (
          <HelperText subtext={subtext} errorMessage={errorMessage} />
        )}
      </FormControl>
    </Element>
  );
};
