import { ExtendedPatient, UUID } from "@aspire/common";
import React from "react";
import { useNavigate } from "react-router-dom";
import { apiHooks } from "../api.js";
import { routeFns } from "../routes.js";

export function WithPatient(props: {
  patientId: UUID | undefined;
  children: (patient: ExtendedPatient) => React.ReactElement;
}) {
  const [{ data: patient, loading: patientLoading }] = apiHooks.patients.get(
    props.patientId || "",
  );

  const navigate = useNavigate();

  if (!props.patientId || patientLoading) {
    return <></>;
  } else if (patient) {
    return <>{props.children(patient)}</>;
  } else {
    navigate(routeFns.notFound(), { replace: true });
    return <></>;
  }
}
