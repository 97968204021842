import { ExtendedTeam } from "@aspire/common";
import { css } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import {
  Button,
  ButtonIcon,
  MenuFlyout,
  MenuOptionsType,
} from "~/components/design-system/index.js";
import { apiHooks } from "../../api.js";
import { LoggedInUserContext } from "../../Contexts.js";
import { routeFns } from "../../routes.js";
import { breakpoints } from "../../styles/themeCommon.js";

function TeamManagementRow({ team }: { team: ExtendedTeam }) {
  const cardRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate();

  const options: MenuOptionsType[] = [
    {
      name: "Edit Team",
      onClick: () => {
        navigate(routeFns.teamCreateEdit(team.id));
      },
      icon: "edit",
      disabled: false,
    },
  ];

  return (
    <TableRow key={team.id}>
      <TableCell>
        {team.type === "guest"
          ? "❓: "
          : team.type === "independent"
            ? "👤: "
            : "👥: "}
        {team.name}
      </TableCell>
      <TableCell>{team.primaryEmail}</TableCell>
      <TableCell>{team.type}</TableCell>
      <TableCell>{team.members.length}</TableCell>
      <TableCell>
        <Box ref={cardRef} onClick={() => setIsMenuOpen(true)}>
          <MenuFlyout
            cardRef={cardRef}
            options={options}
            isOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}
export function TeamManagementPage({}: {}) {
  const [teamFilterQuery, setTeamFilterQuery] = React.useState("");
  const [page, setPage] = React.useState(0);
  const teamsPageSize = 10;
  const userContext = useContext(LoggedInUserContext);

  const [{ data: teams, loading: loadingTeams }] = apiHooks.teams.search(
    teamFilterQuery,
    teamsPageSize,
    page * teamsPageSize,
  );

  const numPages =
    teams && teams.count ? Math.ceil(teams.count / teamsPageSize) : 1;

  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <TextField
          placeholder={"Filter teams..."}
          sx={{ width: "100%" }}
          value={teamFilterQuery}
          onChange={(e) => {
            setTeamFilterQuery(e.target.value);
            setPage(0);
          }}
        />
        <Box
          css={css`
            @media (max-width: ${breakpoints.values.sm}px) {
              margin-left: ${theme.spacing(2)};
            }

            display: flex;
            margin-bottom: ${theme.spacing(2)};
            margin-left: ${theme.spacing(2)};
          `}
        >
          <Button
            variant={"contained"}
            label={"Create"}
            endIcon={ButtonIcon.add}
            onClick={() => navigate(routeFns.teamCreateEdit(v4()))}
          />
        </Box>
      </Box>
      {!teams ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <button onClick={() => page > 0 && setPage(page - 1)}>&#60;</button>
            <Box sx={{ marginLeft: "1em", marginRight: "1em" }}>
              Page {page + 1} of {numPages}
            </Box>
            <button onClick={() => page < numPages - 1 && setPage(page + 1)}>
              &#62;
            </button>
          </Box>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Type</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}># Members</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teams.results?.map((team) => (
                  <TeamManagementRow team={team} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}
