import { ExtendedForm, getBaseFormTemplate } from "@aspire/common";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, FormTitle } from "~/components/design-system/index.js";
import { PatientBanner } from "~/components/layout/index.js";
import { apiHooks } from "../api.js";
import { MultiPagePdf } from "../components/MultiPagePdf.js";
import { usePatientTimeline } from "../hooks/apiCalls.js";
import { routeFns } from "../routes.js";
import { DefaultPageProps } from "./defaultProps.js";

function FormPdfPageInner({
  pdfData,
  form,
}: {
  form: ExtendedForm<any>;
  pdfData: string;
}) {
  const template = getBaseFormTemplate(
    form.formTemplate.id,
    form.formTemplate.version,
  )!;
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { patientTimeline, reloadPatientTimeline } = usePatientTimeline({
    patientId: form.patientId,
  });

  const nhsNumber = patientTimeline?.patient?.nhsNumber;

  return (
    <>
      <PatientBanner
        patient={form.patient}
        nhsNumber={nhsNumber ?? undefined}
        patientTimeline={patientTimeline}
        reloadPatientTimeline={reloadPatientTimeline}
      />
      <FormTitle
        useReducedTopPadding={true}
        titleText={`${template.formName}${template.description && `:`} ${
          template.description
        }`}
        subtitleText={`Section ${template.section}`}
      />
      <Box sx={{ marginBottom: "1em" }}>
        <Button
          label={t("buttonLabels.backToForm")}
          onClick={() =>
            navigate(routeFns.formContextPage(form.id, form.patientId))
          }
        />
      </Box>
      <MultiPagePdf data={pdfData} />
    </>
  );
}

export function FormPdfPage({}: DefaultPageProps) {
  let { formId } = useParams();

  const [{ data: form, loading: formLoading }] = apiHooks.forms.get(formId!);

  const [{ data: formPdf, loading: formPdfLoading }] = apiHooks.forms.getPdf(
    formId!,
  );

  return formLoading || formPdfLoading ? (
    <></>
  ) : !form ? (
    // TODO: generic 404 component
    <div>Oops - couldn't find that draft!</div>
  ) : (
    <FormPdfPageInner form={form} pdfData={formPdf!} />
  );
}
