import { Error, InfoOutlined, Warning } from "@mui/icons-material";
import { Box, Icon, Typography, useTheme } from "@mui/material";
import { isArray } from "lodash-es";
import React, { Fragment, useMemo } from "react";
import { IconType } from "../../types/subText.js";

export interface HelperTextProps {
  subtext?: string;
  errorMessage?: string | string[];
  warningMessage?: string;
}

export const HelperText = (props: HelperTextProps) => {
  const theme = useTheme();

  const messageType = ((props.errorMessage && "error") ??
    (props.warningMessage && "warning") ??
    "info") as IconType;

  const color = (() => {
    if (messageType === "error") return theme.palette.error.main;
    if (messageType === "warning") return theme.palette.warning.main;
    if (messageType === "info") return theme.palette.text.primary;
    return theme.palette.text.primary;
  })();

  const dataTestId = useMemo(() => {
    if (messageType === "error") return "helper-text-error";
    if (messageType === "warning") return "helper-text-warning";
    if (messageType === "info") return "helper-text-info";
  }, [messageType]);

  const renderMultiLineErrorMessage = () => {
    const errorMessage = props.errorMessage as string[];
    return (
      <>
        {errorMessage.map((line: string, index: number) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ))}
      </>
    );
  };

  return (
    <Box
      className={messageType === "error" ? "thalamosErrorMessage" : ""}
      data-testid={dataTestId}
      sx={{
        display: "flex",
        flexGrow: 1,
        marginTop: theme.spacing(0.5),
        color: color,
      }}
    >
      <Icon style={{ display: "flex" }} component="div">
        {messageType === "error" && (
          <Error data-testid="error" sx={{ color: color }} />
        )}
        {messageType === "warning" && (
          <Warning data-testid="warning" sx={{ color: color }} />
        )}
        {messageType === "info" && (
          <InfoOutlined data-testid="info" sx={{ color: color }} />
        )}
      </Icon>
      <Typography
        sx={{
          fontSize: theme.spacing(1.75),
          alignSelf: "start",
          marginLeft: theme.spacing(1),
        }}
      >
        {props.errorMessage && isArray(props.errorMessage)
          ? renderMultiLineErrorMessage()
          : props.errorMessage || props.warningMessage || props.subtext}
      </Typography>
    </Box>
  );
};
