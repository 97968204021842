import { ExtendedThalamosUser, isGuestUser } from "@aspire/common";
import { Box, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";

import { ExtendedWorkItem, WorkItemAction } from "@aspire/common";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { routeFns } from "../../routes.js";

import { css } from "@emotion/react";
import { Dayjs } from "dayjs";
import {
  Banner,
  BannerList,
  LoadingSpinner,
} from "~/components/design-system/index.js";
import { breakpoints } from "../../styles/themeCommon.js";

import { LoggedInUserContext } from "../../Contexts.js";
import { useRetriveWorkItems } from "../../hooks/apiCalls.js";
import NoResultsBanners from "./NoResultsBanners.js";
import PaginationControls from "./PaginationControls.js";
import RenderedTable from "./RenderedTable.js";
import SearchAndFilters from "./SearchAndFilters.js";
import WorkTabs from "./WorkTabs.js";
import { SelectedFilteredOptions, WorkMode } from "./types.js";

import { getFullFormName } from "@aspire/common";

const pageLimit = 30;

export function CaseloadPage() {
  const theme = useTheme();

  const userContext = React.useContext(LoggedInUserContext);
  const { user } = userContext!;

  const contextId = (
    user.sessionContext?.type === "team"
      ? user.sessionContext.teamId
      : user.sessionContext?.organisationId
  )!;

  const [page, setPage] = useState(0);
  const [patientSearchFilter, setPatientSearchFilter] = useState("");
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedFilteredOptions, setSelectedFilteredOptions] =
    useState<SelectedFilteredOptions>({});

  const [mode, setMode] = useState<WorkMode>("caseload");

  const [selectedTab, setSelectedTab] = useState(0);

  const { pathname } = useLocation();
  const { t } = useTranslation();

  React.useEffect(() => {
    switch (pathname) {
      case routeFns.caseloadUser():
        setMode("user-caseload");
        break;
      case routeFns.caseloadCaseload():
        setMode("caseload");
        break;
      case routeFns.caseloadArchive():
        setMode("archive");
        break;
    }
  }, [pathname]);

  const userSpecifiedFromDate = fromDate?.isValid()
    ? fromDate?.tz("Europe/London").format("YYYY-MM-DD")
    : undefined;

  const { work, workLoading } = useRetriveWorkItems({
    userId: user.id,
    contextId,
    mode,
    pageLimit,
    page,
    patientSearchFilter,
    selectedFilteredOptions,
    userSpecifiedFromDate,
    toDate,
  });

  const selectedWork = work
    ? mode === "caseload"
      ? work.caseload
      : mode === "user-caseload"
        ? work.myCaseload
        : work.archive
    : null;

  const noSearchOrFilterResults =
    !!patientSearchFilter || !!fromDate || !!toDate || !!selectedOptions.length;

  useEffect(() => {
    setPage(0);
  }, [patientSearchFilter, selectedFilteredOptions, fromDate, toDate]);

  return (
    <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
      <Box
        css={css`
          @media (min-width: ${breakpoints.values.sm}px) {
            position: relative;
            bottom: ${theme.spacing(1.05)};
            height: ${theme.spacing(0.375)};
            background-color: ${theme.palette.common.background};
          }
        `}
      />

      <SearchAndFilters
        user={user}
        patientSearchFilter={patientSearchFilter}
        formFullName={getFullFormName}
        selectedOptions={selectedOptions}
        setPatientSearchFilter={setPatientSearchFilter}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        work={work}
        setToDate={setToDate}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        setSelectedFilteredOptions={setSelectedFilteredOptions}
        setSelectedOptions={setSelectedOptions}
      />
      <WorkTabs
        mode={mode}
        work={work}
        user={user}
        setPage={() => setPage(0)}
      />

      {isGuestUser(user) && mode === "archive" && (
        <Box sx={{ mb: 2 }}>
          <Banner
            bannerType={BannerList.WARNING}
            title={t("pages.caseload.guestUserBanner")}
          />
        </Box>
      )}
      {workLoading ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <LoadingSpinner />
        </Box>
      ) : (
        <CaseloadPageInner
          page={page}
          setPage={setPage}
          user={user}
          work={{
            senderNames: work?.senderNames || [],
            work: selectedWork?.work || [],
            count: selectedWork?.count || 0,
          }}
          noSearchOrFilterResults={noSearchOrFilterResults}
          mode={mode}
        />
      )}
    </Box>
  );
}

function CaseloadPageInner({
  user,
  page,
  setPage,
  work,
  mode,
  noSearchOrFilterResults,
}: {
  user: ExtendedThalamosUser;
  page: number;
  setPage: (page: number) => void;
  work: {
    senderNames: { username: string }[];
    work: (ExtendedWorkItem & { actions: WorkItemAction[] })[];
    count: number;
  };
  mode: WorkMode;
  noSearchOrFilterResults: boolean;
}) {
  const numPages = Math.ceil(work?.count / pageLimit);

  const isNhsNumberConfigEnabled =
    user.sessionOrganisationConfiguration?.nhsNumberEnabled ?? false;

  return (
    <>
      {!!work?.count ? (
        <>
          <PaginationControls
            numPages={numPages}
            page={page}
            setPage={setPage}
          />
          <RenderedTable work={work} showNhsNumber={isNhsNumberConfigEnabled} />
          <PaginationControls
            numPages={numPages}
            page={page}
            setPage={setPage}
          />
        </>
      ) : (
        <NoResultsBanners
          mode={mode}
          noSearchOrFilterResults={noSearchOrFilterResults}
          user={user}
        />
      )}
    </>
  );
}
