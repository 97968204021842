import { css } from "@emotion/react";
import { Box, SvgIcon, Typography, useTheme } from "@mui/material";
import React from "react";

export const FancyIcon = ({
  icon,
  iconColor,
  label,
  labelColor,
  size,
}: {
  icon: typeof SvgIcon;
  iconColor?: string;
  label?: string;
  labelColor?: string;
  size?: number;
}) => {
  const theme = useTheme();
  const styles = {
    checkContainer: css`
      justify-content: center;
      align-items: center;
      width: ${size ?? theme.spacing(3)};
      height: ${size ?? theme.spacing(3)};
    `,
  };
  const Icon = icon;

  return (
    <Box display="flex" gap={theme.spacing(1)}>
      <Box css={styles.checkContainer}>
        <Icon htmlColor={iconColor} />
      </Box>
      {label ? <Typography color={labelColor}> {label}</Typography> : null}
    </Box>
  );
};
