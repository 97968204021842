import {
  FormContextData,
  formIsApplication,
  getBaseFormTemplate,
  UUID,
} from "@aspire/common";
import { Box, useTheme } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Banner,
  BannerList,
  Button,
  Dropdown,
  PopupDialog,
  PopupDialogTitle,
  ReadOnlyContent,
  renderErrorToast,
  renderSuccessToast,
  TextField,
} from "~/components/design-system/index.js";
import { api } from "../../../api.js";
import { routeFns } from "../../../routes.js";

export interface ReasonOption {
  value: string;
  label: string;
}

const sharedReasons = [
  {
    value: "incorrect-user-details",
    label: "Name, address or email address of person completing form incorrect",
  },
  {
    value: "different-patient-name-address",
    label: "Differences in the patient’s name and/or address",
  },
  {
    value: "incorrect-patient-name-address",
    label: "Name and/or address of patient incorrect",
  },
  {
    value: "misspelt-hospital",
    label: "Name and/or address of hospital misspelt",
  },
];
const otherReason = { value: "other", label: "Other" };
const previousAcquaintanceReason = {
  value: "incorrect-previous-acquiantance",
  label: "Incorrect selection of previous acquaintance",
};

const medRecReasons = [
  ...sharedReasons,
  {
    value: "incorrect-examination-datetime",
    label: "Examination date and/or time incorrect",
  },
  {
    value: "innacurate-or-incomplete-medical-reasonsing",
    label: "Medical reasoning inaccurate or incomplete",
  },
  {
    value: "incorrect-risk-categories",
    label: "Incorrect selection of risk categories",
  },
  previousAcquaintanceReason,
  {
    value: "incorrect-s12-status",
    label: "Incorrect selection of section 12 status",
  },
  otherReason,
];

const applicationReasons = [
  ...sharedReasons,
  { value: "datetime-incorrect", label: "Date and/or time incorrect" },
  {
    value: "incorrect-nearest-relative-details",
    label: "Details of nearest relative or person authorised incorrect",
  },
  { value: "incorrect-local-authority", label: "Incorrect local authority" },
  previousAcquaintanceReason,
  otherReason,
];

export function RequestAmendDialog({
  formId,
  formTemplateId,
  onClose,
  reloadFormContext,
  formContext,
}: {
  formContext: Omit<FormContextData, "patient">;
  formId: UUID;
  formTemplateId: string;
  onClose: () => void;
  reloadFormContext: () => void;
}) {
  const theme = useTheme();
  const navigate = useNavigate();

  const template = getBaseFormTemplate(formTemplateId)!;
  const section = template.section;
  const formDescription = `Form ${template.formName}: Section ${template.section} - ${template.description}`;
  const s15AmendTimePeriod = section === "4" ? "72 hours" : "14 days";

  const isApplication = formIsApplication(template);

  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [additionalInfo, setAdditionalInfo] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const availableReasons = isApplication ? applicationReasons : medRecReasons;

  const canContinue =
    !isSubmitting && !!selectedReasons.length && !!additionalInfo;

  const currentForm = formContext.forms.find((f) => f.id === formId);

  const sendAmendRequest = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const result = await api.forms.requestAmend(formId, {
        formPart: 1,
        reasons: availableReasons.filter((r) =>
          selectedReasons.includes(r.value),
        ),
        additionalInfo,
      });
      if (result.status === 204) {
        const message = `Amend request successfully sent`; // to ${formikValues.values.name}`;
        renderSuccessToast({ message });
        reloadFormContext();
        navigate(
          routeFns.formContextPageSuccessDialog(
            formContext.id,
            formContext.patientId,
            {
              title: "Amend request successfully sent",
              message: message,
            },
          ),
          { replace: true },
        );
      } else {
        const errorMessage = (result.data as any)?.reason || "Unknown error";
        throw new Error(errorMessage);
      }
    } catch (e: any) {
      renderErrorToast({ message: e.message });
      setSubmitError(e.message);
      setIsSubmitting(false);
    }
  }, [formId, selectedReasons, availableReasons, additionalInfo]);

  const bannerBody =
    section === "4"
      ? [
          `You are about to request ${formDescription} to be amended under Section 15 of the Act. The request can only be sent to the person who signed this form.`,
          `The form can be amended within ${s15AmendTimePeriod} after admission if the form is found to be incorrect or defective. `,
        ]
      : [
          `You are about to request ${formDescription} to be amended under Section 15 of the Act. The request can only be sent to the person(s) who signed this form. `,
          `The form can be amended within ${s15AmendTimePeriod} after admission if the form is found to be incorrect or defective. Please note that there are different legal opinions as to if it is acceptable to amend joint medical recommendations under s15. Please discuss your amend with your local mental health act team if you are unsure.`,
        ];

  const currentVersion = currentForm?.versions.find(
    (v) => v.version === currentForm.latestVersion,
  );

  const getAllSignatureNameAndEmail = currentVersion?.signatures.map((s) => {
    const userData = currentVersion.data.find(
      (userData: any) => userData.data.user.id === s.userId,
    );

    return { name: s.userName, email: userData?.data?.user?.email };
  });

  return (
    <PopupDialog open={true} onClose={onClose}>
      <PopupDialogTitle
        titleText="Request Section 15 Amend"
        closeDialog={onClose}
      />
      <Box sx={{ mb: 2 }}>
        <Banner
          body={[
            ...bannerBody,
            "Certain errors on forms may invalidate the patient’s detention and cannot be corrected, a new form must be provided in these circumstances. ",
          ]}
          bannerType={BannerList.WARNING}
        />
      </Box>
      <Dropdown
        label="Please select a reason why this form needs to be amended"
        multiple
        name="reason"
        values={availableReasons}
        selectedValue={selectedReasons}
        onChange={(e) => {
          setSelectedReasons(e);
        }}
      />
      <Box sx={{ mt: 2 }}>
        <TextField
          name="additionalInfo"
          label="Please provide additional information"
          onChange={(e) => setAdditionalInfo(e)}
          value={additionalInfo}
          useFullWidth={true}
          multiline
          rows="5"
        />

        <ReadOnlyContent
          isActive
          label={"Name and email address of person signed this form"}
          content={
            getAllSignatureNameAndEmail
              ? getAllSignatureNameAndEmail.map(
                  (s) => `${s.name}${s.email ? ", " + s.email : ""}`,
                )
              : []
          }
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginTop: theme.spacing(3),
        }}
      >
        <Button label={"Close"} onClick={onClose} variant="outlined" />
        <Button
          color="primary"
          disabled={!canContinue}
          label={"Confirm"}
          onClick={sendAmendRequest}
        />
      </Box>
    </PopupDialog>
  );
}
