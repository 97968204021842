import { css } from "@emotion/react";
import { Box, useTheme } from "@mui/material";
import React from "react";

interface VerticalLineProps {
  useTopMargin?: boolean;
  useBottomMargin?: boolean;
  color?: string;
  height?: string;
  sx?: any;
}

const defaultProps: Partial<VerticalLineProps> = {
  useTopMargin: false,
  useBottomMargin: false,
};

export const VerticalLine = (props: VerticalLineProps) => {
  const allProps = { ...defaultProps, ...props };
  const { useBottomMargin, useTopMargin, sx } = allProps;

  const theme = useTheme();
  return (
    <Box
      css={css`
        margin-bottom: ${useBottomMargin ? theme.spacing(3) : 0};
        margin-top: ${useTopMargin ? theme.spacing(3) : 0};
        border-left: 1px solid ${props.color || theme.palette.text.disabled};
        height: ${props.height || theme.spacing(4)};
        align-self: center;
      `}
      sx={sx}
    />
  );
};
