import {
  applicationTemplateIds,
  baseTemplates,
  ExtendedWorkItem,
  FormContextType,
  getLatestVersion,
  recordOfDetentionTemplateIds,
  WorkItemMiniForm,
} from "@aspire/common";
import { Theme } from "@mui/material/styles";

export function calculateWorkItemStatus(
  theme: Theme,
  item: {
    formContextType: FormContextType;
    forms: WorkItemMiniForm[];
    otherWorkItems: ExtendedWorkItem["otherWorkItems"];
  },
): {
  statusText: string;
  colour: string;
} {
  const hasMhaActiveWorkItem = item.otherWorkItems.some(
    (i) =>
      i.team.type === "mha" &&
      ["pending", "accepted", "completed"].includes(i.status),
  );

  const legalScrutinyMarkedAsComplete = "Legal scrutiny marked as complete";
  const awaitingLegalScrutiny = "Awaiting legal scrutiny";

  if (item.formContextType === "standalone") {
    const form = item.forms[0];
    const latestVersion = getLatestVersion(form);
    const template = baseTemplates.find(
      (t) =>
        t.id === form.formTemplate.id &&
        t.version === form.formTemplate.version,
    );

    if (form.status === "finalised") {
      return {
        statusText: legalScrutinyMarkedAsComplete,
        colour: theme.palette.statuses.teal,
      };
    }

    if (form.status === "complete") {
      if (hasMhaActiveWorkItem) {
        return {
          statusText: awaitingLegalScrutiny,
          colour: theme.palette.statuses.teal,
        };
      } else {
        return {
          statusText: `${latestVersion.signatures.length} / ${
            template!.parts.length
          } signatures`,
          colour: theme.palette.statuses.teal,
        };
      }
    }

    return {
      statusText: `${latestVersion.signatures.length} / ${
        template!.parts.length
      } signatures`,
      colour:
        latestVersion.signatures.length === 0
          ? theme.palette.common.lightGray
          : theme.palette.statuses.yellow,
    };
  }

  const signedForms =
    item.forms.filter((f) => ["complete", "finalised"].includes(f.status))
      .length > 0;

  const signedApplication = item.forms.some(
    (f) =>
      ["complete", "finalised"].includes(f.status) &&
      applicationTemplateIds.includes(f.formTemplate.id),
  );

  const signedRecordOfDetention = item.forms.find(
    (f) =>
      ["complete", "finalised"].includes(f.status) &&
      recordOfDetentionTemplateIds.includes(f.formTemplate.id),
  );

  const finalised = signedRecordOfDetention?.status === "finalised";

  if (finalised) {
    return {
      statusText: legalScrutinyMarkedAsComplete,
      colour: theme.palette.statuses.teal,
    };
  }

  if (signedRecordOfDetention) {
    if (hasMhaActiveWorkItem) {
      return {
        statusText: awaitingLegalScrutiny,
        colour: theme.palette.statuses.teal,
      };
    } else {
      return {
        statusText: "Record of detention signed",
        colour: theme.palette.statuses.teal,
      };
    }
  }

  if (signedApplication) {
    return {
      statusText: "Awaiting record of detention",
      colour: theme.palette.statuses.yellow,
    };
  }

  if (signedForms) {
    return {
      statusText: "Awaiting medical recommendations",
      colour: theme.palette.statuses.yellow,
    };
  }

  return {
    statusText: "No forms signed",
    colour: theme.palette.common.lightGray,
  };
}
