import React, { useEffect, useState } from "react";

import { ManagedLocationBase, isAdminUser } from "@aspire/common";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InferType } from "yup";
import {
  AddressField,
  Dropdown,
  FormLabel,
  FormTitle,
  ReadOnlyContent,
  TextField,
  Typeahead,
} from "~/components/design-system/index.js";
import {
  DateFormField,
  HorizontalLine,
  Signature,
} from "~/components/form/index.js";
import { Container } from "~/components/layout/styleWrappers.js";
import { config } from "../../config.js";
import { LoggedInUserContext } from "../../Contexts.js";

import { debounce } from "lodash-es";
import { ThalamosLink } from "~/components/ThalamosLink.js";
import { routeFns } from "~/routes.js";
import { api } from "../../api.js";
import { safeCast } from "../FormDraft/FormDraftCompletionPage.js";
import {
  APPROVAL_TYPES,
  PROF_REG_TYPES,
  applyFormInvariants,
  createAccreditations,
  dataSchema,
} from "./UserProfile.js";

export const UserProfileContainer = ({
  values,
  user,
  errors,
  setValues,
  derived,
  localAuthorities,
  fieldProps,
  dispatchAutoSaveState,
  isAutoSaving,
  defaultSelectedContextName,
}: any) => {
  const userContext = React.useContext(LoggedInUserContext);
  const { t } = useTranslation();

  // Debounced auto-save action
  const autoSave = debounce(async (userData: InferType<typeof dataSchema>) => {
    const accreditations = createAccreditations(userData);
    const userDataValues = {
      name: userData.name,
      address: {
        address: userData.address,
        postalCode: userData.postalCode?.trim(),
      },
      defaultSignature: userData.defaultSignature,
      accreditations,
    };
    try {
      const result = await api.users.update(
        user.id,
        safeCast(fieldProps.validationSchema, userDataValues),
      );

      if (result.status === 204) {
        dispatchAutoSaveState("success");
      } else if (result.status === 404) {
        return;
      } else {
        dispatchAutoSaveState("error");
      }
    } catch (error) {
      // TODO: log error to datadog?
      dispatchAutoSaveState("error");
    }
  }, 2000);

  const [lastSavedValues, setLastSavedValues] = useState(fieldProps.values);

  const validateForm = async (values: any) => {
    try {
      // Validate the form data
      await dataSchema.validate(values, { abortEarly: false });
      // If validation passes, there are no errors
      return true;
    } catch (errors: any) {
      // If validation fails, errors will be caught here
      return errors.errors;
    }
  };

  // Trigger auto-save on field change
  useEffect(() => {
    const validateAndAutoSave = async () => {
      // Validate the form data before making the API call

      const validationResult = await validateForm(fieldProps.values);

      // Check if there are no errors and values have changed
      if (validationResult === true && fieldProps.values !== lastSavedValues) {
        // Set auto-saving state to loading
        if (!isAutoSaving) dispatchAutoSaveState("loading");

        // Call autoSave function with the updated values
        autoSave(fieldProps.values);

        // Update the hasErrors state and lastSavedValues
        setLastSavedValues(fieldProps.values);
      }
    };
    validateAndAutoSave();
  }, [fieldProps.values]);

  return (
    <>
      <Container addMarginBottom>
        <Box sx={{ mb: 2 }}>
          <ReadOnlyContent
            isActive
            label={t("common.email")}
            content={[user.email]}
          />
        </Box>
        <TextField
          errorMessage={errors.name as string}
          showHelperText={!!errors.name}
          useFullWidth={true}
          label={t("common.name")}
          name="listItemName"
          value={values.name}
          onChange={(value: string) => setValues({ ...values, name: value })}
        />

        <AddressField
          showHelperText={!!errors.address || !!errors.postalCode}
          errors={errors}
          hideConfirmation={true}
          label={t("common.address")}
          name="userProfileAddress"
          value={{
            address: values.address,
            postalCode: values.postalCode,
          }}
          googleMapsApiKey={config.googleMapsApiKey}
          onChange={(value: { address: string; postalCode: string }) => {
            setValues({
              ...values,
              address: value.address,
              postalCode: value.postalCode,
            });
          }}
          addressCheckBoxLabel={t(
            "forms.nameAddressFormField.addressCheckBoxLabel",
          )}
        />
        {defaultSelectedContextName && (
          <>
            <ReadOnlyContent
              label={t("pages.userProfile.defaultMembership")}
              content={[defaultSelectedContextName]}
              dense
            />
            <ThalamosLink to={routeFns.contextSelector()}>
              <Box sx={{ cursor: "pointer", color: "primary.main", ml: 1.2 }}>
                {t("layout.menu.changeContext")}
              </Box>{" "}
            </ThalamosLink>
          </>
        )}
      </Container>
      <Container addMarginBottom>
        <FormTitle
          useReducedTopPadding
          hasTitleBottomMargin={false}
          subtitleText={t("pages.userProfile.accreditationsSectionTitle")}
        />

        <Dropdown
          label={t("pages.userProfile.profRegDropdown.label")}
          errorMessage={errors.profRegistrations as string}
          name="profRegDropdown"
          values={[
            {
              value: PROF_REG_TYPES.GMC,
              label: t("pages.userProfile.profRegDropdown.options.gmc"),
            },
            {
              value: PROF_REG_TYPES.NMC,
              label: t("pages.userProfile.profRegDropdown.options.nmc"),
            },
            {
              value: PROF_REG_TYPES.HCPC,
              label: t("pages.userProfile.profRegDropdown.options.hcpc"),
            },
            {
              value: PROF_REG_TYPES.SWE,
              label: t("pages.userProfile.profRegDropdown.options.swe"),
            },
          ]}
          multiple
          selectedValue={
            Array.isArray(values.profRegistrations)
              ? values.profRegistrations
              : [values.profRegistrations]
          }
          onChange={(profRegistrations: string[]) => {
            const newValues = { ...values, profRegistrations };
            setValues(applyFormInvariants(newValues));
          }}
        />

        <Dropdown
          label={t("pages.userProfile.mhApprovalsDropdown.label")}
          errorMessage={errors.mhApprovals as string}
          name="mhApprovalsDropdown"
          values={[
            {
              value: APPROVAL_TYPES.AMHP,
              disabled: !derived.canBeAmhp,
              label: t("pages.userProfile.mhApprovalsDropdown.options.amhp"),
            },
            {
              value: APPROVAL_TYPES.AC,
              disabled: !values.profRegistrations.length,
              label: t("pages.userProfile.mhApprovalsDropdown.options.ac"),
              sublabel: t("pages.userProfile.mhApprovalsDropdown.info.ac"),
            },
            {
              value: APPROVAL_TYPES.S12,
              // S12 option is disabled when:
              //  - User is not a Registered Medical Practitioner (fixed as unselected)
              //  - User is a Registered Medical Practitioner with Approved Clinician status (fixed as selected)
              disabled:
                !derived.isRmp ||
                (derived.isRmp && derived.isApprovedClinician),
              label: t("pages.userProfile.mhApprovalsDropdown.options.s12"),
              sublabel: t("pages.userProfile.mhApprovalsDropdown.info.s12"),
            },
          ]}
          multiple
          selectedValue={
            Array.isArray(values.mhApprovals)
              ? values.mhApprovals
              : [values.mhApprovals]
          }
          onChange={(mhApprovals: string[]) => {
            // Unselect S12 approval if user is an RMP and AC approval is unselected. This is done because
            // for RMP's, selecting AC auto-selects S12. This isn't entirely precise as the user could also have
            // separately checked S12 themselves, but errs on the side of not selecting an approval.
            if (
              derived.isRmp &&
              derived.isApprovedClinician &&
              !mhApprovals.includes(APPROVAL_TYPES.AC)
            ) {
              mhApprovals = mhApprovals.filter((a) => a !== APPROVAL_TYPES.S12);
            }

            const newValues = { ...values, mhApprovals };
            setValues(applyFormInvariants(newValues));
          }}
        />

        <HorizontalLine topMargin />

        {derived.isRmp && (
          <TextField
            useFullWidth
            onChange={(gmcNumber: string) =>
              setValues({ ...values, gmcNumber })
            }
            name={"gmcNumber"}
            showHelperText
            errorMessage={errors.gmcNumber}
            label={t("pages.userProfile.gmcNumberLabel")}
            value={values.gmcNumber || ""}
          />
        )}

        {derived.isNurse && (
          <TextField
            useFullWidth
            onChange={(pinNumber: string) =>
              setValues({ ...values, pinNumber })
            }
            name={"pinNumber"}
            showHelperText
            errorMessage={errors.pinNumber as string}
            label={t("pages.userProfile.pinNumberLabel")}
            value={values.pinNumber || ""}
          />
        )}

        {derived.isHcpc && (
          <TextField
            useFullWidth
            onChange={(hcpcNumber: string) =>
              setValues({ ...values, hcpcNumber })
            }
            name={"hcpcNumber"}
            showHelperText
            errorMessage={errors.hcpcNumber as string}
            label={t("pages.userProfile.hcpcNumberLabel")}
            value={values.hcpcNumber || ""}
          />
        )}

        {derived.isSocialWorker && (
          <TextField
            useFullWidth
            onChange={(socialWorkerNumber: string) =>
              setValues({ ...values, socialWorkerNumber })
            }
            name={"socialWorkerNumber"}
            showHelperText
            errorMessage={errors.socialWorkerNumber as string}
            label={t("pages.userProfile.socialWorkerNumberLabel")}
            value={values.socialWorkerNumber || ""}
          />
        )}

        {derived.isAmhp && (
          <>
            <FormLabel label={t("pages.userProfile.localAuthorityFormLabel")} />
            <Typeahead
              useFullWidth
              options={localAuthorities}
              inputValue={values.localAuthority || ""}
              errorMessage={errors.localAuthority}
              optionsKey={"name"}
              name={"la"}
              getOptionLabel={(la: ManagedLocationBase | string) => {
                if (typeof la === "string") {
                  return la;
                } else {
                  return la.name;
                }
              }}
              onInputChange={(value: string | null) => {
                if (value) {
                  setValues({ ...values, localAuthority: value });
                } else {
                  setValues({ ...values, localAuthority: null! });
                }
              }}
            />
          </>
        )}

        {derived.isApprovedClinician && (
          <DateFormField
            field={{
              type: "date",
              field: "approvedClinicianExpiryDate",
              label: t("pages.userProfile.approvedClinicianExpiryDate"),
            }}
            fieldProps={fieldProps}
          />
        )}

        {derived.isSection12Approved &&
          !(derived.isRmp && derived.isApprovedClinician) && (
            <DateFormField
              field={{
                type: "date",
                field: "section12ExpiryDate",
                label: t(
                  "pages.onBoarding.confirmOccupation.section12ExpiryDate",
                ),
              }}
              fieldProps={fieldProps}
            />
          )}
      </Container>
      {!isAdminUser(userContext?.user) && (
        <Container addMarginBottom>
          <Signature
            name="profileFormSignature"
            checkboxLabel={t("pages.common.signature.checkboxLabel")}
            confirmButtonLabel={t("buttonLabels.confirm")}
            clearButtonLabel={t("pages.common.signature.clearButtonLabel")}
            formSignature={values?.defaultSignature?.toString()}
            saveSignature={(e) => setValues({ ...values, defaultSignature: e })}
            userDefaultSignature={user.defaultSignature}
            hideSavedSignature
            showSaveDefaultSignatureOption={false}
          />
        </Container>
      )}
    </>
  );
};
