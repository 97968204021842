import { TermsAndConditions } from "@aspire/common";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import Markdown from "react-markdown";
import { useNavigate, useParams } from "react-router";
import { api, apiHooks } from "~/api.js";
import {
  Button,
  LoadingSpinner,
  renderErrorToast,
  renderSuccessToast,
  TextField,
} from "~/components/design-system/index.js";
import { routeFns } from "~/routes.js";

function TermsAndConditionsCreateEditPageInner(props: {
  termsAndConditionsId: string;
  termsAndConditions: TermsAndConditions | null;
  refetchTermsAndConditions: () => void;
}) {
  const navigate = useNavigate();

  const isCreate = props.termsAndConditions === null;

  const [humanReadableName, setHumanReadableName] = useState(
    props.termsAndConditions?.humanReadableName ?? "",
  );

  const [markdown, setMarkdown] = useState(
    props.termsAndConditions?.markdown ?? "",
  );

  return (
    <>
      <Typography variant="h3">
        Terms and Conditions - {isCreate ? "Add New" : "View"}
      </Typography>
      <TextField
        useFullWidth
        label="Internal Name"
        name="humanReadableName"
        disabled={!isCreate}
        value={humanReadableName}
        onChange={setHumanReadableName}
      />
      <Box sx={{ width: "100%", display: "flex", gap: "10px" }}>
        <Box sx={{ width: "100%" }}>
          <TextField
            useFullWidth
            name="markdown"
            disabled={!isCreate}
            multiline
            value={markdown}
            rows="20"
            onChange={setMarkdown}
          />
        </Box>
        <Box
          sx={{ paddingLeft: "1em", border: "1px solid gray", width: "100%" }}
        >
          <Markdown>{markdown}</Markdown>
        </Box>
      </Box>
      {isCreate && (
        <Button
          label="Create"
          disabled={!humanReadableName.trim() || !markdown.trim()}
          onClick={async () => {
            const response = await api.termsAndConditions.create(
              props.termsAndConditionsId,
              {
                humanReadableName: humanReadableName.trim(),
                markdown: markdown.trim(),
              },
            );

            if (response.status === 204) {
              renderSuccessToast({
                message: "Terms and Conditions created successfully",
              });
            } else {
              renderErrorToast({
                message:
                  "Failed to create Terms and Conditions: " +
                  response.statusText,
              });
            }
            navigate(routeFns.termsAndConditionsManagementPage());
          }}
        />
      )}
    </>
  );
}

export function TermsAndConditionsCreateEditPage({}: {}) {
  let { termsAndConditionsId } = useParams();

  const [
    { data: termsAndConditions, loading: termsAndConditionsLoading, response },
    refetchTermsAndConditions,
  ] = apiHooks.termsAndConditions.get(termsAndConditionsId!);

  return termsAndConditionsLoading ? (
    <LoadingSpinner />
  ) : (
    <TermsAndConditionsCreateEditPageInner
      termsAndConditionsId={termsAndConditionsId!}
      termsAndConditions={response?.status === 200 ? termsAndConditions! : null}
      refetchTermsAndConditions={refetchTermsAndConditions}
    />
  );
}
