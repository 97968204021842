export const myTeamColor = "#EAEAEB";

export const otherTeamColorPalette = [
  "#FFF1E6",
  "#FDE2E4",
  "#FAD2E1",
  "#E2ECE9",
  "#BEE1E6",
  "#F0EFEB",
  "#DFE7FD",
  "#CDDAFD",
];

export function getRandomColor() {
  return (
    "hsl(" +
    360 * Math.random() +
    "," +
    (25 + 70 * Math.random()) +
    "%," +
    (85 + 10 * Math.random()) +
    "%)"
  );
}

export function getNextColor(participantColours: Map<string, string>) {
  const strings = [...participantColours.values()];
  const length = strings.length;

  if (length < otherTeamColorPalette.length) {
    return otherTeamColorPalette[length - 1];
  } else {
    return getRandomColor();
  }
}
