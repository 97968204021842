import { ExtendedThalamosUser } from "@aspire/common";
import { Box, Checkbox, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Banner,
  BannerList,
  Button,
  FormTitle,
  PopupDialog,
  ReadOnlyContent,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { api } from "../../../../api.js";
import { useGetFileNotes } from "../../../../hooks/apiCalls.js";
import { routeFns } from "../../../../routes.js";

export function DeleteFileNoteDialog({
  formId,
  closeFn,
  reloadFormContext,
  formContextId,
  patientId,
  user,
}: {
  formId: string;
  closeFn: () => void;
  reloadFormContext: () => void;
  formContextId: string;
  patientId: string;
  user: ExtendedThalamosUser;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const [selectedNoteIds, setSelectedNoteIds] = useState<string[]>([]);

  const navigate = useNavigate();

  const { fileNotes } = useGetFileNotes(formId, user);

  const hasFileNotes = fileNotes && fileNotes.length > 0;

  const sendFileNoteRequest = async () => {
    setIsSubmitting(true);
    try {
      const result = await api.forms.deleteFileNotes(formId, {
        fileNoteIds: selectedNoteIds.toString(),
      });
      if (result.status === 204) {
        const message = `A file note(s) was successfully deleted`;
        renderSuccessToast({ message });
        reloadFormContext();
        navigate(
          routeFns.formContextPageSuccessDialog(formContextId, patientId, {
            title: "Action successful",
            message: message,
          }),
          { replace: true },
        );
      } else {
        const errorMessage = (result.data as any)?.reason || "Unknown error";
        throw new Error(errorMessage);
      }
    } catch (e: any) {
      renderErrorToast({ message: e.message });
      setSubmitError(e.message);
      setIsSubmitting(false);
    }
  };

  if (submitError) {
    return <div>{submitError}</div>;
  }

  return (
    <PopupDialog open={true} onClose={closeFn} testId="delete-file-note">
      <FormTitle
        useReducedTopPadding
        hasTitleBottomMargin={false}
        subtitleText={"Delete file note(s)"}
      />
      <Box sx={{ mb: 2 }}>
        <Banner
          bannerType={BannerList.WARNING}
          title={
            "Please select the file note you want to delete. The file note will be deleted from the form PDF once confirmed."
          }
        />
      </Box>
      {hasFileNotes ? (
        fileNotes.map((f: any) => (
          <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
            <Checkbox
              sx={{ color: "primary.main", maxHeight: 100 }}
              onChange={() => {
                setSelectedNoteIds((prevSelected) => {
                  const newSelected = selectedNoteIds.filter(
                    (id) => id !== f.fileNoteId,
                  );

                  if (newSelected.length === prevSelected.length) {
                    // If the file note was not in the array, add it
                    return [...prevSelected, f.fileNoteId];
                  } else {
                    // If the file note was in the array, remove it
                    return newSelected;
                  }
                });
              }}
              checked={selectedNoteIds.some((s) => s === f.fileNoteId)}
            />
            <Box display={"flex"} flexDirection="column">
              <ReadOnlyContent
                label={"File Note:"}
                content={[f.note]}
                isActive
              />
              <Typography sx={{ my: 1, fontSize: 12, color: "primary.hint" }}>
                {f.createdBy}
              </Typography>
            </Box>
          </Box>
        ))
      ) : (
        <Box sx={{ fontWeight: "bold" }}>
          No file notes have been added to this form.
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          mt: 3,
        }}
      >
        <Button label={"Close"} onClick={closeFn} variant="outlined" />
        <Button
          color="primary"
          disabled={selectedNoteIds.length <= 0 && !isSubmitting}
          label={"Delete"}
          onClick={sendFileNoteRequest}
        />
      </Box>
    </PopupDialog>
  );
}
