import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Drawer as MuiDrawer,
} from "@mui/material";
import { CSSObject, styled, Theme } from "@mui/material/styles";

export const drawerWidth = 220;

export interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  borderRight: "none",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  overflowX: "hidden",
  backgroundColor: theme.palette.background.lightGray,
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  borderRight: "none",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(15)} + 1px)`,
  },
  backgroundColor: theme.palette.background.lightGray,
});

export const DrawerHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  display: "flex",
  marginTop: theme.spacing(1),
  overflow: "visable",
  alignItems: "center",
  padding: open ? theme.spacing(0, 2.5) : theme.spacing(0, 5),
  ...theme.mixins.toolbar,
}));

export const DropdownStyles = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  display: "flex",
  ...(open && { padding: theme.spacing(1, 2.5) }),
  margin: open ? theme.spacing(1, 1, 1, 0) : theme.spacing(2, 2, 2, 2),
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  marginTop: theme.spacing(1.6),
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  boxShadow:
    "0px 18px 28px rgba(84, 89, 107, 0.1), 0px 1px 1px rgba(84, 89, 107, 0.21)",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
