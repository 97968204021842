import React, { useEffect } from "react";

import { isAdminUser, isManagerUser, isTeamManagerUser } from "@aspire/common";
import { useNavigate } from "react-router";
import { routeFns } from "~/routes.js";
import { LoggedInUserContext } from "../Contexts.js";
import { AdminPage } from "./Admin/AdminPage.js";
import { CaseloadPage } from "./Caseload/Caseload.js";
import { DashboardPage } from "./DashboardPage.js";
import { DefaultPageProps } from "./defaultProps.js";
import { TeamsOverviewPage } from "./ExternalAdmin/Teams/TeamsOverviewPage.js";

export function HomePage(props: DefaultPageProps) {
  const userContext = React.useContext(LoggedInUserContext);
  const user = userContext?.user!;
  const navigate = useNavigate();

  const isTeamManager = isTeamManagerUser(user);
  const userSessionTeamId = user?.sessionContext?.teamId;

  useEffect(() => {
    if (isTeamManager && userSessionTeamId) {
      navigate(routeFns.managerTeamEdit(userSessionTeamId));
    }
  }, [userContext]);

  // We should have a user and a user context by this point
  if (!userContext?.user?.sessionContext) {
    return <div>Loading...</div>;
  }

  const isCurrentUserManager = isManagerUser(user);

  return isAdminUser(user) ? (
    <AdminPage {...props} />
  ) : isCurrentUserManager ? (
    <TeamsOverviewPage {...props} />
  ) : userContext?.user?.sessionContext?.type === "team" ? (
    <CaseloadPage {...props} />
  ) : (
    <DashboardPage {...props} />
  );
}
