import React from "react";

export const useStickyHeader = () => {
  const [isSticky, setIsSticky] = React.useState(false);

  const toggleSticky = () => {
    const scrollTop = window.scrollY;
    const maxScrollAvailable = document.body.scrollHeight - window.innerHeight;

    // Patient banner was flickering when not enough content available
    // With MHA status it's about 250px tall
    // So should only disappear when there's enough content to replace it
    if (scrollTop >= 70 && maxScrollAvailable > 250) {
      setIsSticky(true);
    }

    if (scrollTop < 30) {
      setIsSticky(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", toggleSticky);
    return () => {
      window.removeEventListener("scroll", toggleSticky);
    };
  }, []);

  return { isSticky };
};
