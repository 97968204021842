import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import { v4 } from "uuid";
import { api, apiHooks } from "~/api.js";
import {
  Button,
  Dropdown,
  renderErrorToast,
  renderSuccessToast,
  TextField,
} from "~/components/design-system/index.js";
import { HorizontalLine } from "~/components/form/HorizontalLine.js";
import { routeFns } from "~/routes.js";
import { TeamPicker } from "./TeamPicker.js";

export function PatientBulkImportTokenCreate({}: {}) {
  const navigate = useNavigate();
  const token = useMemo(() => v4(), []);

  const [{ data, loading: loadingRioInstances, error }] = apiHooks.rio.search(
    "",
    1000,
    0,
  );

  const rioInstances = data?.results || [];

  const [team, setTeam] = React.useState({ id: null, name: "" });
  const [rioInstanceSelected, setRioInstanceSelected] = React.useState<
    string | null
  >(null);

  const [trustName, setTrustName] = React.useState("");
  const [trustOdsCode, setTrustOdsCode] = React.useState("");

  const canCreate = team.id && rioInstanceSelected && trustName && trustOdsCode;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <Typography variant="h4">Patient Bulk Import Tokens</Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <Typography sx={{ marginBottom: "2em" }}>
          Create a link for a new client to bulk import patients and patient MHA
          statuses. This link will be valid for 1 month from creation and must
          be used by a user of the Team selected below.
        </Typography>

        <TeamPicker
          idField={"id"}
          nameField={"name"}
          values={team}
          setValues={setTeam}
          error={null}
          label="Team (*)"
        />
        <Dropdown
          name="rioInstance"
          onChange={(e: string) => {
            setRioInstanceSelected(
              rioInstances.find((r) => r.instanceId === e)!.instanceId,
            );
          }}
          label="RIO Instance (*)"
          selectedValue={rioInstanceSelected ?? ""}
          values={rioInstances.map((r) => ({
            value: r.instanceId,
            label: r.displayName,
          }))}
        />

        <TextField
          name="trustName"
          label="Trust Name (*)"
          value={trustName}
          onChange={(e) => setTrustName(e)}
          useFullWidth
        />

        <TextField
          name="trustOdsCode"
          label="Trust ODS Code"
          value={trustOdsCode}
          onChange={(e) => setTrustOdsCode(e)}
          useFullWidth
        />

        <Box sx={{ marginTop: "1em", marginBottom: "1em" }}>
          <HorizontalLine />
        </Box>

        <Button
          label="Create"
          disabled={!canCreate}
          onClick={async () => {
            const result = await api.patientBulkImport.create(token, {
              teamId: team.id!,
              externalSystem: `rio/${rioInstanceSelected!}`,
              trust: trustName
                ? { name: trustName, odsCode: trustOdsCode }
                : undefined,
            });

            if (result.status === 204) {
              renderSuccessToast({ message: "Token created successfully" });
              await navigate(routeFns.patientBulkImportTokens());
            } else {
              renderErrorToast({
                message: `Failed to create token: ${(result.data as any).message}`,
              });
            }
          }}
        />
      </Box>
    </Box>
  );
}
