import { Box } from "@mui/material";
import React from "react";
import {
  Banner,
  BannerList,
  Button,
  PopupDialog,
  PopupDialogTitle,
} from "~/components/design-system/index.js";

export function BannerDialog({
  title,
  message,
  onClose,
  bannerType,
}: {
  title: string;
  message: string;
  onClose: () => void;
  bannerType?: BannerList;
}) {
  return (
    <PopupDialog open={true} onClose={onClose} testId="banner-dialog">
      <PopupDialogTitle titleText={title} closeDialog={onClose} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Banner
          title={message}
          bannerType={bannerType || BannerList.CONFIRMATION}
        />
        <Box
          sx={{
            mt: 2,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            label={"Close"}
            onClick={onClose}
            fullWidth={false}
            testId="close-button"
          />
        </Box>
      </Box>
    </PopupDialog>
  );
}
