import { css } from "@emotion/react";
import { Box, Tab, Tabs, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { breakpoints } from "../../../styles/themeCommon.js";

export type SignatureTabsProps = {
  selectedTab: number;
  handleClear: () => void;
  setSelectedTab: (params: any) => void;
  addMarginBottom?: boolean;
  userDefaultSignature?: string;
  hideSavedSignature?: boolean;
  allowUseOfDefaultSignature?: boolean;
};

const defaultProps: Partial<SignatureTabsProps> = {
  addMarginBottom: false,
};

export const SignatureTabs = (props: SignatureTabsProps) => {
  const {
    selectedTab,
    handleClear,
    setSelectedTab,
    addMarginBottom,
    userDefaultSignature,
    hideSavedSignature,
    allowUseOfDefaultSignature,
  } = {
    ...defaultProps,
    ...props,
  };
  const theme = useTheme();
  const { t } = useTranslation();

  const styles = {
    tabs: css`
      font-weight: 600;
      z-index: 1;
      padding: ${theme.spacing(1)};
      font-size: ${theme.spacing(1.625)};
      &[aria-selected="true"] {
        border-bottom: 3px solid ${theme.palette.primary.main};
      }
    `,
  };
  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={(ev, value) => {
          handleClear();
          setSelectedTab(value);
        }}
        sx={{
          ...(addMarginBottom && { mb: 3 }),
          "& .MuiTabs-flexContainer": {
            gap: theme.spacing(4),
            justifyContent: { sm: "center", md: "flex-start" },
          },
          "& .MuiTab-root.Mui-selected": {
            color: theme.palette.text.primary,
            paddingBottom: theme.spacing(0.875),
          },
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTab-root": {
            color: theme.palette.text.primary,
            minHeight: theme.spacing(4.5),
            minWidth: theme.spacing(0),
            padding: theme.spacing(0, 0.5, 1.125, 0.5),
          },
        }}
      >
        {userDefaultSignature &&
          !hideSavedSignature &&
          allowUseOfDefaultSignature && (
            <Tab
              css={styles.tabs}
              label={t("components.signatureDialog.tabLabelSavedSignature")}
              value={0}
            />
          )}
        <Tab
          css={styles.tabs}
          label={t("components.signatureDialog.tabLabelDraw")}
          value={1}
        />
        <Tab
          css={styles.tabs}
          label={t("components.signatureDialog.tabLabelUpload")}
          value={2}
        />
      </Tabs>
      <Box
        css={css`
          @media (min-width: ${breakpoints.values.sm}px) {
            position: relative;
            bottom: ${addMarginBottom
              ? theme.spacing(4.875)
              : theme.spacing(1.855)};
            height: ${theme.spacing(0.375)};
            background-color: ${theme.palette.common.background};
          }
        `}
      />
    </>
  );
};
