import { css } from "@emotion/react";
import { Typography, useTheme } from "@mui/material";
import React from "react";

export interface SubtitleProps {
  text: string;
  isTitleSubheader?: boolean;
  fontSize?: number;
}

const defaultProps: Partial<SubtitleProps> = {
  isTitleSubheader: false,
};

export const Subtitle = (props: SubtitleProps) => {
  const allProps = { ...defaultProps, ...props };
  const { text, isTitleSubheader, fontSize } = allProps;

  const theme = useTheme();

  return (
    <Typography
      css={css`
        text-align: ${isTitleSubheader ? "center" : "left"};
        font-size: ${theme.spacing(fontSize ?? 2)};
        font-weight: 600;
      `}
    >
      {text}
    </Typography>
  );
};
