import { css } from "@emotion/react";
import {
  Box,
  FormControl,
  InputBaseComponentProps,
  TextField as MUITextField,
  TextFieldProps as MuiTextFieldProps,
  InputProps as StandardInputProps,
  useTheme,
} from "@mui/material";
import React from "react";
import { Element } from "react-scroll";
import { breakpoints } from "../../../styles/themeCommon.js";
import { IconType } from "../../types/subText.js";
import { FormLabel } from "../FormLabel.js";
import { HelperText } from "../HelperText/HelperText.js";
import { ReadOnlyContent } from "../ReadOnlyContent/ReadOnlyContent.js";

const defaultProps: Partial<TextFieldProps> = {
  rows: "1",
  isSubControlChecked: false,
  readOnly: false,
  value: undefined,
  isHighlighted: false,
  variant: "outlined",
  autoFocus: false,
};

export interface TextFieldProps {
  name: string;
  label?: string;
  muiTextFieldLabel?: string;
  placeholder?: string;
  subtext?: string;
  subtextIcon?: IconType;
  subControlText?: string;
  isSubControlChecked?: boolean;
  errorMessage?: string;
  warningMessage?: string;
  rows?: string;
  maxRows?: string;
  variant?: MuiTextFieldProps["variant"];
  value?: string;
  multiline?: boolean;
  readOnly?: boolean;
  isHighlighted?: boolean;
  inputRef?: any;
  onChange?: (e: any) => void;
  disabled?: boolean;
  showHelperText?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  InputProps?: Partial<StandardInputProps>;
  inputProps?: InputBaseComponentProps;
  onChangeSubControl?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  useFullWidth?: boolean;
  isSubTextAlignedRight?: boolean;
  noBottomMargin?: boolean;
  autoFocus?: boolean;
}

export const TextField = (props: TextFieldProps) => {
  const allProps: TextFieldProps = { ...defaultProps, ...props };
  const {
    name,
    label,
    muiTextFieldLabel,
    placeholder,
    subtext,
    subtextIcon,
    maxRows,
    subControlText,
    isSubControlChecked,
    errorMessage,
    warningMessage,
    value,
    rows,
    multiline,
    onChange,
    disabled,
    isHighlighted,
    readOnly,
    showHelperText,
    variant,
    onBlur,
    InputProps,
    inputProps,
    inputRef,
    onChangeSubControl,
    useFullWidth,
    isSubTextAlignedRight,
    noBottomMargin,
    autoFocus,
    ...rest
  } = allProps;

  const theme = useTheme();

  const handleOnChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    onChange && onChange(e.target.value);
  };

  const handleOnBlur = (e: any) => {
    const newVal = e.target.value;
    onChange && onChange(newVal?.trim ? newVal.trim() : newVal);
    onBlur && onBlur(e.target.value);
  };

  const renderHelperText = () =>
    showHelperText &&
    (errorMessage || warningMessage || subtext) && (
      <HelperText
        subtext={subtext}
        errorMessage={errorMessage}
        warningMessage={warningMessage}
      />
    );

  if (readOnly) {
    const readOnlyContent: string[] = value !== undefined ? [value].flat() : [];
    return (
      <ReadOnlyContent
        label={label}
        content={readOnlyContent}
        isHighlighted={isHighlighted}
        dense
      />
    );
  }

  const isInputDisabled = () => disabled;

  const styles = {
    textField: css`
      width: 100%;
      /* clears the ‘X’ from Internet Explorer */
      background-color: ${theme.palette.common.paleGray};
      input[type="search"]::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }
      input[type="search"]::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }
      /* clears the ‘X’ from Chrome */
      input[type="search"]::-webkit-search-decoration,
      input[type="search"]::-webkit-search-cancel-button,
      input[type="search"]::-webkit-search-results-button,
      input[type="search"]::-webkit-search-results-decoration {
        display: none;
      }
    `,
  };

  return (
    <Element name={name}>
      <FormControl
        css={css`
          width: ${useFullWidth && "100%"};
          margin-bottom: ${noBottomMargin ? "0" : theme.spacing(2)};
        `}
      >
        {label && (
          <FormLabel
            isAddressField={!!muiTextFieldLabel}
            label={label}
            error={!!errorMessage}
          />
        )}
        <Box
          css={css`
            @media (min-width: ${breakpoints.values.sm}px) {
              flex-wrap: wrap;
              display: flex;
            }
          `}
        >
          <MUITextField
            name={name}
            label={muiTextFieldLabel}
            id={`input-${name}`}
            css={styles.textField}
            InputProps={{
              ...InputProps,
              autoComplete: "off",
              inputProps: {
                "aria-label": `${name}`,
                "data-testid": "text-field",
                ...inputProps,
                ...InputProps?.inputProps,
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={value}
            rows={rows}
            variant={variant}
            maxRows={maxRows}
            error={!!errorMessage}
            placeholder={placeholder}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            multiline={multiline}
            disabled={isInputDisabled()}
            inputRef={inputRef}
            autoFocus={autoFocus}
            {...rest}
          />
        </Box>
        {renderHelperText()}
      </FormControl>
    </Element>
  );
};
