import { CheckCircle, Error, Info, Warning } from "@mui/icons-material";
import { Box, ButtonProps, Typography, useTheme } from "@mui/material";
import React from "react";
import { Button } from "~/components/design-system/index.js";
import { HorizontalLine } from "../../form/HorizontalLine.js";

import DOMPurify from "dompurify";
import { isArray, isString } from "lodash-es";

export enum BannerList {
  CONFIRMATION = "confirmation",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

export type BannerProps<T> = {
  title?: string;
  body?: T;
  leftButtonLabel?: string;
  rightButtonLabel?: string;
  leftButtonTestId?: string;
  rightButtonTestId?: string;
  onLeftButton?: (e: any) => void;
  onRightButton?: (e: any) => void;
  bannerType?: BannerList;
  iconOff?: boolean;
  leftButtonVariant?: ButtonProps["variant"];
  rightButtonVariant?: ButtonProps["variant"];
  dense?: boolean;
  extraMargin?: boolean;
  disabledButtonLeft?: ButtonProps["disabled"];
  disabledButtonRight?: ButtonProps["disabled"];
  info?: string;
  singleLine?: boolean;
  sx?: any;
};

const defaultProps: Partial<BannerProps<any>> = {
  leftButtonLabel: undefined,
  rightButtonLabel: undefined,
  body: undefined,
  onLeftButton: () => {},
  onRightButton: () => {},
  iconOff: false,
  leftButtonVariant: "outlined",
  rightButtonVariant: "outlined",
  dense: false,
  extraMargin: false,
  disabledButtonLeft: false,
  disabledButtonRight: false,
  info: undefined,
  singleLine: undefined,
};

export const Banner = <T,>(props: BannerProps<T>) => {
  const allProps = { ...defaultProps, ...props };
  const {
    title,
    body,
    leftButtonLabel,
    rightButtonLabel,
    leftButtonTestId,
    rightButtonTestId,
    onLeftButton,
    onRightButton,
    bannerType,
    iconOff,
    leftButtonVariant,
    rightButtonVariant,
    dense,
    extraMargin,
    disabledButtonLeft,
    disabledButtonRight,
    info,
    sx,
  } = allProps;

  const theme = useTheme();

  const rootBackgroundColor = () => {
    switch (bannerType) {
      case BannerList.CONFIRMATION:
        return theme.palette.common.paleGreen;
      case BannerList.ERROR:
        return theme.palette.error.light;
      case BannerList.WARNING:
        return theme.palette.background.lightYellow;
      default:
        return theme.palette.secondary.light;
    }
  };

  const sideBorderColor = () => {
    switch (bannerType) {
      case BannerList.CONFIRMATION:
        return theme.palette.success.main;
      case BannerList.ERROR:
        return theme.palette.error.main;
      case BannerList.WARNING:
        return theme.palette.warning.main;
      default:
        return theme.palette.primary.main;
    }
  };

  const iconBackgroundColor = () => {
    switch (bannerType) {
      case BannerList.CONFIRMATION:
        return theme.palette.success.main;
      case BannerList.ERROR:
        return theme.palette.error.main;
      case BannerList.WARNING:
        return theme.palette.warning.main;
      default:
        return theme.palette.secondary.darker;
    }
  };

  let contentToDisplay = [];
  // content is an array of string arrays
  if (isArray(body) && body.length && isArray(body[0])) {
    contentToDisplay = body.flatMap((section) => section);
    // content is an array of strings
  } else if (isArray(body) && body.length && isString(body[0])) {
    contentToDisplay = body;
    // content is string
  } else if (body) {
    contentToDisplay = [body];
  } else {
    contentToDisplay = [title];
  }

  const singleLine = allProps.singleLine ?? contentToDisplay?.length === 1;

  const renderSubtextIcon = (banner: string) => {
    const style = {
      color: iconBackgroundColor(),
      fontSize: "48px",
    };

    if (iconOff) {
      return null;
    } else {
      switch (banner) {
        case BannerList.INFO:
          return <Info sx={style} />;
        case BannerList.ERROR:
          return <Error sx={style} />;
        case BannerList.CONFIRMATION:
          return <CheckCircle sx={style} />;
        case BannerList.WARNING:
          return <Warning sx={style} />;
        default:
          return banner;
      }
    }
  };

  const renderButtons = () => {
    return (
      <Box
        sx={{
          gap: theme.spacing(2),
          display: "flex",
          justifyContent: "end",
          flexWrap: "wrap",
        }}
      >
        {leftButtonLabel && (
          <Button
            sx={{
              backgroundColor: theme.palette.common.white,
            }}
            testId={leftButtonTestId}
            label={leftButtonLabel}
            onClick={onLeftButton!}
            variant={leftButtonVariant}
            disabled={disabledButtonLeft}
          />
        )}
        {rightButtonLabel && (
          <Button
            sx={{
              backgroundColor: theme.palette.common.white,
            }}
            testId={rightButtonTestId}
            label={rightButtonLabel}
            onClick={onRightButton!}
            variant={rightButtonVariant}
            disabled={disabledButtonRight}
          />
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        ...sx,
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        flexDirection: "column",
        borderLeft: `6px solid ${sideBorderColor()}`,
        borderRadius: "6px",
        backgroundColor: rootBackgroundColor(),
        padding: theme.spacing(2),
        rowGap: dense ? 0 : theme.spacing(2),
      }}
      data-testid="root-id"
    >
      {title && body && (
        <Typography sx={{ color: "black", fontWeight: 600 }}>
          {title}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: singleLine && !info ? "row" : "column",
          justifyContent: "space-between",
          gap: dense ? 0 : theme.spacing(2),
          marginTop: extraMargin ? theme.spacing(2) : "",
          marginBottom: extraMargin ? theme.spacing(1) : "",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: singleLine ? "center" : "start",
            gap: theme.spacing(2),
          }}
        >
          {renderSubtextIcon(bannerType!)}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              color: theme.palette.text.secondary,
              gap: theme.spacing(0.5),
            }}
          >
            {(body || title) &&
              contentToDisplay.flatMap((paragraph: string) => (
                <Typography
                  key={paragraph}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(paragraph),
                  }}
                />
              ))}
          </Box>
          {(leftButtonLabel || rightButtonLabel) &&
            !!singleLine &&
            renderButtons()}
        </Box>
        {info && (
          <>
            <HorizontalLine noMargin />
            <Typography color={theme.palette.text.secondary}>{info}</Typography>
          </>
        )}
        {(leftButtonLabel || rightButtonLabel) &&
          !singleLine &&
          renderButtons()}
      </Box>
    </Box>
  );
};
