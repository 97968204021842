import { Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  InputAdornment,
  ListItem,
  Paper,
  Popper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenDetection } from "../../../hooks/ScreenDetection/useScreenDetection.js";
import { Button } from "../Button/Button.js";
import { DateField } from "../DateField/DateField.js";
import { TextField } from "../TextField/TextField.js";

export interface SearchBarWithFilterProps {
  filters: string[];
  optionValues: {
    tabValue: number;
    options: string[];
  }[];
  hideFilter?: boolean;
  searchQuery: string;
  setSearchQuery: (params: any) => void;
  selectedOptions: string[];
  setSelectedOptions: (params: any) => void;
  showDateRange?: boolean;
  fromDate?: Dayjs | null;
  setFromDate?: (params: any) => void;
  toDate?: Dayjs | null;
  setToDate?: (params: any) => void;
  selectedTab: number;
  setSelectedTab: (params: number) => void;
  placeholder?: string;
}
export const SearchBarWithFilter = (props: SearchBarWithFilterProps) => {
  const {
    hideFilter,
    filters,
    optionValues,
    searchQuery,
    setSearchQuery,
    selectedOptions,
    setSelectedOptions,
    showDateRange,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    selectedTab,
    setSelectedTab,
    placeholder,
  } = props;

  const { t } = useTranslation();

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filteredSearchQuery, setFilteredSearchQuery] = useState("");
  const { isMobileView } = useScreenDetection();

  const open = Boolean(anchorEl);
  const id = open ? "search-popper" : undefined;

  const handleClick = (event: any) => {
    setAnchorEl(
      anchorEl
        ? null
        : (event.currentTarget as React.SetStateAction<HTMLElement | null>),
    );
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSearch = (filtered: string) => {
    setSearchQuery(filtered);
  };

  const handleEndDateBlur = (event: any) => {
    const endDate = event;
    if (fromDate && dayjs(endDate) < dayjs(fromDate)) {
      alert("End date cannot be before start date!");
      setToDate && setToDate(dayjs);
    } else {
      setToDate && setToDate(endDate);
    }
  };

  const filterSearchHeight = theme.spacing(5.25);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            ...(isMobileView ? {} : { marginRight: theme.spacing(2) }),
          }}
        >
          <TextField
            name="Search"
            placeholder={placeholder ?? "Start typing patient name..."}
            InputProps={{
              startAdornment: (
                <SearchIcon sx={{ mr: 1, color: theme.palette.primary.hint }} />
              ),
              style: {
                height: filterSearchHeight,
              },
            }}
            useFullWidth
            value={searchQuery || ""}
            onChange={handleSearch}
          />
        </Box>
        {!hideFilter && (
          <Box
            sx={{
              display: "flex",
              marginBottom: theme.spacing(2),
              [theme.breakpoints.down("sm")]: {
                marginLeft: theme.spacing(2),
              },
            }}
          >
            <Button
              sx={{ height: filterSearchHeight }}
              label={t("buttonLabels.filter")}
              onClick={handleClick}
              variant={"contained"}
            />
          </Box>
        )}
        <Popper id={id} open={open} anchorEl={anchorEl}>
          <ClickAwayListener onClickAway={handleClick}>
            <Paper sx={{ width: 450 }} key={id} elevation={4}>
              <Box sx={{ padding: theme.spacing(2.5, 0, 0, 3.5) }}>
                <Tabs
                  sx={{
                    mb: 3,
                    minHeight: 0,
                    "& .MuiTabs-flexContainer": {
                      gap: theme.spacing(4),
                      justifyContent: { sm: "center", md: "flex-start" },
                    },
                    "& .MuiTab-root.Mui-selected": {
                      color: theme.palette.text.primary,
                      paddingBottom: theme.spacing(1.2),
                    },
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                    "& .MuiTab-root": {
                      color: theme.palette.text.primary,
                      minHeight: theme.spacing(1),
                      minWidth: theme.spacing(0),
                      padding: theme.spacing(0, 0.5, 1.3, 0.5),
                    },
                  }}
                  value={selectedTab}
                  onChange={handleTabChange}
                >
                  {filters.map((f: string) => (
                    <Tab
                      sx={{
                        fontWeight: 600,
                        zIndex: 1,
                        padding: theme.spacing(1),
                        fontSize: theme.spacing(1.625),
                        '&[aria-selected="true"]': {
                          borderBottom: `3px solid ${theme.palette.primary.main}`,
                        },
                      }}
                      key={f}
                      label={f}
                    />
                  ))}
                  <Box
                    sx={{
                      [theme.breakpoints.up("sm")]: {
                        position: "relative",
                        bottom: theme.spacing(3.375),
                        height: theme.spacing(0.375),
                        backgroundColor: theme.palette.common.background,
                      },
                    }}
                  />
                </Tabs>
              </Box>
              {selectedTab !== 2 && (
                <Box sx={{ mx: 2 }}>
                  <TextField
                    name="search"
                    useFullWidth
                    value={filteredSearchQuery}
                    onChange={(val) => setFilteredSearchQuery(val)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon
                            sx={{ mr: 1 }}
                            style={{ color: theme.palette.primary.hint }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              )}
              {optionValues.map(
                (v: { tabValue: number; options: string[] }) => (
                  <div key={v.tabValue}>
                    {v.tabValue === selectedTab && (
                      <>
                        {v.options &&
                          v.options
                            .filter((option) =>
                              option
                                ?.toLowerCase()
                                .includes(filteredSearchQuery?.toLowerCase()),
                            )
                            .map((option: string, i) => (
                              <ListItem key={option + i} value={option}>
                                <Checkbox
                                  sx={{ color: "primary.main", mr: 1 }}
                                  onChange={() => {
                                    const selectedValues =
                                      selectedOptions.includes(option)
                                        ? selectedOptions.filter(
                                            (x) => x !== option,
                                          )
                                        : [...selectedOptions, option];

                                    setSelectedOptions(selectedValues);
                                  }}
                                  checked={selectedOptions?.includes(option)}
                                />
                                <Typography>{option}</Typography>
                              </ListItem>
                            ))}
                      </>
                    )}
                  </div>
                ),
              )}
              {showDateRange && selectedTab === 2 && (
                <Box sx={{ px: 2, pb: 1 }}>
                  <DateField
                    name="from"
                    label="From"
                    value={fromDate || null}
                    maxDate={dayjs()}
                    onChange={(e) => setFromDate && setFromDate(e)}
                  ></DateField>

                  <DateField
                    name="to"
                    label="To"
                    value={toDate || null}
                    maxDate={dayjs()}
                    onChange={handleEndDateBlur}
                  ></DateField>
                </Box>
              )}
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </>
  );
};
