import { isAdminUser, isGuestUser } from "@aspire/common";
import { css } from "@emotion/react";
import { Box, useTheme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { LoggedInUserContext } from "../../../Contexts.js";
import { useScreenDetection } from "../../../hooks/ScreenDetection/useScreenDetection.js";
import AlertBar from "../AlertBar.js";
import DrawerDesktopMenu from "../menu/Menu.js";
import MobileMenu from "../menu/mobile/Menu.js";

export interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const userContext = React.useContext(LoggedInUserContext);
  const user = userContext?.user!;
  const { isMobileView } = useScreenDetection();
  const theme = useTheme();

  const menuComponent = React.useMemo(() => {
    return isMobileView ? (
      <MobileMenu user={user} direction={"bottom"} />
    ) : (
      <DrawerDesktopMenu user={user} />
    );
  }, [isMobileView, user]);

  return (
    <>
      <Box
        css={css`
          display: flex;
          width: 100%;
          ${isMobileView && "flex-direction: column;"}
        `}
      >
        {isGuestUser(user) && (
          <AlertBar
            backgroundColor={theme.palette.background.lightYellow}
            color={theme.palette.common.black}
          >
            You're using Thalamos as a Guest. To access all features, please{" "}
            <a
              href={"https://calendly.com/d/yph-vzp-y7g"}
              target={"_blank"}
              rel="noreferrer"
            >
              book a demo
            </a>{" "}
            with our team
          </AlertBar>
        )}
        {user &&
          user.sessionContext &&
          !user.address.address &&
          !isGuestUser(user) &&
          !isAdminUser(user) && (
            <AlertBar
              backgroundColor={theme.palette.error.main}
              color={theme.palette.common.white}
            >
              Your profile is not updated. This may prevent you from completing
              forms.{" "}
              <Link
                to={`/profile/${user.id}`}
                style={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                Go to profile here.
              </Link>
            </AlertBar>
          )}
        {menuComponent}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            px: { xs: 2, sm: 3, md: 4, lg: 5 },
            ...(isMobileView ? { pt: 2 } : null),
            minHeight: "100vh",
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};
