import { css } from "@emotion/react";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { Subtitle } from "../Subtitle.js";

export interface SectionTitleProps {
  titleText: string;
  subtitleText?: string;
  alignCenter?: boolean;
}

const defaultProps: Partial<SectionTitleProps> = {
  subtitleText: undefined,
  alignCenter: false,
};

export const SectionTitle = (props: SectionTitleProps) => {
  const allProps: SectionTitleProps = { ...defaultProps, ...props };

  const { titleText, subtitleText, alignCenter } = allProps;

  const theme = useTheme();

  const styles = {
    title: css`
      font-size: ${theme.spacing(4)};
      color: ${theme.palette.common.black};
      font-weight: ${theme.spacing(37.5)};
      text-align: ${alignCenter ? "center" : ""};
      margin-bottom: ${subtitleText ? theme.spacing(2) : theme.spacing(4)};
    `,
  };
  return (
    <Box>
      <Typography css={styles.title}>{titleText}</Typography>
      {subtitleText && (
        <Subtitle text={subtitleText} isTitleSubheader={alignCenter} />
      )}
    </Box>
  );
};
