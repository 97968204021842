import {
  CreateTeamRequest,
  ExtendedTeam,
  ExtendedThalamosUser,
  isAdminUser,
  isGuestUser,
  nonEmptyRequiredString,
  TeamMembership,
} from "@aspire/common";
import { css } from "@emotion/react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Switch,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Formik } from "formik";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { boolean, object, string } from "yup";
import {
  Button,
  Dropdown,
  FormLabel,
  HelperText,
  LoadingSpinner,
  MenuFlyout,
  MenuOptionsType,
  renderErrorToast,
  renderSuccessToast,
  TextField,
  Typeahead,
} from "~/components/design-system/index.js";
import { getRoleLabel } from "~/util.js";
import { api, apiHooks } from "../../api.js";
import { LoggedInUserContext } from "../../Contexts.js";
import { routeFns } from "../../routes.js";
import { ConfirmationModal } from "../ConfirmationModal.js";
import { addMemberValues } from "./constants.js";
import { OrganisationPicker } from "./OrganisationPicker.js";

function DeleteTeamButton({ team }: { team: ExtendedTeam }) {
  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);

  const navigate = useNavigate();

  return (
    <>
      {confirmFn && (
        <ConfirmationModal
          message={confirmFn.message}
          confirmFn={confirmFn.confirmFn}
          closeFn={() => setConfirmFn(null)}
        />
      )}

      <Button
        onClick={() => {
          setConfirmFn({
            message: `Are you sure you want to delete this team (${team.name})? This cannot be undone. The team cannot have any members or any pending/accepted/completed work`,
            confirmFn: async () => {
              const result = await api.teams.delete(team.id, {
                reason: "created-in-error",
              });

              if (result.status === 204) {
                navigate(routeFns.adminTeamsPage());
                renderSuccessToast({
                  message: "Team deleted successfully",
                });
              } else {
                renderErrorToast({
                  message: `${result.data.reason}`,
                });
              }
            },
          });
        }}
        sx={{ ml: "1em" }}
        color={"error"}
        label={"Delete"}
      />
    </>
  );
}

const teamDetailsSchema = object({
  name: nonEmptyRequiredString.default(""),
  type: nonEmptyRequiredString
    .oneOf(["mha", "ward", "amhp", "generic"])
    .default("mha"),
  organisationId: nonEmptyRequiredString.default(""),
  organisationName: string().default(""),
  primaryEmail: nonEmptyRequiredString.email().default(""),
  hasPatientMergeAccess: boolean().default(false),
});

function TeamBasicDetailsCreateOrEdit({
  initialData,
  team,
  onSubmit,
  isPatientMergeAccess,
}: {
  initialData: Partial<CreateTeamRequest>;
  team: ExtendedTeam | null;
  onSubmit: (data: CreateTeamRequest) => void;
  isPatientMergeAccess: boolean | undefined;
}) {
  return (
    <Formik<CreateTeamRequest & { organisationName: string | null }>
      initialValues={{
        ...teamDetailsSchema.getDefault(),
        ...initialData,
        organisationName: team?.organisationName || "",
        hasPatientMergeAccess: team?.hasPatientMergeAccess || false,
      }}
      onSubmit={(data) => {
        return onSubmit({
          ...data,
          organisationName: undefined,
        } as CreateTeamRequest);
      }}
      validationSchema={teamDetailsSchema}
    >
      {({ values, setValues, isSubmitting, touched, errors, submitForm }) => (
        <Box sx={{ maxWidth: "600px", marginBottom: "2em" }}>
          <Box>
            <FormLabel label={"Name"} error={touched.name && !!errors.name} />

            <TextField
              name={"name"}
              useFullWidth={true}
              value={values.name}
              onChange={(e) => setValues({ ...values, name: e })}
            />
            {errors.name && touched.name && (
              <Box sx={{ mb: 4 }}>
                <HelperText errorMessage={errors.name as string} />
              </Box>
            )}
          </Box>

          <Box>
            <FormLabel
              label={"Email"}
              error={touched.primaryEmail && !!errors.primaryEmail}
            />
            <TextField
              name={"email"}
              useFullWidth={true}
              value={values.primaryEmail}
              onChange={(e) => setValues({ ...values, primaryEmail: e })}
            />
            {errors.primaryEmail && touched.primaryEmail && (
              <Box sx={{ mb: 4 }}>
                <HelperText errorMessage={errors.primaryEmail as string} />
              </Box>
            )}
          </Box>

          <Box>
            <FormLabel label={"Type"} error={touched.type && !!errors.type} />
            <Dropdown
              name={"type"}
              selectedValue={values.type}
              values={[
                { value: "mha", label: "mha" },
                { value: "ward", label: "ward" },
                { value: "amhp", label: "amhp" },
                { value: "generic", label: "generic" },
              ]}
              onChange={(e) => setValues({ ...values, type: e })}
            />
            {errors.type && touched.type && (
              <Box sx={{ mb: 4 }}>
                <HelperText errorMessage={errors.type as string} />
              </Box>
            )}
          </Box>
          <OrganisationPicker
            label={"Managing Organisation"}
            error={
              touched.organisationId ? (errors.organisationId as string) : null
            }
            idField={"organisationId"}
            nameField={"organisationName"}
            values={values}
            setValues={setValues}
          />
          {isPatientMergeAccess && (
            <Box sx={{ mb: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.hasPatientMergeAccess}
                    onChange={() => {
                      setValues({
                        ...values,
                        hasPatientMergeAccess: !values.hasPatientMergeAccess,
                      });
                    }}
                  />
                }
                label={"Patient Merge Enabled"}
              />
            </Box>
          )}
          <Box>
            <>
              <Button
                disabled={isSubmitting}
                label={team ? "Update Team" : "Create Team"}
                onClick={() => {
                  return submitForm();
                }}
              />
              {team && <DeleteTeamButton team={team} />}
            </>
          </Box>
        </Box>
      )}
    </Formik>
  );
}

function TeamMemberRow({
  user,
  team,
  member,
  refetchTeam,
}: {
  user: ExtendedThalamosUser | undefined;
  refetchTeam: () => void;
  team: ExtendedTeam;
  member: ExtendedTeam["members"][0];
}) {
  const cardRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);
  const isEditableTeam = team.type !== "independent" && team.type !== "guest";

  const [isLoading, setIsLoading] = useState(false);

  const options: MenuOptionsType[] = [
    {
      name: "Remove from Team",
      onClick: () => {
        setConfirmFn({
          confirmFn: async () => {
            await api.teams.removeMember(team.id, {
              userId: member.userId,
              role: member.role,
            });

            refetchTeam();
          },
          message: `Are you sure you want to remove ${member.userName} from ${team.name}?`,
        });
      },
      icon: "delete",
      disabled: false,
    },
  ];

  return (
    <TableRow>
      {confirmFn && (
        <ConfirmationModal
          message={confirmFn.message}
          confirmFn={confirmFn.confirmFn}
          closeFn={() => setConfirmFn(null)}
        />
      )}
      <TableCell>{member.userName}</TableCell>
      <TableCell>{member.userEmail}</TableCell>
      <TableCell>{getRoleLabel(member.role)}</TableCell>
      <TableCell>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Checkbox
            checked={member.hasPatientMergeAccess}
            disabled={!team.hasPatientMergeAccess}
            onChange={async () => {
              setIsLoading(true); // Enable loading state
              try {
                await api.users.update(member.userId, {
                  hasPatientMergeAccess: !member.hasPatientMergeAccess,
                });
                refetchTeam();
              } catch (error) {
                console.error("Error updating user:", error);
              } finally {
                setIsLoading(false);
              }
            }}
          />
        )}
      </TableCell>

      {isAdminUser(user) && isEditableTeam && (
        <TableCell>
          <Box ref={cardRef} onClick={() => setIsMenuOpen(true)}>
            <MenuFlyout
              cardRef={cardRef}
              options={options}
              isOpen={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
            />
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
}

function TeamCreateEditPageInner({
  teamId,
  team,
  refetchTeam,
}: {
  teamId: string;
  team: ExtendedTeam | null;
  refetchTeam: () => void;
}) {
  const userContext = useContext(LoggedInUserContext);
  const { t } = useTranslation();

  const [searchResults, setSearchResults] = React.useState<
    ExtendedThalamosUser[]
  >([]);

  const [userValue, setUserValue] = React.useState<
    string | ExtendedThalamosUser
  >("");

  const isEditableTeam = team?.type !== "independent" && team?.type !== "guest";

  const [{ data: allOrgsAndTeams, loading: allOrgsAndTeamsLoading }] =
    apiHooks.reports.availableOrgsAndTeams(teamId);

  const isPatientMergeAccess =
    allOrgsAndTeams?.teamOrgData?.hasPatientMergeAccess;

  const [selectedRole, setSelectedRole] = useState("");

  const handleRoleChange = async (role: TeamMembership["role"]) => {
    if (typeof userValue !== "string" && role) {
      await api.teams.addMember(team!.id, {
        userId: userValue.id,
        role,
      });
      refetchTeam();
    }
  };

  return !allOrgsAndTeamsLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {team ? (
        <h2>
          {team.name} ({team.type})
        </h2>
      ) : (
        <h2>Create New Team</h2>
      )}
      {isEditableTeam ? (
        <TeamBasicDetailsCreateOrEdit
          isPatientMergeAccess={isPatientMergeAccess}
          team={team}
          initialData={
            team
              ? ({
                  name: team.name,
                  type: team.type,
                  organisationId: team.organisationId,
                  primaryEmail: team.primaryEmail,
                } as CreateTeamRequest)
              : {}
          }
          onSubmit={async (data) => {
            if (team) {
              await api.teams.update(team.id, data);
            } else {
              await api.teams.create(teamId, data);
            }
            refetchTeam();
          }}
        />
      ) : (
        <>
          <p>This team cannot be edited</p>
        </>
      )}

      {team && <h3>{t("common.members")}</h3>}

      {team && isAdminUser(userContext?.user) && isEditableTeam && (
        <Box sx={{ display: "flex", width: "100%" }}>
          <Typeahead
            css={css`
              width: 500px;
            `}
            label={"Search for a user to add"}
            options={searchResults}
            name="recipientEmail"
            enableFuse={false}
            inputValue={
              typeof userValue === "string" ? userValue : userValue?.email
            }
            optionsKey="name"
            getOptionLabel={(option) =>
              typeof option === "string"
                ? option
                : `${option.name} (${option.email})`
            }
            onInputChange={async (value: string) => {
              const match = searchResults.find(
                (u) => value === `${u.name} (${u.email})`,
              );
              if (match) {
                setUserValue(match);
              } else {
                setUserValue(value);
              }
              if (value.length > 1) {
                const results = await api.users.search(value, 10, 0);

                const filtered = results.data?.results.filter(
                  (u) => !isGuestUser(u),
                );

                setSearchResults(filtered);

                if (match) {
                  setUserValue(match);
                }
              } else {
                setSearchResults([]);
              }
            }}
          />
          <Box sx={{ marginLeft: "1em", width: "20%" }}>
            <Dropdown
              name="add-member-dropdown"
              values={addMemberValues}
              selectedValue={selectedRole}
              onChange={(e) => {
                setSelectedRole(e);
                handleRoleChange(e);
              }}
              placeholder="Select role"
              disabled={typeof userValue === "string"}
            />
          </Box>
        </Box>
      )}

      {team && (
        <TableContainer sx={{ width: "100%" }}>
          <TableRow>
            <TableCell>
              <strong>{t("common.name")}</strong>
            </TableCell>
            <TableCell>
              <strong>{t("common.email")}</strong>
            </TableCell>
            <TableCell>
              <strong>{t("common.role")}</strong>
            </TableCell>
            <TableCell>
              <strong>{"Patient Merge"}</strong>
            </TableCell>
          </TableRow>
          {team &&
            (team?.members || []).map((member) => {
              return (
                <TeamMemberRow
                  refetchTeam={refetchTeam}
                  team={team}
                  member={member}
                  user={userContext?.user}
                />
              );
            })}
        </TableContainer>
      )}
    </Box>
  ) : (
    <></>
  );
}

export function TeamCreateEditPage({}: {}) {
  let { teamId } = useParams();

  const [{ data: team, loading: teamLoading, response }, refetchTeam] =
    apiHooks.teams.get(teamId!);

  return teamLoading ? (
    <></>
  ) : (
    <TeamCreateEditPageInner
      teamId={teamId!}
      team={response?.status === 200 ? team! : null}
      refetchTeam={refetchTeam}
    />
  );
}
