import {
  ExtendedThalamosUser,
  isAdminUser,
  isGuestUser,
  notificationChannelFns,
  NotificationEvents,
} from "@aspire/common";
import { css, Global } from "@emotion/react";
import { Close as CloseIcon } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import Cookies from "js-cookie";
import React, { KeyboardEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  Banner,
  BannerList,
  Button,
  LoadingSpinner,
  PopupDialog,
  ToastNotifications,
} from "~/components/design-system/index.js";
import { Layout } from "~/components/layout/index.js";
import {
  LoggedInUserContext,
  ManagedLocationsContext,
  NotificationsContext,
} from "./Contexts.js";
import { api, apiHooks, logout } from "./api.js";
import { config } from "./config.js";
import { useChannel } from "./lib/pusher/useChannel.js";
import { useEvent } from "./lib/pusher/useEvent.js";
import {
  ContextSelectorPage,
  ContextSelectorPageProps,
} from "./pages/ContextSelector/ContextSelector.js";
import { GuestUserLoginPage } from "./pages/GuestUserLoginPage.js";
import { routeFns, routes } from "./routes.js";
import * as tracing from "./tracing.js";

import {
  extendedMembershipToMembership,
  Membership,
  OrganisationMembership,
} from "@aspire/common";
import { DialogsProvider } from "@toolpad/core/useDialogs";
import { TermsAndConditionsAccept } from "./components/layout/TermsAndConditions/TermsAndConditionsAccept.js";
import { LoginPage } from "./pages/LoginPage.js";
import NotFound from "./pages/NotFound.js";
import ErrorBoundary from "./pages/helpers/ErrorBoundary/ErrorBoundary.js";

function TrainingBanner(props: {}) {
  const userContext = React.useContext(LoggedInUserContext);
  const { t } = useTranslation();

  const [showTrainingBanner, setShowTrainingBanner] = useState(
    userContext?.user?.sessionContext?.isTrainingOrganisation || false,
  );

  return (
    <PopupDialog
      open={showTrainingBanner}
      onClose={() => setShowTrainingBanner(false)}
    >
      {" "}
      <Box display="flex" flexDirection="column" sx={{ padding: "1em" }}>
        <IconButton
          aria-label={"Close"}
          onClick={() => setShowTrainingBanner(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            marginLeft: "2em",
            marginRight: "2em",
          }}
        >
          <Banner
            bannerType={BannerList.WARNING}
            title={t("training.dialog")}
          />
        </Box>
        <Box
          sx={{
            paddingLeft: "2em",
            paddingRight: "2em",
            marginTop: "2em",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            label={t("buttonLabels.continue")}
            onClick={() => setShowTrainingBanner(false)}
            color={"primary"}
          />
        </Box>
      </Box>
    </PopupDialog>
  );
}

function AuthedApp({}: {}) {
  const userContext = React.useContext(LoggedInUserContext);
  const user = userContext?.user!;
  const navigate = useNavigate();

  // TODO: Remove this when we do the onboarding flow along with cookie-js
  useEffect(() => {
    if (!isGuestUser(user) && !isAdminUser(user)) {
      // We only want to redirect the user once to the profile page if the login count is 1
      if (user && user.loginCount === 1) {
        const redirectionFlag = Cookies.get(`${user.id}-profile-redirect`);

        if (!redirectionFlag) {
          navigate(`/profile/${user.id}`);
          Cookies.set(`${user.id}-profile-redirect`, "true", { expires: 1 }); // expires in 1 day
        }
      }
    }
  }, []);

  return (
    <>
      <TrainingBanner />
      <TermsAndConditionsAccept />

      <Box sx={{ mt: 3 }}>
        <Routes>
          {routes.map((r) => (
            <Route
              key={r.breadcrumb}
              path={r.path}
              element={<r.component />}
            ></Route>
          ))}
          <Route path={"*"} element={<NotFound />}></Route>
        </Routes>
      </Box>
    </>
  );
}

function WithNotifications({ children }: { children: React.ReactElement }) {
  const userContext = React.useContext(LoggedInUserContext);

  const contextId =
    userContext?.user?.sessionContext?.type === "team"
      ? userContext?.user?.sessionContext?.teamId
      : userContext?.user?.sessionContext?.organisationId;

  const [notificationsPage, setNotificationsPage] = useState(0);

  const notificationsPageSize = 20;

  const [{ data: notifications }, refetchNotifications] =
    apiHooks.notifications.get(contextId, {
      limit: notificationsPageSize,
      offset: notificationsPage * notificationsPageSize,
    });

  useEffect(() => {
    refetchNotifications();
  }, [contextId, notificationsPage]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications: notifications!,
        setNotificationsPage,
        notificationsPage,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

function WithDialogs({ children }: { children: React.ReactElement }) {
  return <DialogsProvider>{children}</DialogsProvider>;
}

function WithAdminManagedLocations({
  children,
}: {
  children: React.ReactElement;
}) {
  const [{ data: locations }, refetchLocations] =
    apiHooks.configuration.getAdminManagedLocations();

  return (
    <ManagedLocationsContext.Provider
      value={{
        managedLocations: locations || [],
        refetchManagedLocations: refetchLocations || (() => {}),
      }}
    >
      {children}
    </ManagedLocationsContext.Provider>
  );
}

function LoggedOutHomePage({}: {}) {
  return (
    <Box height="100%" display="grid" justifyItems="center" alignItems="center">
      <LoadingSpinner label="Loading..." />
    </Box>
  );
}

// Toggles the display of testid labels
function TestIdDebugger() {
  let [debugLabelsVisible, setDebugLabelsVisible] = useState(false);

  useEffect(() => {
    const handleKeyPress = function (event: KeyboardEvent) {
      if (
        event.code == "KeyT" &&
        event.altKey &&
        !event.ctrlKey &&
        !event.metaKey &&
        !event.shiftKey
      ) {
        setDebugLabelsVisible(!debugLabelsVisible);
      }
    } as any;

    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [debugLabelsVisible]);

  if (debugLabelsVisible) {
    return (
      <Global
        styles={css`
          [data-testid] {
            position: relative;
          }

          [data-testid]::before {
            display: block;
            content: attr(data-testid);
            position: absolute;
            left: 0;
            top: 0;
            clear: both;
            width: max-content;
            padding: 3px 6px;
            background-color: hotpink;
            color: white;
            font-size: 10px;
            line-height: 1;
            text-transform: none;
            letter-spacing: normal;
          }

          [data-testid] > [data-testid]::before {
            left: auto;
            right: 0;
          }

          [data-testid] > :not([data-testid]) > [data-testid]::before {
            left: auto;
            right: 0;
          }
        `}
      />
    );
  }

  return null;
}

let loginCheckInteral: NodeJS.Timeout | string | number | undefined = undefined;

function WithLoggedInUser({ children }: { children: React.ReactElement }) {
  const navigate = useNavigate();
  const location = useLocation();

  // The URL path without the query string. Redirecting to this URL effectively removes
  // all query string paramters
  const pathname = window.location.pathname;
  const qs = new URLSearchParams(location.search);

  const guestUserIdFromQueryParams = qs.get("guest-user-id");
  const contextIdFromParams = qs.get("context-id");

  // Fetch user data
  const [{ data: user, response: userMeResponse }, refetchUser] =
    apiHooks.users.me(contextIdFromParams || guestUserIdFromQueryParams);

  // Refetch user data if pusher channel is triggers
  const userChannel = useChannel(notificationChannelFns.user.profile(user?.id));
  useEvent(userChannel, NotificationEvents.resourceReload, (event) => {
    refetchUser();
  });

  // Determine whether user is logged in
  const amILoggedIn = userMeResponse?.status === 200 && user;

  // We ping /api/health/authed-ping every 5 seconds to check that the session is still active.
  // Any API request _other_ than /api/health/authed-ping will refresh the session, so this is
  // essentially our way of doing session inactivity timeouts
  const CHECK_LOGIN_STATUS_INTERVAL_MS = 1000 * 10;
  useEffect(() => {
    if (loginCheckInteral) {
      clearInterval(loginCheckInteral);
    }
    if (amILoggedIn) {
      loginCheckInteral = setInterval(
        api.users.loggedInCheck,
        CHECK_LOGIN_STATUS_INTERVAL_MS,
      );
    }
  }, [user, amILoggedIn]);

  // Set user for Datadog tracing
  useEffect(() => {
    tracing.setUser(user || null);
  }, [user]);

  const hasReturnToParam = qs.has("returnTo");
  const isContextSelectorPage = location.pathname.startsWith(
    routeFns.contextSelector(),
  );

  // Set the user's team context (or else redirect to the context selector)
  useEffect(() => {
    if (amILoggedIn) {
      const membershipFromQueryParam = user.memberships.find((m: Membership) =>
        m.role === "member" && m.type === "team"
          ? m.teamId === contextIdFromParams
          : (m as OrganisationMembership).organisationId ===
            contextIdFromParams,
      );

      // Remove the context-id param so we never get stuck in a loop
      qs.delete("context-id");

      const newPathname = qs.size ? `${pathname}?${qs.toString()}` : pathname;

      if (membershipFromQueryParam) {
        api.users
          .setSessionContext(
            extendedMembershipToMembership(membershipFromQueryParam),
          )
          .then((result) => {
            refetchUser();
            navigate(newPathname, { replace: true });
          });
      } else if (!user.sessionContext) {
        if (user.memberships.length === 1) {
          api.users
            .setSessionContext(
              extendedMembershipToMembership(user.memberships[0]),
            )
            .then((result) => {
              refetchUser();
              navigate(newPathname, { replace: true });
            });
          // We should only add the returnTo param if it hasn't already been set
        } else if (!isContextSelectorPage && !hasReturnToParam) {
          navigate(routeFns.contextSelector(newPathname));
        }
      }
    }
  }, [user]);

  // Render team context selector
  if (amILoggedIn && isContextSelectorPage) {
    function transformMemberships(
      memberships: ExtendedThalamosUser["memberships"],
    ) {
      return memberships.map((membership) => ({
        type: membership.type,
        ...(membership.type === "team"
          ? { teamId: membership.teamId }
          : { organisationId: membership.organisationId }),
        role: membership.role,
        name:
          membership.type === "team"
            ? membership.teamName
            : membership.organisationName,
      }));
    }

    return (
      <ContextSelectorPage
        userId={user.id}
        userName={user.name}
        defaultSelectedContext={user.defaultSelectedContext}
        memberships={
          transformMemberships(
            user.memberships,
          ) as ContextSelectorPageProps["memberships"]
        }
        sessionContext={!!user.sessionContext}
        renderLogoutFn={logout}
        reloadUser={refetchUser}
      />
    );
  }

  // Render loading spinner
  if (!amILoggedIn || !user?.sessionContext) {
    return (
      <Layout>
        <LoggedOutHomePage />
      </Layout>
    );
  }

  // Render main app
  return (
    <LoggedInUserContext.Provider value={{ user, refetchUser }}>
      {children}
    </LoggedInUserContext.Provider>
  );
}
export function App() {
  const location = useLocation();

  if (location.pathname.startsWith(routeFns.guestUserLogin()))
    return <GuestUserLoginPage />;
  else if (location.pathname.startsWith(routeFns.loginPage("")))
    return <LoginPage />;
  else
    return (
      <WithLoggedInUser>
        <WithNotifications>
          <WithDialogs>
            <WithAdminManagedLocations>
              <Layout>
                {config.enableTestIdVizualizer && <TestIdDebugger />}
                <ToastNotifications />
                <ErrorBoundary location={location}>
                  <AuthedApp />
                </ErrorBoundary>
              </Layout>
            </WithAdminManagedLocations>
          </WithDialogs>
        </WithNotifications>
      </WithLoggedInUser>
    );
}
