import { ThalamosUser } from "@aspire/common";
import { datadogRum } from "@datadog/browser-rum";
import { config } from "./config.js";

export function initialiseTracing() {
  if (!config.datadog.disableTracing) {
    datadogRum.init({
      applicationId: config.datadog.applicationId,
      clientToken: config.datadog.clientToken,
      site: "datadoghq.eu",
      service: "aspire",
      env: config.environment ?? "unknown",
      version: config.version ?? "unknown",
      trackUserInteractions: false,
      allowedTracingUrls: [config.backendUrl],

      // This defaults to false - this gives us information about
      // requested resources from the server
      trackResources: true,

      // This defaults to false - this gives us information about long
      // running browser tasks
      trackLongTasks: true,

      // This is the default - but we are setting it explicitly to be sure
      // the value is preserved on uprgade
      defaultPrivacyLevel: "mask",
    });
  }
}

export function setUser(user: ThalamosUser | null) {
  if (!config.datadog.disableTracing) {
    if (user) {
      datadogRum.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
      });
    } else {
      datadogRum.clearUser();
    }
  }
}

export function logActionToDatadog(name: string, context?: object) {
  if (!config.datadog.disableTracing) {
    datadogRum.addAction(name, context);
  } else {
    // For local dev, just log to the console
    console.log(name, context);
  }
}

export function logErrorToDatadog(error: Error, context?: object) {
  if (!config.datadog.disableTracing) {
    datadogRum.addError(error, context);
  } else {
    // For local dev, just log to the console
    console.log(name, context);
  }
}
