import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { IconType } from "../../types/subText.js";
import { FormLabel } from "../FormLabel.js";
import { HelperText } from "../HelperText/HelperText.js";

export interface ReadOnlyContentProps {
  label?: string;
  content: string[];
  isActive?: boolean;
  subtext?: string;
  subtextIcon?: IconType;
  errorMessage?: string;
  dense?: boolean;
  isHighlighted?: boolean;
  firstLineBold?: boolean;
  useMarginBottom?: boolean;
  useMarginTop?: boolean;
}

export const ReadOnlyContent = (props: ReadOnlyContentProps) => {
  const {
    label,
    content,
    isActive,
    subtext,
    errorMessage,
    subtextIcon,
    dense,
    isHighlighted,
    firstLineBold,
    useMarginBottom,
    useMarginTop,
  } = props;

  const Nbsp = "\u00A0";

  const theme = useTheme();

  return (
    <>
      {label && <FormLabel label={label} error={!!errorMessage} />}
      <Box
        data-testid="info-container"
        sx={{
          paddingLeft: theme.spacing(1.2),
          borderLeft: isActive
            ? `${theme.spacing(0.3)} solid ${theme.palette.primary.main}`
            : `${theme.spacing(0.3)} solid ${theme.palette.text.hint}`,
          ...(isHighlighted
            ? {
                backgroundColor: theme.palette.background.highlighted,
              }
            : {}),
          ...(useMarginBottom ? { marginBottom: theme.spacing(2) } : {}),
          ...(useMarginTop ? { marginTop: theme.spacing(2) } : {}),
        }}
      >
        {content
          .filter((c) => c)
          .map((line: string, idx: number) => (
            <Typography
              key={`${idx}_${line}`}
              sx={{
                display: "block",
                color:
                  isActive && dense
                    ? theme.palette.text.secondary
                    : theme.palette.text.primary,
                fontSize: theme.spacing(2),
                fontWeight: dense || firstLineBold ? 500 : 400,
                wordWrap: "break-word",
              }}
            >
              {line || Nbsp}
            </Typography>
          ))}
      </Box>
      {(subtext || errorMessage) && subtextIcon && (
        <Box
          sx={{
            marginBottom: theme.spacing(4),
            marginTop: theme.spacing(1.875),
          }}
        >
          <HelperText subtext={subtext} errorMessage={errorMessage} />
        </Box>
      )}
    </>
  );
};
