import { Patient } from "@aspire/common";
import { Box } from "@mui/material";
import { isEqual, pick } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { Banner, BannerList } from "~/components/design-system/index.js";

export function comparePatientDemographics(
  patient: Pick<Patient, "name" | "address">,
  currentValues: any,
) {
  const newPatientDemographics = {
    name: `${patient.name.given} ${patient.name.family}`,
    address: patient.address.address,
    postalCode: patient.address.postalCode,
  };

  const currentPatientDemographics =
    currentValues && currentValues["patient"]
      ? pick(currentValues["patient"], "name", "address", "postalCode")
      : {};

  return {
    newPatientDemographics,
    currentPatientDemographics,
    patientDemographicsMatch: isEqual(
      newPatientDemographics,
      currentPatientDemographics,
    ),
  };
}

export function PatientDemographicsOutdatedBanner({
  patient,
  currentValues,
}: {
  patient: Pick<Patient, "name" | "address">;
  currentValues: any;
}) {
  const { t } = useTranslation();

  const { patientDemographicsMatch } = comparePatientDemographics(
    patient,
    currentValues,
  );

  return patientDemographicsMatch ? (
    <></>
  ) : (
    <Box sx={{ mb: 4 }}>
      <Banner
        title={t("pages.formDraftCompletionPage.patientDetailsEdited")}
        bannerType={BannerList.WARNING}
        body={t("pages.formPage.patientDetailsOutdated")}
      />
    </Box>
  );
}

export function AdmissionPatientDemographicsOutdatedBanner({
  patient,
  formData,
}: {
  patient: Pick<Patient, "name" | "address">;
  formData: any[];
}) {
  const { t } = useTranslation();

  const allPatientDemographicsMatches = formData
    .map((formDataEntry) => {
      const { patientDemographicsMatch } = comparePatientDemographics(
        patient,
        formDataEntry,
      );
      return patientDemographicsMatch;
    })
    .every(Boolean);

  return allPatientDemographicsMatches ? (
    <></>
  ) : (
    <Box sx={{ mb: 4 }}>
      <Banner
        title={t("pages.formDraftCompletionPage.patientDetailsEdited")}
        bannerType={BannerList.WARNING}
        body={t("pages.formPage.patientDetailsOutdatedAdmissions")}
      />
    </Box>
  );
}
