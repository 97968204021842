import { getExternalPatientIdValue } from "@aspire/common";
import { useExternalPatientLinks } from "./useExternalPatientLinks.js";

/**
 * This hook is used to fetch the NHS number of a patient (RIO).
 * It uses the `useExternalPatientLinks` hook to fetch the external patient links for a given patient ID.
 * If no external patient links are found, it returns null.
 * If external patient links are found, it uses the `getHighestPriorityLink` function to get the link with the highest priority.
 * If no highest priority link is found, it returns null.
 * If a highest priority link is found, it returns the `externalPatientIdValue` of that link, which is the NHS number of the patient.
 *
 * @param {string} patientId - The ID of the patient whose NHS number is to be fetched.
 * @param {boolean} patientIdsToTreatAsRootPatients - What to treat this as a root patient. Useful for predicting what would happen after an unmerge.
 * @returns {string|null} The NHS number of the patient if found, else null.
 */
export function useGetNhsNumber({
  patientId,
  patientIdsToTreatAsRootPatients,
}: {
  patientId: string;
  patientIdsToTreatAsRootPatients?: string[];
}) {
  const { externalPatientLinks } = useExternalPatientLinks({
    patientId,
    patientIdsToTreatAsRootPatients,
  });

  if (!externalPatientLinks) return null;

  return getExternalPatientIdValue(externalPatientLinks.externalLinks);
}
