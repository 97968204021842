import { PATIENT_BULK_IMPORT_TOKEN_MAX_AGE_MONTHS } from "@aspire/common";
import { ContentCopy } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router";
import { api, apiHooks } from "~/api.js";
import {
  Button,
  ButtonIcon,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { LoadingSpinner } from "~/components/design-system/LoadingSpinner.js";
import { routeFns } from "~/routes.js";
import { ConfirmationModal } from "../ConfirmationModal.js";

export function PatientBulkImportTokensManagement() {
  const navigate = useNavigate();
  const [
    { data: patientBulkImportTokens, loading: loading },
    refetchPatientBulkImportTokens,
  ] = apiHooks.patientBulkImport.getAll();

  const [confirmFn, setConfirmFn] = useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);

  return (
    <Box>
      {confirmFn && (
        <ConfirmationModal
          message={confirmFn.message}
          confirmFn={confirmFn.confirmFn}
          closeFn={() => setConfirmFn(null)}
        />
      )}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <Typography variant="h4">Patient Bulk Import Tokens</Typography>
        <Button
          endIcon={ButtonIcon.add}
          label="Create"
          onClick={() => navigate(routeFns.createPatientBulkImportToken())}
        />
      </Box>
      {loading || !patientBulkImportTokens ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Created At</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Team</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Trust Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Trust ODS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patientBulkImportTokens!.tokens.map(
                ({ id, created, teamName, trust, teamId, status }) => {
                  const isExpired =
                    dayjs().diff(dayjs(created), "month") >=
                    PATIENT_BULK_IMPORT_TOKEN_MAX_AGE_MONTHS;

                  const link = `${window.location.origin}${routeFns.usePatientBulkImportToken(id, teamId)}`;
                  return (
                    <TableRow key={id}>
                      <TableCell>
                        {id}
                        {status === "pending" && !isExpired && (
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(link);
                              renderSuccessToast({
                                message: "Copied to clipboard",
                              });
                            }}
                          >
                            <ContentCopy />
                          </IconButton>
                        )}
                      </TableCell>
                      <TableCell>{isExpired ? "expired" : status}</TableCell>
                      <TableCell>
                        {dayjs(created).format("DD/MM/YYYY HH:mm:ss")}
                      </TableCell>
                      <TableCell>{teamName}</TableCell>
                      <TableCell>{trust?.name || "Unknown"}</TableCell>
                      <TableCell>{trust?.odsCode || "Unknown"}</TableCell>
                      <TableCell>
                        <Button
                          label="Revoke"
                          color="error"
                          disabled={status !== "pending"}
                          onClick={async () => {
                            setConfirmFn({
                              confirmFn: async () => {
                                const result =
                                  await api.patientBulkImport.revoke(id);
                                if (result.status === 204) {
                                  renderSuccessToast({
                                    message: "Token revoked successfully",
                                  });
                                  refetchPatientBulkImportTokens();
                                } else {
                                  renderErrorToast({
                                    message: `Failed to revoke token: ${(result.data as any).message}`,
                                  });
                                }
                                setConfirmFn(null);
                              },
                              message:
                                "Are you sure you want to revoke this token? The token will no longer be usable by the trust.",
                            });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
