import pusher_pkg from "pusher-js";
type Channel = pusher_pkg.Channel;
type PresenceChannel = pusher_pkg.PresenceChannel;

import { useEffect } from "react";

/**
 * Subscribes to a channel event and registers a callback.
 * @param channel Pusher channel to bind to
 * @param eventName Name of event to bind to
 * @param callback Callback to call on a new event
 */
export function useEvent<D>(
  channel: Channel | PresenceChannel | undefined,
  eventName: string,
  callback: (data?: D, metadata?: { user_id: string }) => void,
) {
  // error when required arguments aren't passed.
  if (!eventName)
    console.error("Must supply eventName and callback to onEvent");

  if (!callback) console.error("Must supply callback to onEvent");

  // bind and unbind events whenever the channel, eventName or callback changes.
  useEffect(() => {
    if (channel === undefined) {
      return;
    } else channel.bind(eventName, callback);
    return () => {
      channel.unbind(eventName, callback);
    };
  }, [channel, eventName, callback]);
}

export function useEvents<D>(
  channels: Channel[] | PresenceChannel[],
  eventName: string,
  callback: (data?: D, metadata?: { user_id: string }) => void,
) {
  // bind and unbind events whenever the channel, eventName or callback changes.
  useEffect(() => {
    for (const channel of channels) {
      if (channel === undefined) continue;
      channel.bind(eventName, callback);
    }

    return () => {
      for (const channel of channels) {
        if (channel === undefined) continue;
        channel.unbind(eventName, callback);
      }
    };
  }, [channels, eventName, callback]);
}
