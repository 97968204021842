import { useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "~/components/design-system/index.js";

import { FormFooterSection } from "~/components/form/FormFooter.js";
import { useScreenDetection } from "~/hooks/ScreenDetection/useScreenDetection.js";

export type FooterProps = {
  state:
    | "search"
    | "searching"
    | "results-not-found"
    | "results-found"
    | "result-selected";
  onContinue: () => void;
  onClear: () => void;
  onCreate: () => void;
};

export const Footer = ({
  state,
  onContinue,
  onClear,
  onCreate,
}: FooterProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobileView } = useScreenDetection();

  return (
    <FormFooterSection
      customFooterBackgroundColor={theme.palette.common.white}
      saveVariant="contained"
      saveLabel={
        ["search", "searching", "results-not-found"].includes(state)
          ? t("buttonLabels.search")
          : t("buttonLabels.continue")
      }
      onSave={state === "result-selected" ? onContinue : () => {}}
      disableSubmit={["searching", "results-found"].includes(state)}
      performSaveActionOnFormSubmit={["search", "results-not-found"].includes(
        state,
      )}
      secondaryLeftButton={
        <Button
          disabled={["search", "searching"].includes(state)}
          fullWidth={isMobileView}
          label={t("buttonLabels.createPatient")}
          onClick={onCreate}
        />
      }
      discardLabel={t("buttonLabels.clearSearch")}
      disableDiscard={["searching"].includes(state)}
      onCancel={onClear}
    />
  );
};
