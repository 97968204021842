import {
  ExtendedThalamosUser,
  isAdminUser,
  isExtendedTeamMembership,
  isGuestUser,
} from "@aspire/common";
import { css } from "@emotion/react";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonIcon,
  LoadingSpinner,
  MenuFlyout,
  MenuOptionsType,
  PopupDialog,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { getRoleLabel } from "~/util.js";
import { api, apiHooks } from "../../api.js";
import { routeFns } from "../../routes.js";
import { breakpoints } from "../../styles/themeCommon.js";
import { ConfirmationModal } from "../ConfirmationModal.js";
import { UserEmailAddressUpdateDialog } from "./UserEmailAddressUpdateDialog.js";

function UserManagementRow({
  user,
  refetchUsers,
}: {
  user: ExtendedThalamosUser;
  refetchUsers: () => {};
}) {
  const cardRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [modalData, setModalData] = useState<string[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);
  const [userForEmailEdit, setUserForEmailEdit] =
    useState<ExtendedThalamosUser | null>(null);

  const regularUserOptions: MenuOptionsType[] = [
    {
      name: "Send password reset email",
      onClick: async () => {
        const result = await api.users.triggerPasswordResetEmail(user.id);
        if (result.status === 204) {
          setModalData([
            `Successfully sent password reset email to ${user.email}`,
          ]);
        } else {
          setModalData(["Something went wrong. Please try again later."]);
        }
        setIsMenuOpen(false);
      },
      icon: "transfer",
      disabled: false,
    },
  ];

  const guestUserOptions: MenuOptionsType[] = [
    {
      name: "Send guest user login code",
      onClick: async () => {
        const result = await api.users.requestGuestUserLoginCode(user.id, true);
        if (result.status === 200) {
          if (result.data.sent) {
            setModalData([
              `Successfully sent guest user code to ${user.email}`,
            ]);
          } else {
            setModalData([
              "Limit reached for sending guest user codes. Please try again in 15 mins.",
            ]);
          }
        } else {
          setModalData([
            "Error sending guest user code. Please try again later.",
          ]);
        }
        setIsMenuOpen(false);
      },
      icon: "transfer",
      disabled: false,
    },
    {
      name: "Get guest user login code",
      onClick: async () => {
        const result = await api.admin.getGuestUserLoginCode(user.id);
        if (result.status === 200) {
          setModalData([
            `Guest user code: ${result.data.code} (expires in ${
              15 - dayjs().diff(dayjs(result.data.created), "minute")
            } minutes)`,
            `Guest user URL: ${result.data.url}`,
          ]);
        } else {
          setModalData(["Something went wrong. Please try again later."]);
        }
        setIsMenuOpen(false);
      },
      icon: "transfer",
      disabled: false,
    },
    {
      name: "Reset Guest User Logins",
      onClick: async () => {
        setConfirmFn({
          message: `Are you sure you want to reset this guest user account (it has been locked as they have had ${user.failedGuestUserLoginCount} logins)?`,
          confirmFn: async () => {
            const result = await api.users.resetGuestUserLogins(user.id);
            if (result.status === 204) {
              renderSuccessToast({ message: `Guest user ${user.email} reset` });
              refetchUsers();
              setConfirmFn(null);
            } else {
              setModalData(["Something went wrong. Please try again later."]);
            }
            setIsMenuOpen(false);
          },
        });
      },

      icon: "transfer",
      disabled: false,
    },
    {
      name: "Convert to full user / team",
      onClick: async () => {
        navigate(routeFns.adminConverGuestUserPage(user.id));
      },
      icon: "person",
      disabled: false,
    },
  ];

  const options: MenuOptionsType[] = [
    {
      name: "Edit Profile",
      onClick: () => {
        navigate(routeFns.userProfilePage(user.id));
      },
      icon: "edit",
      disabled: false,
    },
    {
      name: "Edit Memberships",
      onClick: () => {
        navigate(routeFns.userMembershipsPage(user.id));
      },
      icon: "edit",
      disabled: false,
    },
    {
      name: "Edit Email",
      onClick: () => {
        setUserForEmailEdit(user);
      },
      icon: "edit",
      disabled: false,
    },

    ...(!isGuestUser(user) && !isAdminUser(user) && user.mfa === "disabled"
      ? [
          {
            name: "Enforce MFA",
            onClick: async () => {
              setConfirmFn({
                message: `Are you sure you want to enforce MFA for user: ${user.name} (${user.email})?`,
                confirmFn: async () => {
                  const result = await api.users.enforceMfa(user.id);
                  if (result.status === 204) {
                    setConfirmFn(null);
                    refetchUsers();
                    setModalData([`Successfully enforced MFA ${user.email}`]);
                  } else {
                    setModalData([
                      "Something went wrong. Please try again later.",
                    ]);
                  }
                  setIsMenuOpen(false);
                },
              });
            },
            icon: "transfer",
            disabled: false,
          },
        ]
      : []),

    ...(user.mfa === "enabled"
      ? [
          {
            name: "Remove MFA",
            onClick: async () => {
              setConfirmFn({
                message: `Are you sure you want to remove MFA for user: ${user.name} (${user.email})?`,
                confirmFn: async () => {
                  const result = await api.users.disableMfa(user.id);
                  if (result.status === 204) {
                    setConfirmFn(null);
                    refetchUsers();
                    setModalData([
                      `Successfully disabled MFA for ${user.email}`,
                    ]);
                  } else {
                    setModalData([
                      "Something went wrong. Please try again later.",
                    ]);
                  }
                  setIsMenuOpen(false);
                },
              });
            },
            icon: "transfer",
            disabled: false,
          },
          {
            name: "Reset MFA",
            onClick: async () => {
              setConfirmFn({
                message: `Are you sure you want to reset MFA (but keep it enabled) for user: ${user.name} (${user.email})?`,
                confirmFn: async () => {
                  const result = await api.users.resetMfa(user.id);
                  if (result.status === 204) {
                    setConfirmFn(null);
                    refetchUsers();
                    setModalData([
                      `Successfully reset MFA for ${user.email}. They will be able to set it up again next time they log in`,
                    ]);
                  } else {
                    setModalData([
                      "Something went wrong. Please try again later.",
                    ]);
                  }
                  setIsMenuOpen(false);
                },
              });
            },
            icon: "transfer",
            disabled: false,
          },
        ]
      : []),

    // TODO: think about SSO etc.
    ...(!isGuestUser(user) && !isAdminUser(user) ? regularUserOptions : []),
    ...(isGuestUser(user) ? guestUserOptions : []),
    {
      name: "View Audit Trail",
      onClick: () => {
        navigate(
          routeFns.adminAuditTrailResourcePage({
            userId: user.id,
            fromDate: dayjs().subtract(30, "days"),
            toDate: dayjs(),
          }),
        );
      },
      icon: "view",
      disabled: false,
    },
    ...(user.loginCount === 0 &&
    // Restrict to users with no memberships for now just to avoid accidental deletion
    user.memberships.filter(
      (m) => !isExtendedTeamMembership(m) || m.teamType !== "guest",
    ).length === 0
      ? [
          {
            name: "Delete User",
            onClick: async () => {
              setConfirmFn({
                message: `Are you sure you want to delete user: ${user.name} (${user.email})? This cannot be undone`,
                confirmFn: async () => {
                  const result = await api.users.delete(user.id);
                  if (result.status === 204) {
                    renderSuccessToast({
                      message: `Successfully deleted user ${user.email}`,
                    });
                    setConfirmFn(null);
                    refetchUsers();
                  } else {
                    renderErrorToast({
                      message: "Something went wrong. Please try again later.",
                    });
                  }
                },
              });
              setIsMenuOpen(false);
            },
            icon: "delete",
            disabled: false,
          },
        ]
      : []),
  ];

  let rowBackground = "white";

  if (user.memberships.length === 0) {
    rowBackground = "lightyellow";
  }

  if (user.failedGuestUserLoginCount > 5) {
    rowBackground = "orange";
  }

  return (
    <>
      <TableRow
        key={user.id}
        sx={{
          backgroundColor: rowBackground,
        }}
      >
        {userForEmailEdit && (
          <UserEmailAddressUpdateDialog
            open={!!userForEmailEdit}
            user={userForEmailEdit}
            onClose={() => setUserForEmailEdit(null)}
            onSuccess={() => {
              setUserForEmailEdit(null);
              refetchUsers();
            }}
          />
        )}
        {confirmFn && (
          <ConfirmationModal
            message={confirmFn.message}
            confirmFn={confirmFn.confirmFn}
            closeFn={() => setConfirmFn(null)}
          />
        )}
        <PopupDialog
          open={modalData !== null}
          onClose={() => setModalData(null)}
        >
          <Box sx={{ padding: "2em" }}>
            {modalData?.map((s) => <p>{s}</p>)}
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button label={"Close"} onClick={() => setModalData(null)} />
            </Box>
          </Box>
        </PopupDialog>
        <TableCell>{user.name}</TableCell>
        <TableCell>
          {user.email} {user.mfa === "enabled" ? "(MFA)" : ""}
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {user.memberships.length === 0
              ? "No Memberships! This user will get an error on logging in"
              : user.memberships.map((m) => {
                  return (
                    <span>{`${
                      m.type === "team"
                        ? m.teamType === "guest"
                          ? "❓:"
                          : m.teamType === "independent"
                            ? "👤:"
                            : "👥:"
                        : "🏢: "
                    } ${`${
                      m.type === "team" ? m.teamName : m.organisationName
                    }`} (${getRoleLabel(m.role)})`}</span>
                  );
                })}
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {user.loginCount > 0 ? (
              <>
                <span>{user.loginCount} logins</span>
                <span>
                  (
                  {`${dayjs(user.lastLoginTimestamp).format(
                    "DD/MM/YYYY HH:mm",
                  )}`}
                  )
                </span>
              </>
            ) : (
              "-"
            )}{" "}
            {user.failedGuestUserLoginCount > 0
              ? `(${user.failedGuestUserLoginCount} failed!)`
              : ""}
          </Box>
        </TableCell>
        <TableCell>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Checkbox
              checked={user.hasPidAccess}
              onChange={async () => {
                setIsLoading(true); // Enable loading state
                try {
                  await api.users.update(user.id, {
                    hasPidAccess: !user.hasPidAccess,
                  });

                  refetchUsers();
                } catch (error) {
                  console.error("Error updating user:", error);
                } finally {
                  setIsLoading(false);
                }
              }}
            />
          )}
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {user.accreditations.map((m) => {
              return <span>{`${m.type}`}</span>;
            })}
          </Box>
        </TableCell>
        <TableCell>
          <Box ref={cardRef} onClick={() => setIsMenuOpen(true)}>
            <MenuFlyout
              cardRef={cardRef}
              options={options}
              isOpen={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
export function UserManagementPage({}: {}) {
  const [userFilterQuery, setUserFilterQuery] = useState("");
  const [page, setPage] = useState(0);
  const userPageSize = 10;

  const [{ data: users, loading: loadingUsers }, refetchUsers] =
    apiHooks.users.search(userFilterQuery, userPageSize, page * userPageSize);

  const numPages =
    users && users.count ? Math.ceil(users.count / userPageSize) : 1;

  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <TextField
          placeholder={"Filter users..."}
          sx={{ width: "100%" }}
          value={userFilterQuery}
          onChange={(e) => {
            setUserFilterQuery(e.target.value);
            setPage(0);
          }}
        />
        <Box
          css={css`
            @media (max-width: ${breakpoints.values.sm}px) {
              margin-left: ${theme.spacing(2)};
            }

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: ${theme.spacing(2)};
          `}
        >
          <Button
            sx={{ marginRight: "0.5em" }}
            variant={"contained"}
            label={"Create"}
            endIcon={ButtonIcon.add}
            onClick={() => navigate(routeFns.adminCreateUsersPage())}
          />
          <Button
            variant={"contained"}
            label={"Bulk"}
            endIcon={ButtonIcon.create}
            onClick={() => navigate(routeFns.adminBulkCreateUsersPage())}
          />
        </Box>
      </Box>
      {!users ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <LoadingSpinner />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <button onClick={() => page > 0 && setPage(page - 1)}>&#60;</button>
            <Box sx={{ marginLeft: "1em", marginRight: "1em" }}>
              Page {page + 1} of {numPages}
            </Box>
            <button onClick={() => page < numPages - 1 && setPage(page + 1)}>
              &#62;
            </button>
          </Box>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    Org / Team Memberships
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    Usage (Last login time)
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>PID Access</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Accreditations</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.results?.map((user) => (
                  <UserManagementRow user={user} refetchUsers={refetchUsers} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}
