import {
  ExtendedThalamosUser,
  isExtendedTeamMembership,
  isGuestUser,
} from "@aspire/common";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormLabel,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { getRoleLabel } from "~/util.js";
import { api, apiHooks } from "../../api.js";
import { routeFns } from "../../routes.js";
import { ConfirmationModal } from "../ConfirmationModal.js";
import { TeamPicker } from "./TeamPicker.js";

function UserMembershipsPageInner({
  user,
  refetchUser,
}: {
  user: ExtendedThalamosUser;
  refetchUser: () => void;
}) {
  const navigate = useNavigate();

  const [name, setName] = React.useState("");
  const [id, setId] = React.useState<string | null>(null);
  const independentTeamContext = user.memberships
    .filter(isExtendedTeamMembership)
    .find((m) => isExtendedTeamMembership(m) && m.teamType === "independent");

  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);

  const membershipsToShow = user.memberships
    .filter(isExtendedTeamMembership)
    .filter((m) => m.teamType !== "independent");

  const isGuest = isGuestUser(user);

  return (
    <>
      <h3>
        User Team Memberships: {user.name} - {user.email} (
        <a href={routeFns.userProfilePage(user.id)}>profile</a>)
      </h3>
      {confirmFn && (
        <ConfirmationModal
          message={confirmFn.message}
          confirmFn={confirmFn.confirmFn}
          closeFn={() => setConfirmFn(null)}
        />
      )}
      {!isGuest ? (
        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
          <Box sx={{ width: "100%", mr: 2 }}>
            <TeamPicker
              error={null}
              idField={"id"}
              nameField={"name"}
              values={{ id, name }}
              setValues={(values: { id: string | null; name: string }) => {
                setId(values.id);
                setName(values.name);
              }}
              label={"Team"}
              disabled={false}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              label={"Add"}
              onClick={async () => {
                await api.teams.addMember(id!, {
                  userId: user.id,
                  role: "member",
                });
                refetchUser();
              }}
              disabled={!id}
            />
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Team</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {membershipsToShow.length === 0 ? (
            <TableRow>
              <TableCell>
                No Team Memberships - add one using the Team Picker above
              </TableCell>
            </TableRow>
          ) : (
            membershipsToShow.map((membership) => (
              <TableRow key={membership.teamId}>
                <TableCell>{membership.teamName}</TableCell>
                <TableCell>{getRoleLabel(membership.role)}</TableCell>
                <TableCell>
                  <Button
                    disabled={isGuest ?? false}
                    label={"Remove"}
                    onClick={async () => {
                      await api.teams.removeMember(membership.teamId, {
                        userId: user.id,
                        role: membership.role,
                      });
                      refetchUser();
                    }}
                  />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      {!isGuest ? (
        <Box sx={{ mt: "2em" }}>
          <FormLabel
            label={`Independent Working Context (e.g. Section 12 doctor) - ${
              independentTeamContext ? "Active ✅" : "Inactive ❌"
            }`}
          />
          {independentTeamContext ? (
            <Button
              color={"error"}
              label={"Delete"}
              onClick={() =>
                setConfirmFn({
                  message: `Are you sure you want to delete the independent working context for ${user.name}? This will only work if they haven't used it`,
                  confirmFn: async () => {
                    const res = await api.users.deleteIndependentContext(
                      user.id,
                    );
                    if (res.status === 204) {
                      refetchUser();
                      renderSuccessToast({
                        message:
                          "Successfully deleted independent working context",
                      });
                    } else {
                      renderErrorToast({
                        message: res.data.reason,
                      });
                    }
                  },
                })
              }
            />
          ) : (
            <Button
              label={"Create"}
              onClick={() =>
                setConfirmFn({
                  message: `Are you sure you want to create an independent working context for ${user.name}?`,
                  confirmFn: async () => {
                    const res = await api.users.createIndependentContext(
                      user.id,
                    );
                    if (res.status === 204) {
                      renderSuccessToast({
                        message:
                          "Successfully created independent working context",
                      });
                      refetchUser();
                    } else {
                      renderErrorToast({
                        message: res.data.reason,
                      });
                    }
                  },
                })
              }
            />
          )}
        </Box>
      ) : (
        <></>
      )}
      {isGuest ? (
        <Box sx={{ mt: "2em" }}>
          <FormLabel label={`Convert to Full User / Team`} />
          <Button
            color={"warning"}
            label={"Convert"}
            onClick={() => navigate(routeFns.adminConverGuestUserPage(user.id))}
          />
        </Box>
      ) : (
        <Box sx={{ mt: "2em" }}>
          <FormLabel label={`Convert to Guest User`} />
          <Button
            color={"warning"}
            label={"Convert"}
            onClick={() =>
              setConfirmFn({
                message: `WARNING - Converting to a Guest User will remove all memberships for this user and delete their username/password from Auth0. Are you sure you know what you are doing? This is the closest we have to deleting a user`,
                confirmFn: async () => {
                  const res = await api.users.convertFullUserToGuest(user.id);
                  if (res.status === 204) {
                    renderSuccessToast({
                      message: "Successfully converted to Guest User",
                    });
                    navigate(routeFns.adminUsersPage());
                  } else {
                    renderErrorToast({
                      message: res.data.reason,
                    });
                  }
                },
              })
            }
          />
        </Box>
      )}
    </>
  );
}

export function UserMembershipsPage({}: {}) {
  let { userId } = useParams();

  const [{ data: user, loading: userLoading }, refetchUser] =
    apiHooks.users.getUserViaPusher(userId!);

  return userLoading ? (
    <div />
  ) : (
    <UserMembershipsPageInner user={user!} refetchUser={refetchUser} />
  );
}
