import { FormContextData, getBaseFormTemplate } from "@aspire/common";
import { Box, Typography, useTheme } from "@mui/material";
import { findIndex } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonIcon,
  FormTitle,
  LoadingSpinner,
  PopupDialog,
  PopupDialogTitle,
} from "~/components/design-system/index.js";
import { api } from "../../../api.js";
import { MultiPagePdf } from "../../../components/MultiPagePdf.js";

import { FormContextMiniForm, getFullFormName } from "@aspire/common";
import { ukLocalFormatDateTime } from "../../../util.js";

interface PdfViewerContentsProps {
  formId: string;
  setFormId: (params: any) => void;
  inModal: boolean;
  data: string | null;
  handlePrev: () => void;
  handleNext: () => void;
  shouldBeDisabledPrev: boolean;
  shouldBeDisabledNext: boolean;
  currentForm: FormContextMiniForm;
  showPrevNextButtons?: boolean;
}

const PdfViewerContents = ({
  data,
  handlePrev,
  handleNext,
  shouldBeDisabledPrev,
  shouldBeDisabledNext,
  showPrevNextButtons,
  currentForm,
}: PdfViewerContentsProps) => (
  <Box>
    <Box
      sx={{
        width: "100%",
        position: "sticky",
        top: "0",
        display: "flex",
        justifyContent: "space-around",
        zIndex: 99,
        overflowY: "auto",
        background: "white",
        padding: 1,
      }}
    >
      <Box>
        {showPrevNextButtons && (
          <Button
            disabled={shouldBeDisabledPrev}
            label={"Previous Form"}
            startIcon={ButtonIcon.arrowBack}
            onClick={handlePrev}
          />
        )}
      </Box>
      <Box>
        <Box sx={{ mr: 8 }}>
          {showPrevNextButtons && (
            <Button
              disabled={shouldBeDisabledNext}
              label={"Next Form"}
              endIcon={ButtonIcon.arrow}
              onClick={handleNext}
            />
          )}
        </Box>
      </Box>
    </Box>
    {currentForm?.source === "manual-upload" && (
      <UploadedFormInfo created={currentForm.created} />
    )}
    <Box
      sx={{
        marginLeft: 2,
        marginRight: 2,
        minHeight: "90vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {data ? <MultiPagePdf data={data} /> : <LoadingSpinner />}
    </Box>
  </Box>
);

export function FormContextPdfViewer({
  formId,
  setFormId,
  forms,
  inModal,
}: {
  forms: FormContextData["forms"];
  formId: string;
  setFormId: (formId: string | null) => void;
  inModal: boolean;
}) {
  const [data, setData] = useState<null | string>(null);

  useEffect(() => {
    setData(null);
    api.forms.getPdf(formId, false).then((result) => {
      setData(result.data!);
    });
  }, [formId]);

  const currentFormIndex = findIndex(forms, (f) => f.id === formId);

  const currentForm = forms[currentFormIndex];

  const template = getBaseFormTemplate(currentForm.formTemplate.id);

  const fullFormName = getFullFormName(template);

  const previousForm =
    currentFormIndex > 0 ? forms[currentFormIndex - 1] : null;
  const nextForm =
    currentFormIndex < forms.length - 1 ? forms[currentFormIndex + 1] : null;

  return inModal ? (
    <PopupDialog open={true} onClose={() => setFormId(null)} hasPadding={false}>
      <Box sx={{ my: 2, mx: 7.5 }}>
        <PopupDialogTitle
          titleText={fullFormName || ""}
          subtitleText="View PDF"
          closeDialog={() => setFormId(null)}
          removeMarginBottom
        />
      </Box>
      <PdfViewerContents
        formId={formId}
        setFormId={setFormId}
        inModal={inModal}
        data={data}
        handlePrev={() => setFormId(previousForm?.id || null)}
        handleNext={() => setFormId(nextForm?.id || null)}
        shouldBeDisabledPrev={!previousForm}
        shouldBeDisabledNext={!nextForm}
        showPrevNextButtons={forms.length > 1}
        currentForm={currentForm}
      />
      <Box
        display="flex"
        justifyContent={"center"}
        sx={{
          mb: 3,
          width: "100%",
          position: "sticky",
          bottom: "0",
          display: "flex",
          justifyContent: "space-around",
          zIndex: 99,
          overflowY: "auto",
          background: "white",
          padding: 1,
        }}
      >
        <Button
          variant="outlined"
          label="Close"
          testId="close-button"
          onClick={() => setFormId(null)}
        />
      </Box>
    </PopupDialog>
  ) : (
    <>
      <PdfViewerContents
        formId={formId}
        setFormId={setFormId}
        inModal={inModal}
        data={data}
        handlePrev={() => setFormId(previousForm?.id || null)}
        handleNext={() => setFormId(nextForm?.id || null)}
        shouldBeDisabledPrev={!previousForm}
        shouldBeDisabledNext={!nextForm}
        showPrevNextButtons={forms.length > 1}
        currentForm={currentForm}
      />
    </>
  );
}

export function VersionFormsPdfViewer({
  formId,
  setFormId,
  forms,
  inModal,
  showPrevNextButtons = true,
}: {
  forms: FormContextData["forms"];
  formId: string;
  setFormId: (formId: string | null) => void;
  inModal: boolean;
  showPrevNextButtons?: boolean;
}) {
  const theme = useTheme();
  const [data, setData] = useState<null | string>(null);

  const currentForm = forms.find((f) => f.id === formId);

  const currentFormVersion = currentForm?.latestVersion;

  const [currentVersion, setCurrentVersion] = useState(currentFormVersion);

  const { t } = useTranslation();

  useEffect(() => {
    setData(null);
    api.forms.getPdf(formId, false, currentVersion).then((result) => {
      setData(result.data!);
    });
  }, [formId, currentVersion]);

  const findVersionByOffset = (offset: number) => {
    const targetVersion = currentVersion! + offset;
    return currentForm?.versions.find(
      (version) => version.version === targetVersion,
    );
  };

  const handleVersionChange = (offset: number) => {
    const findVersion = findVersionByOffset(offset);
    if (findVersion) {
      setCurrentVersion(findVersion.version);
    }
  };

  const handlePrevForm = () => handleVersionChange(-1);
  const handleNextForm = () => handleVersionChange(1);

  const indexOfCurrentVersion = currentForm?.versions.findIndex(
    (version) => version.version === currentVersion,
  );

  const shouldBeDisabledNext =
    !currentForm?.versions[indexOfCurrentVersion! + 1];

  const shouldBeDisabledPrev =
    !currentForm?.versions[indexOfCurrentVersion! - 1];

  const isCurrentVersion = currentFormVersion === currentVersion;

  const template = currentForm?.formTemplate
    ? getBaseFormTemplate(currentForm.formTemplate.id)
    : null;

  const fullFormName = template ? getFullFormName(template) : null;

  return inModal ? (
    <PopupDialog open={true} onClose={() => setFormId(null)} hasPadding={false}>
      <Box sx={{ mt: 2, mx: 8 }}>
        <PopupDialogTitle
          titleText={fullFormName || ""}
          subtitleText="View PDF"
          closeDialog={() => setFormId(null)}
          removeMarginBottom
        />
      </Box>

      {currentFormVersion! > 1 && (
        <>
          <Box
            display="flex"
            sx={{
              ml: 6,
              mr: 6,
              mt: 1,
              border: 3,
              borderColor: isCurrentVersion
                ? "primary.lightGreen"
                : "error.darker",
            }}
          >
            <Box display="flex" sx={{ p: 2 }}>
              <FormTitle
                useReducedTopPadding={true}
                hasTitleBottomMargin={false}
                titleText={`${
                  isCurrentVersion ? "Current Version" : "Old Version"
                }`}
                subtitleText={`Version ${currentVersion} of ${currentFormVersion}`}
              />
            </Box>
          </Box>
        </>
      )}

      <PdfViewerContents
        formId={formId}
        setFormId={setFormId}
        inModal={inModal}
        data={data}
        handlePrev={handlePrevForm}
        handleNext={handleNextForm}
        shouldBeDisabledPrev={shouldBeDisabledPrev}
        shouldBeDisabledNext={shouldBeDisabledNext}
        showPrevNextButtons={currentFormVersion! > 1 && showPrevNextButtons}
        currentForm={currentForm!}
      />
      <Box
        display="flex"
        justifyContent={"center"}
        sx={{
          width: "100%",
          position: "sticky",
          bottom: "0",
          display: "flex",
          justifyContent: "space-around",
          zIndex: 99,
          overflowY: "auto",
          background: "white",
          padding: 1,
        }}
      >
        <Button
          variant="outlined"
          label={t("buttonLabels.close")}
          testId="close-button"
          onClick={() => setFormId(null)}
        />
      </Box>
    </PopupDialog>
  ) : (
    <PdfViewerContents
      formId={formId}
      setFormId={setFormId}
      inModal={inModal}
      data={data}
      handlePrev={handlePrevForm}
      handleNext={handleNextForm}
      shouldBeDisabledPrev={shouldBeDisabledPrev}
      shouldBeDisabledNext={shouldBeDisabledNext}
      showPrevNextButtons={
        currentForm?.latestVersion! > 1 && showPrevNextButtons
      }
      currentForm={currentForm!}
    />
  );
}

function UploadedFormInfo({ created }: { created: string }) {
  const theme = useTheme();

  return (
    <Typography
      textAlign="center"
      sx={{ mt: 1 }}
      fontWeight="bold"
      color={theme.palette.error.main}
    >
      Form Uploaded:
      {ukLocalFormatDateTime(created)}
    </Typography>
  );
}
