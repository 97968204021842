import React from "react";

import { Box, Button, Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import notFound from "../assets/img/notFound.png";

interface ErrorPageProps {
  error?: Error | null;
  onRefresh?: () => void;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ error, onRefresh }) => {
  const { t } = useTranslation();
  return (
    <Container
      data-testid="error-page"
      maxWidth="sm"
      style={{ textAlign: "center", marginTop: "50px" }}
    >
      <Box>
        <img src={notFound} alt={"Not Found"} width={400} />
      </Box>
      <Typography variant="h4" gutterBottom>
        {t("errors.somethingWentWrong")}
      </Typography>
      <Typography variant="body1" paragraph>
        {t("errors.somethingWentWrongDescription")}
      </Typography>
      {error && (
        <div>
          <Typography variant="body1" paragraph>
            {error.message}
          </Typography>
        </div>
      )}
      <Button variant="contained" color="primary" onClick={onRefresh}>
        {t("common.refresh")}
      </Button>
    </Container>
  );
};

export default ErrorPage;
