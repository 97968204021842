function b() {
  throw new Error("error");

  return "asd";
}

function a(): string {
  return b();
}

export function DeliberatelyThrowsAnErrorPage(props: {}) {
  const x = a();

  return <>Error {x}</>;
}
