import { Info } from "@mui/icons-material";
import { IconButton, Typography, useTheme } from "@mui/material";
import React from "react";
import { LightTooltip } from "../layout/PatientBanner/PatientStateDisplay.js";

export interface FormLabelProps {
  label: string;
  error?: boolean;
  isAddressField?: boolean;
  tooltip?: string;
}
export const FormLabel = (props: FormLabelProps) => {
  const { label, error, isAddressField = false, tooltip } = props;

  const theme = useTheme();
  const style = {
    fontSize: 15,
    display: tooltip ? "inline-flex" : "block",
    marginBottom: isAddressField ? theme.spacing(1.5) : theme.spacing(1),
    fontWeight: "bold",
    color: error ? theme.palette.error.main : theme.palette.text.primary,
  };

  return (
    <>
      <Typography data-testid="label-id" sx={style}>
        {label}
      </Typography>
      {tooltip && (
        <LightTooltip title={tooltip} placement="top-start">
          <IconButton sx={{ paddingLeft: "5px" }} color="primary">
            <Info />
          </IconButton>
        </LightTooltip>
      )}
    </>
  );
};
