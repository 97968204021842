import { useMediaQuery, useTheme } from "@mui/material";

/**
 * A React Hook to detect whether we are in a mobile view.
 * Use this for consistency across the application as to which breakpoints
 * constitute a mobile view.
 *
 * @returns isMobileView - boolean
 */
export const useScreenDetection = () => {
  const theme = useTheme();

  // Mobile view
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return {
    isMobileView,
  };
};
