import { ExpandMore } from "@mui/icons-material";
import { alpha, Autocomplete } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { TextField } from "../TextField/TextField.js";

export const AutocompleteStyled = styled(
  Autocomplete,
  {},
)(({ theme }) => ({
  "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
    transform: `translate(${theme.spacing(2)}, ${theme.spacing(2)}) scale(1);`,
  },
  "& .MuiInputLabel-root": {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  "& .MuiAutocomplete-inputRoot": {
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: 14,
    backgroundColor: "transparent",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.primary.main, 0.1),
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.primary.main, 0.1),
    },
    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
      color: theme.palette.primary.main,
    },
  },
}));

export interface SearchAndDropdownProps {
  value: string | null;
  inputValue: string;
  label: string | undefined;
  options: string[];
  onChange: (e: any, v: any) => void;
  onInputChange: (e: any, v: string) => void;
}

export const SearchAndDropdown = (props: SearchAndDropdownProps) => {
  const {
    value,
    label,
    inputValue,
    options,
    onChange,
    onInputChange,
    ...rest
  } = props;

  return (
    <AutocompleteStyled
      value={value}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      popupIcon={<ExpandMore color="primary" />}
      options={options}
      sx={{
        width: 300,
      }}
      renderInput={(params) => (
        <TextField
          name="team"
          {...rest}
          {...params}
          useFullWidth={true}
          placeholder="Select team"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            autoComplete: "off",
            inputProps: {
              ...params.inputProps,
              "data-testid": "searchanddropdown-field",
            },
          }}
        />
      )}
    />
  );
};
