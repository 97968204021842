import { css } from "@emotion/css";
import {
  FormControl,
  TextField,
  TextFieldProps,
  useTheme,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import { Element } from "react-scroll";
import { IconType } from "../../types/subText.js";
import { FormLabel } from "../FormLabel.js";
import { HelperText } from "../HelperText/HelperText.js";
import { ReadOnlyContent } from "../ReadOnlyContent/ReadOnlyContent.js";

const defaultProps: Partial<TimePickerProps> = {
  label: undefined,
  errorMessage: undefined,
  disabled: false,
  variant: "outlined",
  subtext: undefined,
  subtextIcon: undefined,
  readOnly: false,
  isMobile: false,
};

export interface TimePickerProps {
  name: string;
  label?: string;
  errorMessage?: string;
  value: string | Dayjs | null;
  onChange: (e: Dayjs | null) => void;
  variant?: TextFieldProps["variant"];
  disabled?: boolean;
  subtext?: string;
  subtextIcon?: IconType;
  readOnly?: boolean;
  isMobile?: boolean;
}

export const TimePicker = (props: TimePickerProps) => {
  const allProps: TimePickerProps = { ...defaultProps, ...props };
  const {
    name,
    label,
    value,
    errorMessage,
    onChange,
    disabled,
    variant,
    subtext,
    subtextIcon,
    readOnly,
    isMobile,
    ...rest
  } = allProps;

  const theme = useTheme();

  if (readOnly) {
    return (
      <ReadOnlyContent
        label={label}
        content={[dayjs(value).format("HH:mm")]}
        dense
        subtextIcon={subtextIcon!}
      />
    );
  }

  const iconColor = () => {
    if (disabled) return theme.palette.text.disabled;
    if (errorMessage) return theme.palette.error.main;
    return theme.palette.primary.main;
  };

  return (
    <Element
      name={name}
      css={css`
        margin-bottom: ${theme.spacing(4)};
      `}
    >
      {label && <FormLabel label={label} error={!!errorMessage} />}

      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {isMobile ? (
            <MobileTimePicker
              showToolbar={true}
              value={value}
              onChange={onChange}
              ampm={false}
              disabled={disabled}
              InputAdornmentProps={{
                position: "start",
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  {...rest}
                  error={!!errorMessage}
                  variant={variant}
                />
              )}
            />
          ) : (
            <DesktopTimePicker
              showToolbar={true}
              value={value}
              onChange={onChange}
              ampm={false}
              disabled={disabled}
              InputAdornmentProps={{
                position: "start",
                classes: {
                  root: css`
                    margin-left: 12px;
                  `,
                },
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  {...rest}
                  error={!!errorMessage}
                  variant={variant}
                  sx={{
                    svg: { color: iconColor() },
                  }}
                  useFullWidth={true}
                  InputProps={{
                    ...params.InputProps,
                    inputProps: {
                      ...params.inputProps,
                      "data-testid": "time-testid",
                    },
                  }}
                />
              )}
            />
          )}
        </LocalizationProvider>
      </FormControl>
      {(errorMessage || subtext) && (
        <HelperText subtext={subtext} errorMessage={errorMessage} />
      )}
    </Element>
  );
};
