import {
  ExtendedPatientBulkImportToken,
  PATIENT_BULK_IMPORT_TOKEN_MAX_AGE_MONTHS,
} from "@aspire/common";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { Banner, BannerList } from "~/components/design-system/index.js";

export function TokenExpired({
  patientBulkImportToken,
}: {
  patientBulkImportToken: ExtendedPatientBulkImportToken;
}) {
  const expiryDate = dayjs(patientBulkImportToken.created)
    .add(PATIENT_BULK_IMPORT_TOKEN_MAX_AGE_MONTHS, "month")
    .format("YYYY-MM-DD");

  return (
    <Box data-testid="token-expired">
      <Banner
        title={`Token has expired (was valid until 
          ${expiryDate}). 
        Please contact the Thalamos programme team to issue a new one`}
        bannerType={BannerList.ERROR}
      />
    </Box>
  );
}
