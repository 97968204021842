import { FormContextData, formUploadBaseTemplates } from "@aspire/common";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PopupDialog,
  PopupDialogTitle,
  Typeahead,
} from "~/components/design-system/index.js";

import {
  canLaunchForm,
  FormTemplateWithLongDescription,
  initialiseForm,
  preprocessTemplates,
} from "@aspire/common";
import { LoggedInUserContext } from "../../Contexts.js";

import {
  useClearSupportingDocuments,
  useFormTemplateUpdater,
} from "../helpers/FormTemplates/FormTemplates.js";

import { Box, Stack, Typography, useTheme } from "@mui/material";
import { routeFns } from "../../routes.js";
import { FormSupportingDocumentsSelector } from "../FormDraft/FormSupportingDocumentsSelector.js";

import {
  SelectedFormsObject,
  SupportingFormsDetails,
  UploadableBaseFormTemplate,
  ValidationOutcome,
} from "@aspire/common";
import { useTranslation } from "react-i18next";
import { FormFooterSection } from "~/components/form/index.js";

export default function SelectedForm({
  formContext,
  closeFn,
}: {
  formContext: Omit<FormContextData, "patient">;
  closeFn: () => void;
}) {
  const { id: formTemplateIdFromUrl } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const user = useContext(LoggedInUserContext)!.user;

  const [selectedForms, setSelectedForms] = useState<SelectedFormsObject[]>([]);
  const [notSelectedReason, setNotSelectedReason] = useState<string>("");
  const [validationOutcome, setValidationOutcome] = useState<
    ValidationOutcome[] | null
  >(null);
  const [supportingFormsProvided, setSupportingFormsProvided] =
    useState<boolean>(false);

  // Get all the templates that are uploadable
  const allTemplates = preprocessTemplates<UploadableBaseFormTemplate>(
    formUploadBaseTemplates,
  );

  const activeUploadableTemplates = allTemplates.filter((t) => {
    const isUploadedable = t.status === "active" && t.uploadable;
    const isUserAParticipant =
      formContext &&
      formContext.workItems.some((w) => {
        return w.teamId === user.sessionContext?.teamId;
      });

    return isUploadedable && isUserAParticipant;
  });

  const { initialFormInputValue, initialFormTemplate } = initialiseForm(
    activeUploadableTemplates,
    formTemplateIdFromUrl,
  );

  const [formInputValue, setFormInputValue] = useState<string>(
    initialFormInputValue,
  );

  const [formTemplate, setFormTemplate] = useState<
    FormTemplateWithLongDescription | undefined
  >(initialFormTemplate);

  const selectedTemplateId = activeUploadableTemplates.find(
    (t) => t.longDescription === formInputValue,
  )?.id;

  const formTemplateId = formTemplate?.id;
  const signingRequirements = formTemplate?.parts?.[0].signing;
  const linkableForms = signingRequirements?.linkableForms;
  const requiresSupportingForms = !!formContext && !!linkableForms;

  useFormTemplateUpdater(
    formTemplateIdFromUrl,
    formInputValue,
    activeUploadableTemplates,
    formTemplate,
    setFormTemplate,
  );

  useClearSupportingDocuments(
    formTemplateId,
    setSupportingFormsProvided,
    setSelectedForms,
    setNotSelectedReason,
    setValidationOutcome,
  );

  let supportingFormsDetails: SupportingFormsDetails | undefined = undefined;

  const supportingFormsIds = selectedForms.map(({ id }) => id);

  if (requiresSupportingForms) {
    if (!supportingFormsProvided) {
      supportingFormsDetails = {
        type: "provided",
        formIds: supportingFormsIds!,
      };
    } else {
      supportingFormsDetails = {
        type: "not-provided",
        reason: notSelectedReason!,
      };
    }
  }

  const canContinueOrStartForm = canLaunchForm({
    linkableForms,
    supportingFormsProvided,
    notSelectedReason,
    validationOutcome,
    requiresSupportingForms,
  });

  const isConfirmButtonDisabled =
    !selectedTemplateId || !canContinueOrStartForm;

  return (
    <PopupDialog open={true} onClose={closeFn} testId="select-form-upload-form">
      <Box sx={{ mb: 3 }}>
        <PopupDialogTitle
          titleText={t("buttonLabels.uploadForm")}
          closeDialog={closeFn}
        />
      </Box>
      <Stack gap={theme.spacing(2)}>
        <Typography>{t("pages.formUpload.selectedFormLabel")}</Typography>
        <Typeahead
          testId="select-new-form"
          onInputChange={setFormInputValue}
          placeholder={t("components.typeahead.placeholder")}
          name={"select-form"}
          inputValue={formInputValue}
          label={t("components.typeahead.label")}
          options={activeUploadableTemplates.map((f) => ({
            value: f.id,
            label: f.longDescription,
          }))}
        />
      </Stack>

      {requiresSupportingForms && (
        <FormSupportingDocumentsSelector
          formContext={formContext!}
          linkableForms={linkableForms}
          selectedForms={selectedForms}
          setSelectedForms={setSelectedForms}
          notSelectedReason={notSelectedReason}
          setNotSelectedReason={setNotSelectedReason}
          supportingFormsProvided={supportingFormsProvided}
          setSupportingFormsProvided={setSupportingFormsProvided}
          setValidationOutcome={setValidationOutcome}
        />
      )}

      <FormFooterSection
        customFooterBackgroundColor="common.white"
        onSave={() =>
          navigate(
            routeFns.formUpload(
              formContext.id,
              formContext.patientId,
              selectedTemplateId!,
              supportingFormsDetails,
            ),
          )
        }
        disableSubmit={isConfirmButtonDisabled}
        saveLabel={t("buttonLabels.confirm")}
        discardLabel={t("buttonLabels.close")}
        onCancel={closeFn}
      />
    </PopupDialog>
  );
}
