import { ExtendedThalamosUser, TermsAndConditions } from "@aspire/common";

export function termsAndConditionsNeedingAcceptanceByUser(
  user: ExtendedThalamosUser,
): Pick<TermsAndConditions, "id" | "humanReadableName"> | null {
  const requiredTermsAndConditions =
    user.sessionOrganisationConfiguration?.termsAndConditions;

  const alreadyAcceptedTermsAndConditions =
    user.termsAndConditionsAcceptances.some(
      (t) => t.id === requiredTermsAndConditions?.id,
    );

  const needsToAcceptTermsAndConditions =
    requiredTermsAndConditions && !alreadyAcceptedTermsAndConditions;

  return needsToAcceptTermsAndConditions ? requiredTermsAndConditions : null;
}
