import {
  ApprovedClinicianCheckboxFormBuilderField,
  isApprovedClinicianAccreditation,
} from "@aspire/common";
import { css } from "@emotion/react";
import { InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoggedInUserContext } from "../../Contexts.js";
import { FieldProps } from "../../pages/FieldProps.js";
import { HelperText } from "../design-system/HelperText/HelperText.js";

export type ApprovedClinicianConfirmationCheckboxFormFieldProps<
  Data extends { [k: string]: any },
> = {
  field: ApprovedClinicianCheckboxFormBuilderField;
  fieldProps: FieldProps<Data>;
};

export function ApprovedClinicianConfirmationCheckboxFormField<
  Data extends { [k: string]: any },
>({
  field,
  fieldProps,
}: ApprovedClinicianConfirmationCheckboxFormFieldProps<Data>) {
  const theme = useTheme();
  const userContext = useContext(LoggedInUserContext);
  const { t } = useTranslation();
  const hasApprovedClinicianAccreditation =
    userContext?.user.accreditations.some(isApprovedClinicianAccreditation);
  const fieldName = "isApprovedClinician";
  const [showApprovedClinicianWarning, setShowApprovedClinicianWarning] =
    useState(
      !!fieldProps.values["user"][fieldName] &&
        !hasApprovedClinicianAccreditation,
    );

  // @ts-ignore
  const userErrors = fieldProps.errors["user"] || {};
  const touchedErrors = fieldProps.touched["user"] || {};

  // @ts-ignore
  const isError = userErrors[fieldName] && touchedErrors[fieldName];

  // @ts-ignore
  const errorMessage = userErrors[fieldName] as string;

  return (
    <Box sx={{ mb: 3 }}>
      <FormGroup data-testid={`field:${field.type}`}>
        <Box display="flex">
          <Typography
            css={css`
              margin-bottom: ${theme.spacing(0.5)};
              font-weight: bold;
              font-size: ${theme.spacing(1.875)};
              color: ${isError && theme.palette.error.main};
            `}
          >
            {field.label}
          </Typography>
          <Tooltip title={field.tooltip}>
            <InfoOutlinedIcon
              style={{
                fontSize: "1rem",
                marginLeft: theme.spacing(0.5),
              }}
            />
          </Tooltip>
        </Box>
        <Box display="inline-block">
          <FormControlLabel
            control={
              <Checkbox
                data-testid={`approved-clinician-confirmation-checkbox`}
                name={fieldName}
                onBlur={fieldProps.handleBlur}
                sx={{ color: "primary.main" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  fieldProps.setValues({
                    ...fieldProps.values,
                    user: {
                      ...fieldProps.values.user,
                      [fieldName]: e.target.checked,
                    },
                  });
                  setShowApprovedClinicianWarning(
                    e.target.checked && !hasApprovedClinicianAccreditation,
                  );
                }}
                checked={!!fieldProps.values["user"][fieldName]}
              />
            }
            label={
              <Typography style={{ fontSize: theme.spacing(1.75) }}>
                {field.checkboxLabel}
              </Typography>
            }
          />
        </Box>
        {showApprovedClinicianWarning && (
          <HelperText
            errorMessage={t("forms.approvedClinicianWarning").split("\n")}
          />
        )}
        {isError && <HelperText errorMessage={errorMessage} />}
      </FormGroup>
    </Box>
  );
}
