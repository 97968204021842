import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { Subtitle } from "../Subtitle.js";

export interface FormTitleProps {
  titleText?: string;
  hasTitleBottomMargin?: boolean;
  subtitleText?: string;
  isDarkTitle?: boolean;
  additionalTitle?: string;
  newRowAdditionalTitle?: string;
  useReducedTopPadding?: boolean;
  isAlignedLeft?: boolean;
  showBorder?: boolean;
  hasContainerMarginBottom?: boolean;
  subTitleTextFontSize?: number;
}

const defaultProps: Partial<FormTitleProps> = {
  subtitleText: undefined,
  hasTitleBottomMargin: true,
  titleText: undefined,
  isDarkTitle: false,
  additionalTitle: undefined,
  newRowAdditionalTitle: undefined,
  useReducedTopPadding: false,
  isAlignedLeft: true,
  showBorder: true,
  hasContainerMarginBottom: true,
};

export const FormTitle = (props: FormTitleProps) => {
  const allProps: FormTitleProps = { ...defaultProps, ...props };

  const {
    subtitleText,
    titleText,
    hasTitleBottomMargin,
    isDarkTitle,
    additionalTitle,
    newRowAdditionalTitle,
    useReducedTopPadding,
    isAlignedLeft,
    showBorder,
    hasContainerMarginBottom,
    subTitleTextFontSize,
  } = allProps;

  const theme = useTheme();

  const titleMarginBottom = () => {
    if (subtitleText) return theme.spacing(2);
    if (newRowAdditionalTitle) return theme.spacing(10);
    return hasTitleBottomMargin ? theme.spacing(4) : theme.spacing(0);
  };

  return (
    <Box
      display="flex"
      alignItems={isAlignedLeft ? "left" : "center"}
      flexDirection="column"
      sx={{
        justifyContent: "center",
        paddingLeft: theme.spacing(2),
        ...(showBorder
          ? {
              borderLeft: `${theme.spacing(0.4)} solid ${
                theme.palette.primary.main
              }`,
            }
          : {}),
        ...(hasContainerMarginBottom ? { marginBottom: theme.spacing(4) } : {}),
      }}
      data-testid="box-id"
    >
      <Box
        display="flex"
        alignItems={isAlignedLeft ? "left" : "center"}
        sx={{ flexDirection: newRowAdditionalTitle ? "column" : "row" }}
      >
        {titleText && (
          <Typography
            variant="h2"
            sx={{
              fontSize: "1.375rem",
              fontWeight: 400,
              color: isDarkTitle
                ? theme.palette.common.black
                : theme.palette.text.primary,
              marginRight: additionalTitle ? theme.spacing(2) : "",
              marginTop: useReducedTopPadding ? "" : theme.spacing(10),
              textAlign: isAlignedLeft ? "left" : "center",
              marginBottom: titleMarginBottom(),
            }}
            data-testid="title-id"
          >
            {titleText}
          </Typography>
        )}
        {additionalTitle && (
          <Typography
            sx={{
              fontSize: theme.spacing(3),
              color: theme.palette.common.black,
              fontWeight: theme.spacing(37.5),
              marginTop: theme.spacing(10.5),
              marginBottom: subtitleText ? theme.spacing(2) : theme.spacing(4),
            }}
          >
            {additionalTitle}
          </Typography>
        )}
        {newRowAdditionalTitle && (
          <Typography
            sx={{
              fontSize: theme.spacing(3.75),
              fontWeight: 300,
              lineHeight: theme.spacing(0.1),
              textAlign: "center",
              marginBottom: theme.spacing(2),
              [theme.breakpoints.down("sm")]: {
                lineHeight: theme.spacing(0.15),
              },
            }}
          >
            {newRowAdditionalTitle}
          </Typography>
        )}
      </Box>

      {subtitleText && (
        <Subtitle text={subtitleText} fontSize={subTitleTextFontSize} />
      )}
    </Box>
  );
};
