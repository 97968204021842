import {
  HospitalSchema,
  NameAndAddressPickerFormBuilderField,
} from "@aspire/common";
import { Box } from "@mui/material";
import { FormikErrors } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { config } from "../../../config.js";
import { FieldProps } from "../../../pages/FieldProps.js";
import { AddressField } from "../../design-system/AddressField/AddressField.js";
import { FormLabel } from "../../design-system/FormLabel.js";
import { HelperText } from "../../design-system/HelperText/HelperText.js";
import { ReadOnlyContent } from "../../design-system/ReadOnlyContent/ReadOnlyContent.js";
import { TextField } from "../../design-system/TextField/TextField.js";
import "../autocomplete.css";

export type NameAddressFormFieldProps<Data extends { [k: string]: any }> = {
  field: NameAndAddressPickerFormBuilderField<Data>;
  fieldProps: FieldProps<Data>;
  warnings?: {
    name?: string;
    address?: string;
  };
};

export function NameAddressFormField<Data extends { [k: string]: any }>({
  field,
  fieldProps,
  warnings,
}: NameAddressFormFieldProps<Data>) {
  const selected = fieldProps.values[field.field] as HospitalSchema | null;
  const user = fieldProps.values[field.field];
  const { t } = useTranslation();

  let isTouched = fieldProps.touched?.[field.field];

  let errors: { [key: string]: string | undefined } = {};
  if (isTouched) {
    if (typeof fieldProps.errors?.[field.field] === "string") {
      errors = {
        overall: (fieldProps.errors?.[field.field] as string) ?? undefined,
      };
    } else {
      errors = (fieldProps.errors?.[field.field] ?? {}) as FormikErrors<{
        [key: string]: string;
      }>;
    }
  }

  // Return an object just containing one key from the data
  const pick = (data: Partial<Data>, key: string) => {
    return (data[key] ?? undefined) !== undefined ? { [key]: data[key] } : {};
  };

  const onNameChanged = useCallback(
    (value: string) => {
      const newValues = {
        ...fieldProps.values,
        [field.field]: {
          ...selected,
          name: value,
        },
      };
      fieldProps.setValues(newValues);
    },
    [JSON.stringify(fieldProps.values)],
  );

  const onAddressChanged = useCallback(
    (value: Data) => {
      const newValues = {
        ...fieldProps.values,
        [field.field]: {
          ...selected,
          // This callback should only influence the address portions of the propss
          ...pick(value, "address"),
          ...pick(value, "postalCode"),
          ...pick(value, "isConfirmed"),
        },
      };
      fieldProps.setValues(newValues);
    },
    [JSON.stringify(fieldProps.values)],
  );

  return (
    <>
      {/* Fieldset label */}
      {field.nameLabel && (
        <FormLabel label={field.nameLabel} error={!!errors.name} />
      )}

      {/* Email field (read only) */}
      {!field.disableEmail && user && (
        <Box sx={{ mb: 2 }}>
          <ReadOnlyContent content={[user.email]} dense isActive={true} />
        </Box>
      )}

      {/* Name field */}
      {!field.disableName && (
        <TextField
          name={field.field}
          useFullWidth
          errorMessage={errors.overall || errors.name}
          warningMessage={warnings?.name}
          showHelperText={!!(errors.overall || errors.name || warnings?.name)}
          value={selected?.name || ""}
          onChange={onNameChanged}
        />
      )}

      {/* Address field */}
      {!field.disableAddress && (
        <Box sx={{ mb: 2 }}>
          <AddressField
            name={field.field}
            label={field.addressLabel}
            showHelperText={
              !!(
                errors.overall ||
                errors.address ||
                errors.postalCode ||
                errors.isConfirmed ||
                warnings?.address
              )
            }
            errors={errors}
            warningMessage={warnings?.address}
            googleMapsApiKey={config.googleMapsApiKey}
            addressCheckBoxLabel={t(
              "forms.nameAddressFormField.addressCheckBoxLabel",
            )}
            value={selected ?? undefined}
            onChange={onAddressChanged}
          />
        </Box>
      )}

      {/* Info text */}
      {field.afterLabel && <HelperText subtext={field.afterLabel} />}
    </>
  );
}
