import dayjs, { Dayjs } from "dayjs";

import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
dayjs.extend(utc);
dayjs.extend(timezone);

// Kill this with fire once https://github.com/mui/mui-x/issues/8484 is fixed
export function ukTimezoneHackeryOnUTCValue(s: string) {
  if (!s || !dayjs(s).isValid()) {
    return s || null;
  }
  const localTimezone = dayjs.tz.guess();
  const offset = dayjs(s).tz(localTimezone).utcOffset();
  const ukOffset = dayjs(s).tz("Europe/London").utcOffset();

  return dayjs(s)
    .tz("Europe/London")
    .subtract(offset, "minutes")
    .add(ukOffset, "minutes")
    .toISOString();
}

export function ukTimezoneHackeryOnBrowserLocalValue(s: Dayjs | null) {
  if (!s || !s.isValid()) {
    return s?.toString() || null;
  }
  const localTimezone = dayjs.tz.guess();
  const offset = dayjs(s).tz(localTimezone).utcOffset();
  const ukOffset = dayjs(s).tz("Europe/London").utcOffset();
  return s
    .add(offset, "minutes")
    .subtract(ukOffset, "minutes")
    .tz("Europe/London")
    .toISOString();
}
