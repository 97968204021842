import { Box } from "@mui/material";
import {
  Banner,
  BannerList,
  Button,
  PopupDialog,
} from "~/components/design-system/index.js";

export function EditMhaStatusSuccessModal({
  title,
  body,
  confirmFn,
  closeFn,
}: {
  title: string;
  body: string;
  confirmFn: () => void;
  closeFn?: () => void;
}) {
  return (
    <PopupDialog open={true}>
      <Banner title={title} bannerType={BannerList.CONFIRMATION} body={body} />
      <Box
        sx={{
          marginTop: "2em",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button label="Close" testId="close-button" onClick={confirmFn} />
      </Box>
    </PopupDialog>
  );
}
