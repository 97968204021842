import {
  Accreditation,
  ExtendedThalamosUser,
  isAmhpAccreditation,
  isApprovedClinicianAccreditation,
  isDoctorAccreditation,
  isHcpcAccreditation,
  isNurseAccreditation,
  isSection12Accreditation,
  isSocialWorkerAccreditation,
} from "@aspire/common";
import { Box } from "@mui/material";
import { uniq } from "lodash-es";
import React from "react";
import {
  Banner,
  Button,
  FormTitle,
  LoadingSpinner,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { api, apiHooks } from "../../api.js";

import {
  isoDateString,
  nonEmptyRequiredString,
  requiredPostalCode,
} from "@aspire/common";
import { Formik } from "formik";
import { array, InferType, object, string } from "yup";

import { formValidationErrorMessages, isTeamMembership } from "@aspire/common";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { FormFooterSection } from "~/components/form/index.js";
import { ManagedLocationsContext } from "../../Contexts.js";
import { useScreenDetection } from "../../hooks/ScreenDetection/useScreenDetection.js";
import {
  autoSaveStateReducer,
  initalAutoSaveState,
} from "../../reducers/autoSaveReducer.js";
import { DefaultPageProps } from "../defaultProps.js";
import { UserProfileContainer } from "./UserProfileContainer.js";

const TEAM_TYPES = {
  WARD: "ward",
};

type AccreditationMap = {
  [key: string]: Accreditation["type"];
};

export const PROF_REG_TYPES: AccreditationMap = {
  GMC: "doctor",
  SWE: "social-worker",
  NMC: "nurse",
  HCPC: "hcpc",
};

export const APPROVAL_TYPES: AccreditationMap = {
  AC: "approved-clinician",
  S12: "section-12",
  AMHP: "amhp",
};

export const dataSchema = object({
  name: nonEmptyRequiredString
    .required("Please enter your name")
    .max(255, formValidationErrorMessages.tooManyCharactersError),
  address: string()
    .required("Please enter your address")
    .max(255, formValidationErrorMessages.tooManyCharactersError),
  postalCode: requiredPostalCode(),
  gmcNumber: string().test({
    name: "validator-gmcNumber",
    test: function (value, ctx) {
      return !value && ctx.parent.profRegistrations.includes(PROF_REG_TYPES.GMC)
        ? this.createError({
            message: "Please enter your GMC number",
            path: "gmcNumber",
          })
        : true;
    },
  }),
  occupation: array().default([]),
  teams: array().default([]),
  profRegistrations: array().default([]),
  mhApprovals: array().default([]),

  section12ExpiryDate: isoDateString.test({
    name: "validator-section12ExpiryDate",
    test: function (value, ctx) {
      const derived = deriveValues(ctx.parent);
      return !value && derived.s12ExpiryRequired
        ? this.createError({
            message: "Please enter Section 12 expiry date",
            path: "section12ExpiryDate",
          })
        : true;
    },
  }),

  localAuthority: string()
    .nullable()
    .test({
      name: "validator-localAuthority",
      test: function (value, ctx) {
        const derived = deriveValues(ctx.parent);

        if (derived.isAmhp && !value) {
          return this.createError({
            message: "Please enter the local authority you are approved by",
            path: "localAuthority",
          });
        } else if (derived.isAmhp && !derived.canBeAmhp) {
          return this.createError({
            message:
              "You must have either a HCPC, Nurse, or Social Worker accreditation",
            path: "localAuthority",
          });
        } else {
          return true;
        }
      },
    }),
  localAuthorityOrganisationId: string(),

  socialWorkerNumber: string().when("profRegistrations", {
    is: (regs: Accreditation["type"][]) => regs.includes(PROF_REG_TYPES.SWE),
    then: (s) =>
      nonEmptyRequiredString.required(
        "Please enter your SWE registration number",
      ),
  }),

  hcpcNumber: string().when("profRegistrations", {
    is: (regs: Accreditation["type"][]) => regs.includes(PROF_REG_TYPES.HCPC),
    then: (s) =>
      nonEmptyRequiredString.required(
        "Please enter your HCPC registration number",
      ),
  }),

  pinNumber: string().when("profRegistrations", {
    is: (regs: Accreditation["type"][]) => regs.includes(PROF_REG_TYPES.NMC),
    then: (s) =>
      nonEmptyRequiredString.required("Please enter your PIN number"),
  }),

  approvedClinicianExpiryDate: isoDateString.when("mhApprovals", {
    is: (apps: Accreditation["type"][]) => apps.includes(APPROVAL_TYPES.AC),
    then: (s) =>
      isoDateString.required("Please enter Approved Clinician expiry date"),
  }),
  defaultSignature: string(),
});

interface DerivedValues {
  isRmp: boolean;
  isNurse: boolean;
  isHcpc: boolean;
  isSocialWorker: boolean;
  isAmhp: boolean;
  isApprovedClinician: boolean;
  isSection12Approved: boolean;
  canBeAmhp: boolean;
  s12ExpiryRequired: boolean;
}

export function deriveValues(
  profileData: InferType<typeof dataSchema>,
): DerivedValues {
  const isRmp = profileData.profRegistrations.includes(PROF_REG_TYPES.GMC);
  const isNurse = profileData.profRegistrations.includes(PROF_REG_TYPES.NMC);
  const isHcpc = profileData.profRegistrations.includes(PROF_REG_TYPES.HCPC);
  const isSocialWorker = profileData.profRegistrations.includes(
    PROF_REG_TYPES.SWE,
  );
  const isAmhp = profileData.mhApprovals.includes(APPROVAL_TYPES.AMHP);
  const isApprovedClinician = profileData.mhApprovals.includes(
    APPROVAL_TYPES.AC,
  );
  const isSection12Approved =
    (isRmp && isApprovedClinician) ||
    profileData.mhApprovals.includes(APPROVAL_TYPES.S12);

  return {
    isRmp,
    isNurse,
    isHcpc,
    isSocialWorker,
    isAmhp,
    isApprovedClinician,
    isSection12Approved,
    canBeAmhp: isNurse || isHcpc || isSocialWorker,
    s12ExpiryRequired: isSection12Approved && !(isRmp && isApprovedClinician),
  };
}

export function applyFormInvariants(
  values: InferType<typeof dataSchema>,
): InferType<typeof dataSchema> {
  const newValues = { ...values };
  const derived = deriveValues(newValues);

  // Unselect AMHP if none of NMC, HCPC, SWE are selected
  if (!derived.canBeAmhp) {
    newValues.mhApprovals = newValues.mhApprovals.filter(
      (v) => v !== APPROVAL_TYPES.AMHP,
    );
  }

  // Unselect S12 if GMC is not selected
  // Select S12 if GMC and AC are selected
  if (!derived.isRmp) {
    newValues.mhApprovals = newValues.mhApprovals.filter(
      (v) => v !== APPROVAL_TYPES.S12,
    );
  } else if (derived.isApprovedClinician) {
    newValues.mhApprovals = uniq([
      ...newValues.mhApprovals,
      APPROVAL_TYPES.S12,
    ]);
  }

  // Unselect all mental health approvals if no professional registrations are selected
  if (!values.profRegistrations.length) {
    newValues.mhApprovals = [];
  }

  return newValues;
}

function buildDefaultData(
  profileData: ExtendedThalamosUser,
): InferType<typeof dataSchema> {
  const doctorAccred = profileData.accreditations.find(isDoctorAccreditation);
  const s12Accred = profileData.accreditations.find(isSection12Accreditation);
  const acAccred = profileData.accreditations.find(
    isApprovedClinicianAccreditation,
  );
  const amhpAccred = profileData.accreditations.find(isAmhpAccreditation);
  const nurseAccred = profileData.accreditations.find(isNurseAccreditation);
  const socialWorkerAccreditation = profileData.accreditations.find(
    isSocialWorkerAccreditation,
  );
  const hcpcAccred = profileData.accreditations.find(isHcpcAccreditation);

  const isWardTeamMember = profileData.memberships.find(
    (m) => isTeamMembership(m) && m?.teamType === TEAM_TYPES.WARD,
  );

  const wardTeamType =
    isWardTeamMember &&
    isTeamMembership(isWardTeamMember) &&
    isWardTeamMember?.teamType;

  const occupations = [
    doctorAccred?.type,
    nurseAccred?.type,
    amhpAccred?.type,
  ].filter((o) => o);

  return {
    name: profileData.name,
    address: profileData.address.address,
    postalCode: profileData.address.postalCode,
    defaultSignature: profileData.defaultSignature,

    occupation:
      (occupations.length && occupations) ||
      (wardTeamType && [wardTeamType]) ||
      [],

    teams: profileData.memberships.filter(isTeamMembership),
    profRegistrations: profileData.accreditations
      .map((a) => a.type)
      .filter((type) => Object.values(PROF_REG_TYPES).includes(type)),
    mhApprovals: profileData.accreditations
      .map((a) => a.type)
      .filter((type) => Object.values(APPROVAL_TYPES).includes(type)),

    gmcNumber: doctorAccred?.gmcNumber,
    pinNumber: nurseAccred?.pinNumber,
    hcpcNumber: hcpcAccred?.registrationNumber,
    socialWorkerNumber: socialWorkerAccreditation?.registrationNumber,

    localAuthority: amhpAccred?.localAuthority,
    localAuthorityOrganisationId: amhpAccred?.localAuthorityOrganisationId,
    approvedClinicianExpiryDate: acAccred?.expiryDate || null,
    section12ExpiryDate: s12Accred?.expiryDate || null,
  };
}

export function createAccreditations(userData: InferType<typeof dataSchema>) {
  const derived = deriveValues(userData);

  const doctorAccred = { type: "doctor", gmcNumber: userData.gmcNumber! };
  const nurseAccred = { type: "nurse", pinNumber: userData.pinNumber! };
  const acAccred = {
    type: "approved-clinician",
    expiryDate: userData.approvedClinicianExpiryDate!,
  };
  const swAccred = {
    type: "social-worker",
    registrationNumber: userData.socialWorkerNumber!,
  };
  const hcpcAccred = { type: "hcpc", registrationNumber: userData.hcpcNumber! };
  const amhpAccred = {
    type: "amhp",
    localAuthority: userData.localAuthority!,
    localAuthorityOrganisationId: userData.localAuthorityOrganisationId!,
  };
  const s12Accred = {
    type: "section-12",
    expiryDate:
      derived.isRmp && derived.isApprovedClinician
        ? userData.approvedClinicianExpiryDate!
        : userData.section12ExpiryDate!,
  };

  // Combine accreditations into an array
  const accreditations = [
    derived.isRmp && doctorAccred,
    derived.isNurse && nurseAccred,
    derived.isHcpc && hcpcAccred,
    derived.isSocialWorker && swAccred,
    derived.isAmhp && amhpAccred,
    derived.isApprovedClinician && acAccred,
    derived.isSection12Approved && s12Accred,
  ].filter(Boolean) as Accreditation[];

  return accreditations;
}

export function UserProfileInner({
  user,
  refetchUser,
}: {
  user: ExtendedThalamosUser;
  refetchUser: () => void;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [autoSaveState, dispatchAutoSaveState] = React.useReducer(
    autoSaveStateReducer,
    initalAutoSaveState,
  );

  const managedLocationsContext = React.useContext(ManagedLocationsContext);

  const localAuthorities = managedLocationsContext.managedLocations
    .filter((location) => location.type === "localAuthority")
    .sort((a, b) => a.name.localeCompare(b.name));

  const { isMobileView } = useScreenDetection();

  const onSave = async (userData: InferType<typeof dataSchema>) => {
    const accreditations = createAccreditations(userData);

    // Send API request and refresh data from the server
    await api.users.update(user.id, {
      name: userData.name,
      address: { address: userData.address, postalCode: userData.postalCode },
      defaultSignature: userData.defaultSignature,
      accreditations,
    });
    renderSuccessToast({
      message: `Profile successfully saved`,
    });
    refetchUser();
  };

  const getDefaultSelectedContext = user.memberships.find((m) =>
    m.type === "team"
      ? m.teamId === user.defaultSelectedContext?.id
      : m.organisationId === user.defaultSelectedContext?.id,
  );

  const defaultSelectedContextName =
    getDefaultSelectedContext?.type === "team"
      ? getDefaultSelectedContext.teamName
      : getDefaultSelectedContext?.organisationName;

  return (
    <>
      <FormTitle
        useReducedTopPadding
        hasTitleBottomMargin={false}
        titleText={t("pages.userProfile.title")}
      />
      <Box sx={{ mb: 2 }}>
        <Banner
          title="Update your profile "
          body={
            "Please enter your professional information and click save. Your profile details will be populated onto the forms you are completing."
          }
        />
      </Box>
      <Formik
        validationSchema={dataSchema}
        initialValues={buildDefaultData(user)}
        onSubmit={onSave}
      >
        {({
          values,
          errors,
          touched,
          setValues,
          handleBlur,
          isSubmitting,
          setFieldTouched,
          submitForm,
          /* and other goodies */
        }) => {
          const fieldProps = {
            validationSchema: dataSchema,
            context: {},
            values,
            errors,
            handleBlur,
            setFieldTouched,
            touched,
            setValues: setValues,
          };
          const derived = deriveValues(values);

          return (
            <>
              <UserProfileContainer
                values={values}
                user={user}
                errors={errors}
                setValues={setValues}
                derived={derived}
                localAuthorities={localAuthorities}
                fieldProps={fieldProps}
                dispatchAutoSaveState={dispatchAutoSaveState}
                isAutoSaving={autoSaveState.isLoading}
                defaultSelectedContextName={defaultSelectedContextName}
              />
              <FormFooterSection
                isSticky={true}
                disableSubmit={isSubmitting}
                autoSaveState={autoSaveState}
                autoSaveType={"profile"}
                discardLabel={t("buttonLabels.close")}
                hideSubmit
                onSave={submitForm}
                secondaryLeftButton={
                  <Button
                    fullWidth={isMobileView}
                    variant="contained"
                    label={t("buttonLabels.save")}
                    onClick={submitForm}
                  />
                }
                onCancel={() => {
                  navigate(-1);
                }}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
}

export function UserProfile(props: DefaultPageProps) {
  let { userId } = useParams();
  const { t } = useTranslation();

  const [{ data: user, loading: userLoading, error: userError }, refetchUser] =
    apiHooks.users.getUserDetails(userId!);

  if (userLoading) {
    return (
      <Box
        height="100%"
        display="grid"
        justifyItems="center"
        alignItems="center"
      >
        <LoadingSpinner label="Loading..." />
      </Box>
    );
  }

  if (userError || !user) {
    return <Box>{t("errors.userLoadingErrorMessage")}</Box>;
  }

  return <UserProfileInner user={user} refetchUser={refetchUser} />;
}
