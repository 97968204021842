import {
  SupportingFormsDetails,
  UploadableBaseFormTemplate,
  UploadFormRequest,
} from "@aspire/common";
import { Box } from "@mui/material";
import { isEmpty } from "lodash-es";
import React from "react";
import {
  Button,
  LoadingSpinner,
  PopupDialog,
  PopupDialogTitle,
  renderErrorToast,
} from "~/components/design-system/index.js";
import { UploadedFile } from "~/components/form/index.js";
import { api, apiHooks } from "../../api.js";
import { MultiPagePdf } from "../../components/MultiPagePdf.js";
import { ConfirmationModal } from "../ConfirmationModal.js";

type FormUploadConfirmationModalProps = {
  formId: string;
  formContextId: string;
  formTemplate: UploadableBaseFormTemplate;
  formikValues: { part0: any; part1: any };
  supportingFormsDetails: SupportingFormsDetails | undefined;
  uploadedFiles: UploadedFile[];
  onClose: () => void;
  onSuccessFn: () => void;
};

export function FormUploadConfirmationModal(
  props: FormUploadConfirmationModalProps,
) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);

  function getTypeFromMimeType(mimeType: string) {
    switch (mimeType) {
      case "application/pdf":
        return "pdf";
      case "image/png":
        return "png";
      default:
        return "jpg";
    }
  }

  const files = props.uploadedFiles.map((file) => {
    const transcodedImage = file.transcodedImage;
    const type = transcodedImage ? transcodedImage.blob.type : file.type;

    return {
      type: getTypeFromMimeType(type),
      uploadedKey: file.uploadedKey,
    };
  });

  const uploadFormRequest = {
    formTemplate: {
      id: props.formTemplate?.id!,
      version: props.formTemplate?.version!,
    },
    data: [
      props.formikValues.part0,
      ...(!isEmpty(props.formikValues.part1) ? [props.formikValues.part1] : []),
    ],
    signatureDates: [
      props.formikValues.part0.signatureDate,
      ...(!isEmpty(props.formikValues.part1)
        ? [props.formikValues.part1.signatureDate]
        : []),
    ],
    files: files as UploadFormRequest["files"],

    supportingFormsDetails: !isEmpty(props.supportingFormsDetails)
      ? props.supportingFormsDetails
      : undefined,
  };

  const submitFormFn = async () => {
    setIsSubmitting(true);
    if (!!props.uploadedFiles?.length) {
      const result = await api.upload.uploadForm(
        props.formContextId,
        props.formId,
        uploadFormRequest,
      );

      if (result.status === 204) {
        props.onSuccessFn();
        setIsSubmitting(false);
      } else {
        const errorMessage = result.data?.reason || "Unknown error";
        renderErrorToast({ message: errorMessage });
        setIsSubmitting(false);
      }
    }
  };

  const [{ data: pdfData, loading: pdfDataLoading }] =
    apiHooks.forms.uploadFormPreview(
      props.formContextId,
      props.formId,
      uploadFormRequest,
    );

  return (
    <PopupDialog
      open={true}
      onClose={props.onClose}
      testId="confirmation-dialog"
      hasPadding={false}
    >
      {confirmFn && (
        <ConfirmationModal
          message={confirmFn.message}
          confirmFn={confirmFn.confirmFn}
          closeFn={() => setConfirmFn(null)}
        />
      )}
      {!pdfDataLoading ? (
        <>
          <Box sx={{ my: 2, mx: 7.5 }}>
            <PopupDialogTitle
              titleText={"Please Confirm Submission"}
              closeDialog={props.onClose}
              removeMarginBottom
            />
          </Box>

          <MultiPagePdf data={pdfData!} />
          <Box
            sx={{
              mb: 3,
              width: "100%",
              position: "sticky",
              bottom: "0",
              display: "flex",
              justifyContent: "space-between",
              zIndex: 99,
              overflowY: "auto",
              background: "white",
              padding: 1,
            }}
          >
            <Button
              label="Go Back"
              testId="close-button"
              onClick={props.onClose}
              variant="outlined"
            />
            <Button
              label="Upload Form"
              testId="confirm-button"
              disabled={isSubmitting}
              onClick={() => {
                setConfirmFn({
                  message:
                    "You are about to upload this form to Thalamos. Please make sure all pages are included, that they are clear and legible and that you have followed local processes for handling the original form. You cannot make any changes to the uploaded form once you confirm.",
                  confirmFn: submitFormFn,
                });
              }}
            />
          </Box>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </PopupDialog>
  );
}
