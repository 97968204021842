import { TextboxFormBuilderField } from "@aspire/common";
import { css } from "@emotion/react";
import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { FieldProps } from "../../pages/FieldProps.js";
import { TextField } from "../design-system/TextField/TextField.js";

export type TextboxFormFieldProps<Data extends { [k: string]: any }> = {
  field: TextboxFormBuilderField<Data>;
  fieldProps: FieldProps<Data>;
  autoFocus?: boolean;
};

export function TextboxFormField<Data extends { [k: string]: any }>({
  field,
  fieldProps,
  autoFocus,
}: TextboxFormFieldProps<Data>) {
  const isError = !!(
    fieldProps.touched[field.field] && fieldProps.errors[field.field]
  );
  const value = fieldProps.values[field.field] as string;
  const theme = useTheme();

  return (
    <Box data-testid={`field:${field.field}`}>
      {field.checkboxToShowField && (
        <Box sx={{ mb: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checked={fieldProps.values[field.checkboxToShowField]}
                onChange={() => {
                  fieldProps.setValues({
                    ...fieldProps.values,
                    [field.field]: null,
                    [field.checkboxToShowField!]:
                      !fieldProps.values[field.checkboxToShowField!],
                  });
                }}
              />
            }
            label={
              <Typography
                data-testid="label-id"
                css={css`
                  font-weight: bold;
                  color: ${isError
                    ? theme.palette.error.main
                    : theme.palette.text.primary};
                `}
              >
                {field.label}
              </Typography>
            }
          />
        </Box>
      )}
      <Box>
        {(!field.checkboxToShowField ||
          fieldProps.values[field.checkboxToShowField!]) && (
          <>
            <TextField
              autoFocus={autoFocus ?? false}
              label={field.checkboxToShowField ? "" : field.label}
              errorMessage={
                isError ? (fieldProps.errors[field.field] as string) : undefined
              }
              showHelperText={isError || !!field.afterLabel}
              subtext={field.afterLabel}
              rows={field.rows}
              multiline={!!field.rows}
              useFullWidth={true}
              name={field.field}
              onChange={(value: string) => {
                fieldProps.setValues({
                  ...fieldProps.values,
                  [field.field]: value,
                });
              }}
              value={value || ""}
              onBlur={fieldProps.handleBlur}
            />
          </>
        )}
      </Box>
    </Box>
  );
}
