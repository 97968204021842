import React from "react";

import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Banner,
  BannerList,
  FormTitle,
} from "~/components/design-system/index.js";

export const Header = () => {
  const { t } = useTranslation();

  return (
    <Stack>
      <FormTitle
        useReducedTopPadding={true}
        hasTitleBottomMargin={false}
        titleText={t("pages.patientSearch.title")}
      />
      <Banner
        bannerType={BannerList.INFO}
        title={t("pages.patientSearchV2.banner.title")}
      />
    </Stack>
  );
};
