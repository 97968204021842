import React from "react";

import { Link as LinkIcon } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { DialogProps, useDialogs } from "@toolpad/core/useDialogs";
import { useTranslation } from "react-i18next";
import { Banner, BannerList } from "../../Banner/Banner.js";
import { PopupDialog } from "../../PopupDialog/PopupDialog.js";
import { PopupDialogTitle } from "../../PopupDialog/PopupDialogTitle.js";

export const CreateLinkButton = () => {
  const { t } = useTranslation();
  const dialogs = useDialogs();

  return (
    <Button
      variant="outlined"
      startIcon={<LinkIcon />}
      sx={{ minWidth: "max-content" }}
      onClick={async () => {
        console.log("Create link button clicked");
        await dialogs.open(CreateLinkDialog);
      }}
    >
      {t("components.externalPatientLink.createLinkButton.linkWithRio")}
    </Button>
  );
};

export function CreateLinkDialog({ open, onClose }: DialogProps) {
  const { t } = useTranslation();

  return (
    <PopupDialog open={open} onClose={() => onClose()}>
      <PopupDialogTitle
        titleText={t(
          "components.externalPatientLink.createLinkButton.dialog.title",
        )}
        closeDialog={onClose}
      />

      <Banner
        bannerType={BannerList.WARNING}
        body={t(
          "components.externalPatientLink.createLinkButton.dialog.body",
        ).split("\n")}
      />

      <Stack
        sx={{
          display: "flex",
          marginTop: "2rem",
          justifyContent: "centre",
          alignItems: "center",
        }}
      >
        <Button variant="contained" onClick={() => onClose()}>
          Close
        </Button>
      </Stack>
    </PopupDialog>
  );
}
