import * as React from "react";
import { useTranslation } from "react-i18next";
import { api } from "~/api.js";
import {
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { TermsAndConditionsModal } from "./TermsAndConditionsModal.js";
import { termsAndConditionsNeedingAcceptanceByUser } from "./util.js";

export function TermsAndConditionsAccept({}: {}) {
  const userContext = React.useContext(LoggedInUserContext)!;

  const { t } = useTranslation();

  const requiredTermsAndConditions = termsAndConditionsNeedingAcceptanceByUser(
    userContext.user,
  );

  if (!requiredTermsAndConditions) {
    return null;
  }

  return (
    <TermsAndConditionsModal
      termsAndConditionsId={requiredTermsAndConditions.id}
      needsAcceptance={true}
      open={true}
      onClose={async () => {
        const result = await api.termsAndConditions.accept({
          termsAndConditionsId: requiredTermsAndConditions.id,
        });

        if (result.status === 204) {
          renderSuccessToast({
            message: t("layout.termsAndConditionsModal.acceptanceSuccess"),
          });
          userContext.refetchUser();
        } else {
          renderErrorToast({
            message: t("layout.termsAndConditionsModal.acceptanceError"),
          });
        }
      }}
    />
  );
}
