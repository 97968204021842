import {
  Hospital,
  HospitalFormBuilderField,
  HospitalSchema,
  MhFormContext,
} from "@aspire/common";
import { Box } from "@mui/material";
import { getIn } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { config } from "../../config.js";
import { ManagedLocationsContext } from "../../Contexts.js";
import { FieldProps } from "../../pages/FieldProps.js";
import { AddressField } from "../design-system/AddressField/AddressField.js";
import { FormLabel } from "../design-system/FormLabel.js";
import { HelperText } from "../design-system/HelperText/HelperText.js";
import { Typeahead } from "../design-system/Typeahead/Typeahead.js";

export type HospitalPickerProps<Data extends { [k: string]: any }> = {
  field: HospitalFormBuilderField<Data>;
  fieldProps: FieldProps<Data>;
};

type HospitalAndCombined = Hospital & { combined: string };

export function HospitalPicker<Data extends { [k: string]: any }>({
  field,
  fieldProps,
}: HospitalPickerProps<Data>) {
  const selected = fieldProps.values[field.field] as HospitalSchema | undefined;
  const { t } = useTranslation();

  const managedLocationsContext = React.useContext(ManagedLocationsContext);

  const hospitals: HospitalAndCombined[] =
    managedLocationsContext.managedLocations
      .filter((location) => location.type === "hospital")
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((location) => {
        const hospitalAddress =
          location.trustStatus === "active" && location.managingOrganisation
            ? `${location.managingOrganisation}, ${location.address}`
            : location.address;

        return {
          name: location.name,
          address: {
            address: hospitalAddress,
            postalCode: location.postalCode,
          },
          combined: `${location.name}, ${hospitalAddress}`,
        };
      });

  const [address, setAddress] = useState({
    address: selected?.address || "",
    postalCode: selected?.postalCode || "",
    isConfirmed: selected?.isConfirmed || false,
  });

  // Update form state of address from local component state of address
  // TODO: remove local component state. This is to workaround issues with
  // the iteraction between the address picker widget and formik. Fix is likely
  // to switch to the data-only hook version of the places lookup API.
  useEffect(() => {
    if (
      address.address !== (selected?.address || "") ||
      address.postalCode !== (selected?.postalCode || "") ||
      address.isConfirmed !== (selected?.isConfirmed || false)
    ) {
      fieldProps.setFieldTouched(`${field.field}.address`, true, false);
      fieldProps.setFieldTouched(`${field.field}.postalCode`, true, false);

      const newValues = {
        ...fieldProps.values,
        [field.field]: { ...(selected || {}), ...address },
      };
      fieldProps.setValues(newValues);
    }
  }, [JSON.stringify(address)]);

  // Set default value if there is no value and the field definition has a defaultFn
  useEffect(() => {
    if (
      !selected?.name &&
      !selected?.address &&
      !selected?.postalCode &&
      !selected?.isConfirmed &&
      field.defaultFn
    ) {
      let defaultValue = field.defaultFn(
        fieldProps.values,
        fieldProps.context as MhFormContext,
      );
      if (defaultValue) {
        setAddress({
          address: defaultValue?.address ?? "",
          postalCode: defaultValue?.postalCode ?? "",
          isConfirmed: false,
        });
        fieldProps.setValues({
          ...fieldProps.values,
          [field.field]: { ...defaultValue, isConfirmed: false },
        });
      }
    } else if (selected === undefined) {
      setAddress({
        address: "",
        postalCode: "",
        isConfirmed: false,
      });
      fieldProps.setValues({
        ...fieldProps.values,
        [field.field]: { name: "", isConfirmed: false },
      });
    }
  }, [JSON.stringify(selected)]);

  const nameErrors =
    getIn(fieldProps.touched, `${field.field}.name`) &&
    getIn(fieldProps.errors, `${field.field}.name`);

  const addressErrors =
    getIn(fieldProps.touched, `${field.field}.address`) &&
    getIn(fieldProps.errors, `${field.field}.address`);

  const postalCodeErrors =
    getIn(fieldProps.touched, `${field.field}.postalCode`) &&
    getIn(fieldProps.errors, `${field.field}.postalCode`);

  const confirmErrors =
    getIn(fieldProps.touched, `${field.field}.isConfirmed`) &&
    getIn(fieldProps.errors, `${field.field}.isConfirmed`);

  return (
    <>
      {field.label && (
        <FormLabel label={field.label} error={false} tooltip={field.tooltip} />
      )}
      <Typeahead
        testId={`${field.field}.name`}
        label="Hospital Name"
        enableFuse={false}
        placeholder={t("forms.hospitalPicker.typeaheadPlaceholder")}
        errorMessage={
          nameErrors
            ? getIn(fieldProps.errors, `${field.field}.name`)
            : undefined
        }
        showHelperText={!!nameErrors}
        name={`${field.field}.name`}
        inputValue={selected?.name || ""}
        optionsKey={"combined"}
        onInputChange={(value: string | null) => {
          fieldProps.setFieldTouched(`${field.field}.name`, true, false);

          if (value) {
            const hospital = hospitals.find((h) => h.combined === value);

            if (hospital) {
              setAddress({
                address: hospital.address.address,
                postalCode: hospital.address.postalCode,
                isConfirmed: true,
              });
            } else {
              setAddress((prevState) => {
                return {
                  ...prevState,
                  isConfirmed: false,
                };
              });
            }

            fieldProps.setValues({
              ...fieldProps.values,
              [field.field]: {
                ...selected,
                ...(hospital
                  ? {
                      name: hospital.name,
                      isConfirmed: true,
                    }
                  : { name: value }),
              },
            });
          } else {
            setAddress({
              address: "",
              postalCode: "",
              isConfirmed: false,
            });
            fieldProps.setValues({
              ...fieldProps.values,
              [field.field]: { name: "" },
            });
          }
        }}
        options={hospitals}
        getOptionLabel={(hospital: HospitalAndCombined | string) => {
          if (typeof hospital === "string") {
            return hospital;
          } else {
            return hospital.combined;
          }
        }}
      />
      {!field.hideAddress && (
        <Box sx={{ mb: 3 }}>
          <AddressField
            testId={`${field.field}.address`}
            placeholder={{
              address: t("forms.hospitalPicker.addressPlaceholder"),
              postalCode: t("forms.hospitalPicker.postalCodePlaceholder"),
            }}
            addressLabel={t("forms.hospitalPicker.addressLabel")}
            postalLabel={t("forms.hospitalPicker.postalLabel")}
            subtextIcon="error"
            showHelperText={addressErrors || postalCodeErrors || confirmErrors}
            errors={{
              address: addressErrors,
              postalCode: postalCodeErrors,
              isConfirmed: confirmErrors,
            }}
            googleMapsApiKey={config.googleMapsApiKey}
            addressCheckBoxLabel={t(
              "forms.hospitalPicker.addressCheckBoxLabel",
            )}
            name={field.field}
            value={address}
            onChange={setAddress}
          />
          {field.afterLabel && <HelperText subtext={field.afterLabel} />}
        </Box>
      )}
    </>
  );
}
