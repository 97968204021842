import { TermsAndConditions, UUID } from "@aspire/common";
import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import { apiHooks } from "~/api.js";
import {
  Button,
  LoadingSpinner,
  PopupDialog,
  PopupDialogTitle,
} from "../../design-system/index.js";

export type TermsAndConditionsModalInnerProps = {
  termsAndConditions: TermsAndConditions;
  onClose: () => void;
  needsAcceptance: boolean;
  open: boolean;
};

export function TermsAndConditionsModalInner({
  termsAndConditions,
  onClose,
  needsAcceptance,
  open,
}: TermsAndConditionsModalInnerProps) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const label = needsAcceptance
    ? t("buttonLabels.continue")
    : t("buttonLabels.close");

  return (
    <PopupDialog
      title={t("pages.patientEditCreate.updateSuccess")}
      open={open}
      onClose={() => {}}
    >
      {!needsAcceptance && (
        <PopupDialogTitle
          closeDialog={onClose}
          titleText="Terms of Use"
        ></PopupDialogTitle>
      )}
      <Markdown>{termsAndConditions?.markdown}</Markdown>
      {needsAcceptance && (
        <FormControlLabel
          control={
            <Checkbox
              // @ts-ignore
              inputProps={{ "data-testid": "terms-and-conditions-checkbox" }}
              checked={checked}
              onClick={() => setChecked(!checked)}
            />
          }
          label={t("layout.termsAndConditionsModal.confirmationText")}
        />
      )}
      <Button
        testId="continue-terms-and-conditions"
        label={label}
        onClick={() => {
          onClose();
        }}
        disabled={!checked && needsAcceptance}
      />
    </PopupDialog>
  );
}

export function TermsAndConditionsModal(props: {
  termsAndConditionsId: UUID;
  onClose: () => void;
  needsAcceptance: boolean;
  open: boolean;
}) {
  const [{ data: termsAndConditions, loading: loadingTermsAndConditions }] =
    apiHooks.termsAndConditions.get(props.termsAndConditionsId);

  return loadingTermsAndConditions ? (
    <PopupDialog title="Loading" open={props.open} onClose={() => {}}>
      <LoadingSpinner />
    </PopupDialog>
  ) : (
    <TermsAndConditionsModalInner
      termsAndConditions={termsAndConditions!}
      onClose={props.onClose}
      needsAcceptance={props.needsAcceptance}
      open={props.open}
    />
  );
}
