import React from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import {
  Banner,
  BannerList,
  Button,
  FormTitle,
  PopupDialog,
} from "~/components/design-system/index.js";

export function ConfirmationModal({
  message,
  confirmFn,
  closeFn,
}: {
  message: string;
  confirmFn: () => void;
  closeFn: () => void;
}) {
  return (
    <PopupDialog open={true} onClose={closeFn} testId="confirmation-dialog">
      <Box display="flex" flexDirection="column" sx={{ padding: "1em" }}>
        <IconButton
          aria-label={"Close"}
          onClick={closeFn}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <FormTitle
          useReducedTopPadding={true}
          hasContainerMarginBottom={false}
          showBorder={false}
          isAlignedLeft={false}
          titleText={"Please Confirm Your Action"}
        />
        <Box sx={{ marginLeft: "2em", marginRight: "2em" }}>
          <Banner title={message} bannerType={BannerList.WARNING} />
        </Box>
        <Box
          sx={{
            marginTop: "2em",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            label="Close"
            testId="close-button"
            onClick={closeFn}
            color={"inherit"}
          />
          <Button label="Confirm" testId="confirm-button" onClick={confirmFn} />
        </Box>
      </Box>
    </PopupDialog>
  );
}
