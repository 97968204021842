import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router";
import { v4 } from "uuid";
import { apiHooks } from "~/api.js";
import {
  Button,
  ButtonIcon,
  LoadingSpinner,
} from "~/components/design-system/index.js";
import { routeFns } from "~/routes.js";

export function TermsAndConditionsManagementPage() {
  const navigate = useNavigate();
  const [{ data: termsAndConditions, loading: loading }] =
    apiHooks.termsAndConditions.getAll();

  return (
    <Box>
      <Box
        sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}
      >
        <Typography variant="h3">Terms and Conditions</Typography>
        <Button
          endIcon={ButtonIcon.add}
          label="Create"
          onClick={() => navigate(routeFns.termsAndConditionsCreateEdit(v4()))}
        />
      </Box>
      {loading || !termsAndConditions ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        <TableContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>Display Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {termsAndConditions!.termsAndConditions.map(
                ({ id, humanReadableName, createdAt }) => (
                  <TableRow key={id}>
                    <TableCell>{humanReadableName}</TableCell>
                    <TableCell>
                      {dayjs(createdAt).format("DD/MM/YYYY HH:mm:ss")}
                    </TableCell>
                    <TableCell>
                      <Button
                        label="View"
                        onClick={() =>
                          navigate(routeFns.termsAndConditionsCreateEdit(id))
                        }
                      />
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
}
