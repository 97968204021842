import { Stack } from "@mui/material";
import { DialogProps } from "@toolpad/core/useDialogs";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Banner,
  BannerList,
  Dropdown,
  PopupDialog,
  PopupDialogTitle,
  TextField,
} from "~/components/design-system/index.js";
import { FormFooterSection } from "~/components/form/index.js";

export type Reasoning =
  | { type: "cancelled" }
  | {
      type: "confirmed";
      reasoning:
        | { reason: Exclude<Reasons, "other"> }
        | { reason: "other"; description: string };
    };

export function SearchReasoningDialog({
  open,
  onClose,
}: DialogProps<unknown, Reasoning>) {
  const { t } = useTranslation();

  // The available reasons
  const reasons = useReasons();

  // The chosen reason
  const [reason, setReason] = useState<Reasons | null>(null);

  // The provded description for the "other" reason
  const [reasonDescription, setReasonDescription] = useState<
    string | undefined
  >(undefined);

  // Whether enough information has been provided to submit the form
  const canSubmit =
    reason !== null &&
    (reason !== "other" ||
      (reasonDescription !== undefined && reasonDescription !== ""));

  return (
    <PopupDialog open={open} onClose={() => onClose({ type: "cancelled" })}>
      <PopupDialogTitle
        titleText={t("pages.patientSearch.searchReasons.popupDialogTitle")}
        closeDialog={() => onClose({ type: "cancelled" })}
      />

      <Banner
        bannerType={BannerList.INFO}
        body={[t("pages.patientSearch.searchReasons.popupDialogBody")]}
      />

      <Stack>
        <Dropdown
          name={"invite"}
          label={t("pages.patientSearch.searchReasons.dropdownLabel")}
          values={reasons}
          selectedValue={reason}
          onChange={setReason}
        />
        {reason === "other" && (
          <TextField
            name={"reasonDescription"}
            label={t(
              "pages.patientSearch.searchReasons.otherReasonTextfieldLabel",
            )}
            value={reasonDescription ?? ""}
            onChange={setReasonDescription}
            useFullWidth={true}
          />
        )}
      </Stack>

      <FormFooterSection
        customFooterBackgroundColor="common.white"
        saveLabel={t("buttonLabels.confirm")}
        disableSubmit={!canSubmit}
        onSave={async () => {
          if (canSubmit) {
            if (reason === "other") {
              onClose({
                type: "confirmed",
                reasoning: { reason: "other", description: reasonDescription! },
              });
            } else {
              onClose({ type: "confirmed", reasoning: { reason } });
            }
          }
        }}
        discardLabel={t("buttonLabels.close")}
        onCancel={() => onClose({ type: "cancelled" })}
      />
    </PopupDialog>
  );
}

export type Reasons =
  | "emergencyOnCall"
  | "adminAddDetails"
  | "referral"
  | "New episode of care/new form context"
  | "auditInvestigationComplaint"
  | "technicalSupport"
  | "other";

const useReasons: () => {
  label: string;
  value: Reasons;
}[] = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        label: t(
          "pages.patientSearch.searchReasons.searchReasonsValues.emergencyOnCall",
        ),
        value: "emergencyOnCall",
      },
      {
        label: t(
          "pages.patientSearch.searchReasons.searchReasonsValues.adminAddDetails",
        ),
        value: "adminAddDetails",
      },
      {
        label: t(
          "pages.patientSearch.searchReasons.searchReasonsValues.referral",
        ),
        value: "referral",
      },
      {
        label: t(
          "pages.patientSearch.searchReasons.searchReasonsValues.newEpisodeOfCare",
        ),
        value: "New episode of care/new form context",
      },
      {
        label: t(
          "pages.patientSearch.searchReasons.searchReasonsValues.auditInvestigationComplaint",
        ),
        value: "auditInvestigationComplaint",
      },
      {
        label: t(
          "pages.patientSearch.searchReasons.searchReasonsValues.technicalSupport",
        ),
        value: "technicalSupport",
      },
      {
        label: t("pages.patientSearch.searchReasons.searchReasonsValues.other"),
        value: "other",
      },
    ],
    [t],
  );
};
