import {
  COMPLETED_FORM_STATUSES,
  ExtendedForm,
  findFormsMerged,
  FormContextData,
  FormPartTemplate,
  MergedPatientData,
  SelectedFormsObject,
  ValidationOutcome,
} from "@aspire/common";
import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { firstBy } from "thenby";
import {
  Banner,
  BannerList,
  Dropdown,
  LoadingSpinner,
} from "~/components/design-system/index.js";
import { apiHooks } from "../../api.js";
import { FormContextPdfViewer } from "../FormProgressPage/helpers/FormContextPdfViewer.js";
import { DialogType } from "../NewFormDialog.js";
import { FormCheckboxRow } from "./AdmissionFormCheckboxRow.js";
export function FormSupportingDocumentsSelector({
  linkableForms,
  formContext,
  setValidationOutcome,
  setSupportingFormsProvided,
  setSelectedForms,
  selectedForms,
  setNotSelectedReason,
  supportingFormsProvided,
  notSelectedReason,
  dialogType,
}: {
  linkableForms: FormPartTemplate<object>["signing"]["linkableForms"];
  formContext: Omit<FormContextData, "patient">;
  supportingFormsProvided: boolean;
  setSupportingFormsProvided: (value: boolean) => void;
  selectedForms: SelectedFormsObject[];
  setSelectedForms: (value: SelectedFormsObject[]) => void;
  notSelectedReason: string;
  setNotSelectedReason: (value: string) => void;
  setValidationOutcome: (validationOutcome: ValidationOutcome[]) => void;
  dialogType?: DialogType;
}) {
  const { t } = useTranslation();

  const [pdfViewFormId, setPdfViewFormId] = useState<null | string>(null);

  // Load relevant (linkable) patient forms
  let patientFormsResponse:
    | {
        forms: Omit<ExtendedForm<any>, "patient">[];
        mergedPatientData: MergedPatientData;
      }
    | undefined
    | null;
  let patientFormsLoading;

  [{ data: patientFormsResponse, loading: patientFormsLoading }] =
    apiHooks.patients.getForms(formContext.patientId, {
      formTemplateIds: linkableForms!.formTemplateIds,
      statuses: COMPLETED_FORM_STATUSES,
      completedAfter: dayjs
        .utc()
        .subtract(2, "weeks")
        .startOf("day")
        .toISOString(),
    });

  const relevantForms = useMemo(() => {
    const forms =
      linkableForms?.source === "form-context"
        ? formContext.forms.filter((f) => {
            return dialogType === "record-of-detention"
              ? f.status === "complete" &&
                  linkableForms.formTemplateIds.includes(f.formTemplate.id)
              : linkableForms.formTemplateIds.includes(f.formTemplate.id);
          })
        : patientFormsResponse?.forms;

    return (forms ?? []).sort(firstBy("updated", -1));
  }, [patientFormsResponse, linkableForms?.source]);

  const validationOutcome = useMemo(
    () =>
      linkableForms!.checkValidityFn(
        relevantForms.filter((f) =>
          selectedForms.some((s) => s.id.includes(f.id)),
        ) || [],
      ),
    [relevantForms, selectedForms, linkableForms!.checkValidityFn],
  );

  // If there is only one possible form to pick form and only one form is required
  // then pre-select it.
  useEffect(() => {
    if (
      relevantForms.length === 1 &&
      selectedForms.length === 0 &&
      !linkableForms?.isMultiSelect
    ) {
      setSelectedForms(
        relevantForms.map((f) => ({
          id: f.id,
          templateId: f.formTemplate.id,
        })),
      );
    }
  }, [relevantForms]);

  useEffect(() => {
    if (supportingFormsProvided) setSelectedForms([]);
  }, [supportingFormsProvided, setSelectedForms]);

  useEffect(() => {
    setValidationOutcome(validationOutcome);
  }, [setValidationOutcome, validationOutcome]);

  const bodyValidationOutcome = (outcome: ValidationOutcome) => {
    const reason = outcome.reason;
    if (!reason) return "";

    const isOutcomeSuccessOrError =
      outcome.type === "success" || outcome.type === "error";

    if (typeof reason === "string" && isOutcomeSuccessOrError) {
      return [reason];
    }

    if (isOutcomeSuccessOrError) return reason;
  };

  if (patientFormsLoading) {
    return <LoadingSpinner />;
  }

  const isBypassable = linkableForms?.isBypassable;

  const relevantMergedForms = relevantForms
    .filter((form) =>
      findFormsMerged(
        patientFormsResponse?.mergedPatientData,
        formContext,
        form,
      ),
    )
    .filter((f) => selectedForms.find((s) => s.id === f.id));

  return (
    <Box data-testid="supporting-documents-selector">
      {!!relevantMergedForms.length && (
        <Box sx={{ mb: 2 }}>
          <Banner
            title={t(
              "pages.formDraftSignPage.supportingDocuments.patientMergeWarningMessage",
            )}
            bannerType={BannerList.WARNING}
          />
        </Box>
      )}
      {!supportingFormsProvided && (
        <Box sx={{ mb: 2 }}>
          <FormGroup>
            {validationOutcome.map((validationOutcome) => (
              <Banner
                sx={{ mb: 2 }}
                bannerType={
                  validationOutcome.type === "warning"
                    ? BannerList.WARNING
                    : validationOutcome.type === "error"
                      ? BannerList.ERROR
                      : BannerList.CONFIRMATION
                }
                title={
                  validationOutcome.type === "success"
                    ? t(
                        "pages.formDraftSignPage.supportingDocuments.validationSuccess.messageTitle",
                      )
                    : validationOutcome.type === "error"
                      ? t(
                          "pages.formDraftSignPage.supportingDocuments.validationError.messageTitle",
                        )
                      : [validationOutcome.reason].flat(2).join(" ")
                }
                body={bodyValidationOutcome(validationOutcome)}
                info={
                  validationOutcome.type === "success"
                    ? t(
                        "pages.formDraftSignPage.supportingDocuments.validationSuccess.messageInfo",
                      )
                    : undefined
                }
              />
            ))}
          </FormGroup>
        </Box>
      )}
      {isBypassable && (
        <Box sx={{ my: 2 }}>
          <FormControlLabel
            control={
              <Switch
                sx={{
                  "& .MuiSwitch-track": {
                    backgroundColor: "gray",
                  },
                }}
                checked={supportingFormsProvided}
                onClick={() => {
                  setSupportingFormsProvided(!supportingFormsProvided);
                  setNotSelectedReason("");
                }}
              />
            }
            label={t("pages.formDraftSignPage.medicalRecommendationsLabel")}
          />
        </Box>
      )}
      {supportingFormsProvided && isBypassable && (
        <Box sx={{ mb: 2 }}>
          <Dropdown
            selectedValue={notSelectedReason}
            name={"not-provided-reason"}
            values={[
              { value: "completedOnPaper", label: "Completed on paper" },
              { value: "other", label: "Other" },
            ]}
            onChange={(e) => {
              setNotSelectedReason(e);
            }}
          />
        </Box>
      )}
      {relevantForms &&
        relevantForms!.map((f) => {
          const checked = selectedForms.some((s) => s.id.includes(f.id));

          const isMultiSelect = linkableForms?.isMultiSelect;

          const shouldSingleSelectCheckBox = supportingFormsProvided;

          const patientResponse = patientFormsResponse?.mergedPatientData;
          const merged = findFormsMerged(patientResponse, formContext, f);

          return (
            <>
              <FormCheckboxRow
                setSelectedForms={setSelectedForms}
                setPdfViewFormId={setPdfViewFormId}
                form={f}
                formContext={formContext}
                checked={checked}
                isMultiSelect={isMultiSelect!}
                isSingleSelectCheckBox={shouldSingleSelectCheckBox}
                lastMerged={merged?.lastMerged}
              />
            </>
          );
        })}
      {relevantForms && pdfViewFormId && (
        <FormContextPdfViewer
          inModal={true}
          forms={relevantForms
            .filter((f) => f.status !== "deleted")
            .sort((f1, f2) => (f1.updated < f2.updated ? 1 : -1))}
          setFormId={setPdfViewFormId}
          formId={pdfViewFormId}
        />
      )}
    </Box>
  );
}
