import {
  Accreditation,
  applicationTemplateIds,
  BaseFormTemplate,
  COMPLETED_FORM_STATUSES,
  ExtendedThalamosUser,
  ExtendedWorkItem,
  FormContextData,
  FormContextMiniForm,
  formHasSignatures,
  formIsApplication,
  formIsCompleted,
  formIsInProgress,
  formIsMedRec,
  formIsRecordOfDetention,
  FormPartTemplate,
  GenericFormData,
  getBaseFormTemplate,
  getFullFormName,
  getLatestVersion,
  H3Part1DataSchema,
  isGuestUserSession,
  medicalRecommendationTemplateIds,
  recordOfDetentionTemplateIds,
  Team,
  UserOrTeamRequirement,
  UUID,
  WorkItemMiniForm,
} from "@aspire/common";
import dayjs from "dayjs";
import { intersection } from "lodash-es";
import { firstBy } from "thenby";
import { config } from "../../../config.js";

interface AdmissionsFormData {
  template: BaseFormTemplate;
  genericFormData: GenericFormData;
  isMedicalRecommendation: boolean;
  latestVersionHasSignatures: boolean;
  formNeedsMoreSignatures: boolean;
  myFormWorkItem: WorkItemMiniForm | undefined;
  canLaunchDraft: boolean;
  canRequestAmend: boolean;
  isFormFinalised: boolean;
  isAmend: boolean;
  draftIdOnNextPart: UUID | null;
}

interface UserAndWorkItemState {
  amInTeamContext: boolean;
  myTeamId?: UUID;
  userId: UUID;
  teamActiveWorkItem?: ExtendedWorkItem;
  teamActiveOrArchivedWorkItem?: ExtendedWorkItem;
  myActiveWorkItem?: ExtendedWorkItem;
  inferredTeamType?: Team["type"];
  inferredAccreditationTypes: Accreditation["type"][];
  matchesSigningReqs: (reqs: UserOrTeamRequirement) => boolean;
  amGuest: boolean;
  amAmhpUser: boolean;
  amDoctor: boolean;
  amMha: boolean;
  amInWardTeam: boolean;
  isAcdContext: boolean;
}

function computeUserAndWorkItemState(
  user: ExtendedThalamosUser,
  workItem: ExtendedWorkItem | undefined,
): UserAndWorkItemState {
  const isAcdContext = !!user.sessionOrganisationConfiguration?.acdEnabled;

  // Work item state
  const workItemIsActive = workItem?.status === "accepted";
  const workItemIsActiveOrArchived = ["accepted", "completed"].includes(
    workItem?.status!,
  );
  const amAssignedToWorkItem = workItem?.assignedUserId === user.id;

  const teamActiveWorkItem = workItemIsActive ? workItem : undefined;
  const teamActiveOrArchivedWorkItem = workItemIsActiveOrArchived
    ? workItem
    : undefined;
  const myActiveWorkItem = amAssignedToWorkItem
    ? teamActiveWorkItem
    : undefined;

  const workItemForm = workItem?.forms.find((f) => f.id === workItem?.formId);

  // Request state
  const requestType = workItem?.requestType;
  const isMedRecRequest = requestType === "medical-recommendation";
  const isApplicationRequest = requestType === "application";
  const isRecordOfDetentionRequest = requestType === "record-of-detention";
  const isFinaliseRequest = requestType === "finalise";
  const isPart2MedRecRequest = !!workItemForm && formIsMedRec(workItemForm);

  const myTeamType = user.sessionContext?.teamType;
  const amGuest = isGuestUserSession(user);
  const amGuestAmph = amGuest && isApplicationRequest;
  const amGuestDoctor = amGuest && (isMedRecRequest || isPart2MedRecRequest);
  const amGuestMha = amGuest && isFinaliseRequest;
  const amGuestWardTeamMember = amGuest && isRecordOfDetentionRequest;

  // User accreditations taking into account those that have been inferred from their guest request type
  const inferredAccreditationTypes = [
    ...(user.accreditations?.map((acc) => acc.type) ?? []),
    amGuestAmph && "amhp",
    amGuestDoctor && "doctor",
    // Everyone in an org with ACD enabled can do an ACD for now, as this feels like the least disgusting hack
    isAcdContext && "acd-facilitator",
  ].filter(Boolean) as Accreditation["type"][];

  // User team memberships taking into account those that have been inferred from their guest request type
  const inferredTeamType = (
    amGuest
      ? (amGuestMha && "mha") || (amGuestWardTeamMember && "ward") || undefined
      : myTeamType
  ) as Team["type"] | undefined;

  const matchesSigningReqs = (reqs: UserOrTeamRequirement) => {
    const hasValidAccreditation = !!intersection(
      reqs.accreditation ?? [],
      inferredAccreditationTypes,
    ).length;
    const hasProhibitedAccreditation = !!intersection(
      reqs.prohibitedAccreditations ?? [],
      inferredAccreditationTypes,
    ).length;
    const hasValidTeam = !!reqs.teamType?.some((t) => t === inferredTeamType);
    const hasProhibitedTeam = !!reqs.prohibitedTeamTypes?.some(
      (t) => t === inferredTeamType,
    );
    return (
      (hasValidAccreditation || hasValidTeam) &&
      !hasProhibitedAccreditation &&
      !hasProhibitedTeam
    );
  };

  return {
    teamActiveWorkItem,
    myActiveWorkItem,
    teamActiveOrArchivedWorkItem,
    amInTeamContext: user.sessionContext?.type === "team",
    myTeamId: user.sessionContext?.teamId,
    userId: user.id,
    inferredTeamType,
    inferredAccreditationTypes,
    matchesSigningReqs,
    amGuest,
    amAmhpUser: inferredAccreditationTypes.includes("amhp"),
    amDoctor: inferredAccreditationTypes.includes("doctor"),
    amMha: inferredTeamType === "mha",
    amInWardTeam: inferredTeamType === "ward",
    isAcdContext,
  };
}

function computeAdmissionFormsState(forms: FormContextMiniForm[]) {
  const medRecs = forms.filter(formIsMedRec);
  const applications = forms.filter(formIsApplication);
  const recordOfDetentions = forms.filter(formIsRecordOfDetention);
  const recordOfDetentionsInProgress = recordOfDetentions.find(
    (d) => d.status === "in-progress",
  );

  const admissionHasCompletedApplication = applications.find(formIsCompleted);
  const admissionHasCompletedRecordOfDetention =
    recordOfDetentions.find(formIsCompleted);
  const signedPartOneRecordOfDetention =
    recordOfDetentions.find(formHasSignatures);
  const patientAdmissionDateTime = (
    getLatestVersion(signedPartOneRecordOfDetention)?.data[0].data as
      | H3Part1DataSchema
      | undefined
  )?.admissionDateTime;

  // Forms grouped by completion status
  const formCanBeWorkedOn = (f: WorkItemMiniForm) => {
    return (
      f.latestVersion > 1 || // amend
      formIsRecordOfDetention(f) ||
      (formIsApplication(f) && !signedPartOneRecordOfDetention) ||
      (formIsMedRec(f) && !signedPartOneRecordOfDetention)
    );
  };
  const completeForms = forms
    .filter((f) => f.status === "complete" || f.status === "finalised")
    .sort((f1, f2) => (f1.updated < f2.updated ? 1 : -1));
  const inProgressForms = forms
    .filter(formIsInProgress)
    .filter(formCanBeWorkedOn);
  const needsPart2Forms = inProgressForms.filter(formHasSignatures);

  const completedRecordOfDetention = completeForms.find(
    formIsRecordOfDetention,
  );

  // Get the ID of the linked ids (if it exists)
  const linkedIdsRecordOfDetention =
    signedPartOneRecordOfDetention?.linkedForms?.map((form) => form.id) || [];

  const linkedFormToActiveApplication = forms.find((form) =>
    linkedIdsRecordOfDetention.includes(form.id),
  );
  const linkedIdsOfActiveApplication =
    linkedFormToActiveApplication?.linkedForms.map((l) => l.id);

  const unusedForms = completeForms.filter((f) => {
    const wasCreatedBeforeCompletedApplication =
      admissionHasCompletedApplication &&
      admissionHasCompletedApplication.versions?.[0].signatures?.[0] &&
      dayjs(f.created).isBefore(
        dayjs(
          admissionHasCompletedApplication.versions[0].signatures[0]
            .legalSignatureDate,
        ),
      );

    // If med rec has no linked form ID and the application is signed = unused form
    if (
      formIsMedRec(f) &&
      f.linkedForms.length === 0 &&
      admissionHasCompletedApplication &&
      wasCreatedBeforeCompletedApplication
    ) {
      return true;
    }
    // If application is not linked to signed H3 = unused form
    if (
      formIsApplication(f) &&
      signedPartOneRecordOfDetention &&
      !!f.linkedForms.length &&
      !linkedIdsRecordOfDetention.includes(f.id)
    ) {
      return true;
    }

    const wasCreatedBeforeCompletedRecordOfDetention =
      signedPartOneRecordOfDetention &&
      dayjs(f.created).isBefore(dayjs(signedPartOneRecordOfDetention.created));

    // If med rec is linked to an application that is not linked to signed H3 = unused forms
    if (
      formIsMedRec(f) &&
      signedPartOneRecordOfDetention &&
      wasCreatedBeforeCompletedApplication &&
      wasCreatedBeforeCompletedRecordOfDetention &&
      !!f.linkedForms.length &&
      !linkedIdsOfActiveApplication?.includes(f.id)
    ) {
      return true;
    }

    return false;
  });

  const usedForms = admissionHasCompletedApplication
    ? completeForms.filter((f) => !unusedForms.includes(f))
    : completeForms;

  const previewableForms = forms
    .filter(formHasSignatures)
    .filter(
      (f) =>
        formIsCompleted(f) || (formIsInProgress(f) && formCanBeWorkedOn(f)),
    )
    .sort(firstBy("updated"));

  return {
    medRecs,
    applications,
    recordOfDetentions,

    admissionHasCompletedApplication,
    admissionHasCompletedRecordOfDetention,
    recordOfDetentionsInProgress,
    signedPartOneRecordOfDetention,
    completedRecordOfDetention,
    patientAdmissionDateTime,

    completeForms,
    inProgressForms,
    needsPart2Forms,
    usedForms,
    unusedForms,
    previewableForms,
  };
}

function computePerFormData(
  userAndWorkItemState: UserAndWorkItemState,
  form: FormContextMiniForm,
  patientAdmissionDateTime: string | undefined,
) {
  const { userId, amMha, myActiveWorkItem, matchesSigningReqs } =
    userAndWorkItemState;

  const template = getBaseFormTemplate(form)!;
  const fullFormName = getFullFormName(template);
  const latestVersion = getLatestVersion(form);
  const myFormWorkItem = myActiveWorkItem?.forms.find((f) => f.id === form.id);

  const isFormFinalised = form.status === "finalised";

  // Form type and status
  const isMedicalRecommendation = formIsMedRec(form);
  const isApplication = formIsApplication(form);
  const isRecordOfDetention = formIsRecordOfDetention(form);
  const formInProgress = form.status === "in-progress";
  const formNeedsFinalise = form.status === "complete";

  // Signature state
  const latestVersionHasSignatures = latestVersion.signatures.length > 0;
  const formNeedsMoreSignatures = latestVersionHasSignatures && formInProgress;
  const signedLastPart =
    latestVersion.signatures.length &&
    latestVersion.signatures.at(-1)?.userId === userId;

  // Permissions on next form part
  const nextPart = latestVersion.signatures.length;
  const nextPartTemplate = template.parts[nextPart] as
    | FormPartTemplate<any>
    | undefined;
  const nextPartSigningReqs = nextPartTemplate?.signing.userOrTeamRequirement;
  const userCanSignFormType =
    !!nextPartSigningReqs && matchesSigningReqs(nextPartSigningReqs);

  // Amend status: if the form is an amend (has previous versions), then the user should only
  // be able to work on the form if they signed the next form part in the original version of the form
  const isAmend = form.versions.length > 1;
  const originalVersion = isAmend ? form.versions[0] : undefined;
  const originalVersionSig = originalVersion?.signatures[nextPart];
  const signedOriginalVersionOfPart = originalVersionSig?.userId === userId;

  const draftIdOnNextPart = myFormWorkItem?.formDraftId ?? null;
  const canWorkOnNextPart =
    formInProgress &&
    !!myActiveWorkItem &&
    (!isAmend || signedOriginalVersionOfPart) &&
    (!signedLastPart || ["acd", "mha-h3"].includes(template.id)) &&
    userCanSignFormType &&
    (myActiveWorkItem?.formId === form.id ||
      isMedicalRecommendation ||
      isRecordOfDetention);
  const canLaunchDraft = canWorkOnNextPart && !draftIdOnNextPart;

  // Compute whether form is within S15 amends period
  let isWithinSection15AmendPeriod = false;
  if (patientAdmissionDateTime && (isApplication || isMedicalRecommendation)) {
    let expiryPeriod;
    if (["mha-a11", "mha-a10"].includes(form.formTemplate.id)) {
      expiryPeriod = config.formExpiryPeriods.s15AmendSection4Expiry;
    } else {
      expiryPeriod = config.formExpiryPeriods.s15AmendSection2And3Expiry;
    }
    const now = dayjs();
    const hasExpired = dayjs(patientAdmissionDateTime)
      .add(expiryPeriod.value, expiryPeriod.unit)
      .isBefore(now);

    isWithinSection15AmendPeriod = !hasExpired;
  }

  const canRequestAmend =
    amMha &&
    formNeedsFinalise &&
    !!myActiveWorkItem &&
    isWithinSection15AmendPeriod &&
    (isMedicalRecommendation || isApplication);

  // Extract generic form data (for use in views)
  const genericFormData =
    latestVersion.data.length > 0
      ? template!.extractGenericData(latestVersion.data)
      : { admissions: {} };

  return {
    template,
    fullFormName,
    genericFormData,
    isMedicalRecommendation,
    latestVersionHasSignatures,
    formNeedsMoreSignatures,
    myFormWorkItem,
    canLaunchDraft,
    isAmend,
    canRequestAmend,
    isFormFinalised,
    draftIdOnNextPart,
  };
}

export function admissionFormContextLogic(
  formContext: Omit<FormContextData, "patient" | "lastFormUpdated">,
  user: ExtendedThalamosUser,
) {
  const isAdmissionClosed = formContext.status === "closed";

  const userAndWorkItemState = computeUserAndWorkItemState(
    user,
    formContext?.activeTeamworkWorkItem,
  );
  const {
    teamActiveWorkItem,
    teamActiveOrArchivedWorkItem,
    myActiveWorkItem,
    amAmhpUser,
    amDoctor,
    amMha,
    matchesSigningReqs,
  } = userAndWorkItemState;

  const canWorkOnForms = !!myActiveWorkItem && !isAdmissionClosed;
  const canClaim =
    !!teamActiveWorkItem && !myActiveWorkItem && !isAdmissionClosed;
  const canSendOrShareAdmission = !!teamActiveOrArchivedWorkItem;

  // Admission form state
  const {
    admissionHasCompletedApplication,
    admissionHasCompletedRecordOfDetention,
    signedPartOneRecordOfDetention,
    completedRecordOfDetention,
    recordOfDetentionsInProgress,
    patientAdmissionDateTime,
    inProgressForms,
    needsPart2Forms,
    usedForms,
    unusedForms,
    previewableForms,
  } = computeAdmissionFormsState(formContext.forms);

  const userCanSignMedRec = medicalRecommendationTemplateIds
    .map((id) => getBaseFormTemplate(id)!)
    .map((template) => template.parts[0].signing.userOrTeamRequirement)
    .some(matchesSigningReqs);

  const userCanSignApplication = applicationTemplateIds
    .map((id) => getBaseFormTemplate(id)!)
    .map((template) => template.parts[0].signing.userOrTeamRequirement)
    .some(matchesSigningReqs);

  const userCanSignRecordOfDetention = recordOfDetentionTemplateIds
    .map((id) => getBaseFormTemplate(id)!)
    .map((template) => template.parts[0].signing.userOrTeamRequirement)
    .some(matchesSigningReqs);

  // Medical reccomendations
  const medRecCanBeCreated = !admissionHasCompletedRecordOfDetention;
  const canLaunchMedicalRecommendation =
    canWorkOnForms && medRecCanBeCreated && userCanSignMedRec;
  const canRequestMedicalRecommendation =
    canWorkOnForms && medRecCanBeCreated && !userCanSignMedRec;
  const showDisabledLaunchMedRecButton =
    canClaim && medRecCanBeCreated && userCanSignMedRec;
  const showDisabledRequestMedRecButton =
    canClaim && medRecCanBeCreated && !userCanSignMedRec;

  // Applications
  const applicationCanBeCreated = !signedPartOneRecordOfDetention;
  const canLaunchApplication =
    canWorkOnForms && applicationCanBeCreated && userCanSignApplication;
  const canRequestApplication =
    canWorkOnForms &&
    applicationCanBeCreated &&
    !userCanSignApplication &&
    !amDoctor;
  const showDisabledLaunchApplicationButton =
    canClaim && applicationCanBeCreated && userCanSignApplication;
  const showDisabledRequestApplicationButton =
    canClaim && applicationCanBeCreated && !userCanSignApplication;

  // Record of Detention
  const recordOfDetentionCanBeCreated =
    !recordOfDetentionsInProgress &&
    !signedPartOneRecordOfDetention &&
    admissionHasCompletedApplication;
  const canLaunchRecordOfDetention =
    canWorkOnForms &&
    recordOfDetentionCanBeCreated &&
    userCanSignRecordOfDetention;
  const showDisabledLaunchRecordOfDetentionButton =
    canClaim && recordOfDetentionCanBeCreated && userCanSignRecordOfDetention;

  // Scrutiny
  const admissionNeedsScrutiny =
    admissionHasCompletedRecordOfDetention?.status === "complete";
  const canScrutiniseAdmission = !!(
    admissionNeedsScrutiny &&
    myActiveWorkItem &&
    amMha
  );

  // Per-form data
  const formData = new Map<string, AdmissionsFormData>();
  for (const form of formContext.forms) {
    formData.set(
      form.id,
      computePerFormData(userAndWorkItemState, form, patientAdmissionDateTime),
    );
  }

  const mayUploadFormsToExternalSystem = amMha;

  return {
    admissionHasCompletedApplication,
    admissionHasCompletedRecordOfDetention,

    amAssignedToWorkItem: !!myActiveWorkItem,

    admissionNeedsScrutiny,
    needsPart2Forms,
    amAmhpUser,
    amDoctor,
    isAdmissionClosed,
    signedPartOneRecordOfDetention,
    recordOfDetentionsInProgress,
    completedRecordOfDetention,

    // Forms
    inProgressForms,
    unusedForms,
    usedForms,
    previewableForms,
    formData,

    // Actions
    canScrutiniseAdmission,
    canSendOrShareAdmission,
    canLaunchMedicalRecommendation,
    canRequestMedicalRecommendation,
    canLaunchApplication,
    canRequestApplication,
    canLaunchRecordOfDetention,
    mayUploadFormsToExternalSystem,
    showDisabledLaunchMedRecButton,
    showDisabledLaunchApplicationButton,
    showDisabledRequestMedRecButton,
    showDisabledRequestApplicationButton,
    showDisabledLaunchRecordOfDetentionButton,
  };
}

export function standaloneFormContextLogic(
  formContext: Omit<FormContextData, "patient" | "lastFormUpdated">,
  user: ExtendedThalamosUser,
) {
  const form = formContext.forms[0]!;
  const formTemplate = getBaseFormTemplate(form)!;
  const latestVersion = getLatestVersion(form);

  const {
    teamActiveWorkItem,
    myActiveWorkItem,
    amGuest,
    amMha,
    matchesSigningReqs,
  } = computeUserAndWorkItemState(user, formContext?.activeTeamworkWorkItem);

  // Admission form state
  const { signedPartOneRecordOfDetention } = computeAdmissionFormsState(
    formContext.forms,
  );

  const fullFormName = getFullFormName(formTemplate);

  const formInProgress = form.status === "in-progress";
  const formNeedsFinalise = form.status === "complete";

  const formSigned = latestVersion.signatures.length > 0;
  const signedLastPart =
    latestVersion.signatures.length > 0 &&
    latestVersion.signatures.at(-1)?.userId === user.id;
  const nextPart = formTemplate.parts[latestVersion.signatures.length] as
    | FormPartTemplate<any>
    | undefined;
  const nextPartSigningReqs = nextPart?.signing.userOrTeamRequirement;
  const userCanSignFormType =
    amGuest || (nextPartSigningReqs && matchesSigningReqs(nextPartSigningReqs));

  const draftIdOnNextPart = myActiveWorkItem?.forms[0].formDraftId ?? null;
  const canContinueForm =
    formInProgress && draftIdOnNextPart && userCanSignFormType;
  const canWorkOnNextPart =
    formInProgress &&
    !!myActiveWorkItem &&
    (!signedLastPart || ["acd", "mha-h3"].includes(formTemplate.id)) &&
    userCanSignFormType;
  const canLaunchDraft = canWorkOnNextPart && !draftIdOnNextPart;

  const canDownloadForm = formSigned;
  const canUploadFormToExternalSystem =
    amMha && COMPLETED_FORM_STATUSES.includes(form.status);
  const canDeleteForm = !!myActiveWorkItem && !formSigned;
  const canFinaliseForm = formNeedsFinalise && !!myActiveWorkItem && amMha;
  const canSendForm =
    teamActiveWorkItem &&
    ((formInProgress && !!myActiveWorkItem && !canWorkOnNextPart) ||
      (formNeedsFinalise && !canFinaliseForm));

  return {
    fullFormName,
    draftIdOnNextPart,
    formInProgress,
    formNeedsFinalise,
    signedLastPart,
    canFinaliseForm,
    canLaunchDraft,
    canDownloadForm,
    canUploadFormToExternalSystem,
    canDeleteForm,
    canContinueForm,
    canSendForm,
    form,
    latestVersion,
    signedPartOneRecordOfDetention,
  };
}

export function canContinueAdmissionForm(
  draftIdOnNextPart: string | undefined,
  currentUserIsAssigned: boolean,
  completedRecordOfDetention: FormContextMiniForm | undefined,
  isAmend: boolean | undefined,
) {
  return (
    draftIdOnNextPart &&
    currentUserIsAssigned &&
    (!completedRecordOfDetention || isAmend)
  );
}

export function canStartAdmissionForm(
  canLaunchDraft: boolean | undefined,
  myFormWorkItem: WorkItemMiniForm | undefined,
  currentUserIsAssigned: boolean,
) {
  return canLaunchDraft && myFormWorkItem && currentUserIsAssigned;
}
