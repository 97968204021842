import {
  ExtendedThalamosUser,
  GetOrganisationResponse,
  isGuestUser,
} from "@aspire/common";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { api, apiHooks } from "~/api.js";
import {
  Button,
  ButtonIcon,
  LoadingSpinner,
  MenuFlyout,
  MenuOptionsType,
  PopupDialog,
  PopupDialogTitle,
  renderErrorToast,
  renderSuccessToast,
  Typeahead,
} from "~/components/design-system/index.js";
import { Container } from "~/components/layout/styleWrappers.js";
import { getRoleLabel } from "~/util.js";
import { ConfirmationModal } from "../../ConfirmationModal.js";
import BulkUploadOrganisationUsers from "./BulkUploadOrganisationUsers.js";

function UserManagementRow({
  orgId,
  user,
  refetchUsers,
  index,
}: {
  orgId: string;
  user: ExtendedThalamosUser;
  refetchUsers: () => {};
  index: number;
}) {
  const cardRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [modalData, setModalData] = useState<string[] | null>(null);
  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);

  const [showAllMemberships, setShowAllMemberships] = useState(false);

  const theme = useTheme();

  const options: MenuOptionsType[] = [
    {
      name: "Remove from organisation",
      onClick: async () => {
        const result = await api.organisations.removeUserFromOrganisation(
          orgId,
          user.id,
        );

        if (result.status === 204) {
          renderSuccessToast({
            message: `User ${user.name} has been removed from the organisation`,
          });
        } else {
          renderErrorToast({
            message: "Failed to remove user from organisation",
          });
        }

        refetchUsers();
      },
      icon: "delete",
      disabled: false,
    },
  ];

  return (
    <>
      <TableRow
        key={user.id}
        sx={{
          backgroundColor:
            index % 2 === 0 ? theme.palette.common.paleBlue : "white",
        }}
      >
        {confirmFn && (
          <ConfirmationModal
            message={confirmFn.message}
            confirmFn={confirmFn.confirmFn}
            closeFn={() => setConfirmFn(null)}
          />
        )}
        <PopupDialog
          open={modalData !== null}
          onClose={() => setModalData(null)}
        >
          <Box sx={{ padding: "2em" }}>
            {modalData?.map((s) => <p>{s}</p>)}
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button label={"Close"} onClick={() => setModalData(null)} />
            </Box>
          </Box>
        </PopupDialog>
        <TableCell>{user.name}</TableCell>
        <TableCell>
          {user.email} {user.mfa === "enabled" ? "(MFA)" : ""}
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {user.memberships.length === 0 ? (
              "No Memberships! This user will get an error on logging in"
            ) : (
              <>
                {user.memberships.slice(0, 2).map((m, index) => (
                  <span key={index}>
                    {`${m.type === "team" ? m.teamName : m.organisationName} (${getRoleLabel(m.role)})`}
                    {index !== user.memberships.length - 1 && ", "}
                  </span>
                ))}
                {user.memberships.length > 2 && (
                  <>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowAllMemberships(true)}
                    >
                      + {user.memberships.length - 2} more
                    </span>
                    {showAllMemberships && (
                      <>
                        {user.memberships.slice(2).map((m, index) => (
                          <span key={index + 2}>
                            {`${m.type === "team" ? m.teamName : m.organisationName} (${getRoleLabel(m.role)})`}
                            {index !== user.memberships.length - 1 && ", "}
                          </span>
                        ))}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowAllMemberships(false)}
                        >
                          (show less)
                        </span>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {user.loginCount > 0 ? (
              <>
                <span>{user.loginCount} logins</span>
                <span>
                  (
                  {`${dayjs(user.lastLoginTimestamp).format(
                    "DD/MM/YYYY HH:mm",
                  )}`}
                  )
                </span>
              </>
            ) : (
              "-"
            )}{" "}
            {user.failedGuestUserLoginCount > 0
              ? `(${user.failedGuestUserLoginCount} failed!)`
              : ""}
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {user.accreditations.map((m) => {
              return <span>{`${m.type}`}</span>;
            })}
          </Box>
        </TableCell>
        <TableCell>
          <Box ref={cardRef} onClick={() => setIsMenuOpen(true)}>
            <MenuFlyout
              cardRef={cardRef}
              options={options}
              isOpen={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
export function ManageOrganisationUsers({
  organisationData,
  refetchOrg,
}: {
  organisationData: GetOrganisationResponse;
  refetchOrg: () => void;
}) {
  const [userFilterQuery, setUserFilterQuery] = useState("");
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [downloadedUserEmails, setDownloadedUserEmails] = useState<string[]>(
    [],
  );

  const { id: orgId } = organisationData.organisation;
  const [page, setPage] = useState(0);
  const userPageSize = 10;

  const [{ data: users, loading: loadingUsers }, refetchUsers] =
    apiHooks.users.search(
      userFilterQuery,
      userPageSize,
      page * userPageSize,
      orgId,
    );

  const numPages =
    users && users.count ? Math.ceil(users.count / userPageSize) : 1;

  const handleUserSelect = async (user: ExtendedThalamosUser) => {
    const result = await api.organisations.addUserToOrganisation(
      orgId,
      user.id,
    );

    if (result.status === 204) {
      renderSuccessToast({
        message: `User ${user.name} has been added to the organisation`,
      });
    }
    refetchUsers();
    setShowAddUserModal(false);
  };

  const handleDownloadOrgTreeUserEmails = async () => {
    const result = await api.organisations.downloadAllUserEmails(orgId);
    if (result.status !== 200) {
      renderErrorToast({
        message: `Failed to download user emails ${result.data.message}`,
      });
    } else {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "user-emails.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <Container>
      <AddUserOrganisationModal
        open={showAddUserModal}
        onClose={() => setShowAddUserModal(false)}
        onUserSelect={handleUserSelect}
      />
      <BulkUploadOrganisationUsers
        open={showBulkUploadModal}
        onClose={() => setShowBulkUploadModal(false)}
        onDownload={handleDownloadOrgTreeUserEmails}
        downloadedUserEmails={downloadedUserEmails}
        orgId={orgId}
        refetchOrg={refetchOrg}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1em",
          justifyContent: "space-between",
          marginBottom: 4,
        }}
      >
        <TextField
          placeholder={"Search keyword"}
          sx={{
            width: "241px",
          }}
          value={userFilterQuery}
          onChange={(e) => {
            setUserFilterQuery(e.target.value);
            setPage(0);
          }}
        />
        <Box display={"flex"}>
          <Button
            sx={{ marginRight: "0.5em" }}
            variant={"contained"}
            label={"Bulk Add Users"}
            endIcon={ButtonIcon.add}
            onClick={() => setShowBulkUploadModal(true)}
          />
          <Button
            sx={{ marginRight: "0.5em" }}
            variant={"contained"}
            label={"Add User"}
            endIcon={ButtonIcon.add}
            onClick={() => setShowAddUserModal(true)}
          />
        </Box>
      </Box>

      {!users ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <LoadingSpinner />
        </Box>
      ) : (
        <Box>
          <TableContainer>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>
                    Org / Team Memberships
                  </TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Usage</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Accreditations</TableCell>
                  <TableCell sx={{ fontWeight: 700 }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.results?.map((user, index) => (
                  <UserManagementRow
                    orgId={orgId}
                    user={user}
                    refetchUsers={refetchUsers}
                    index={index}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              my: 4,
            }}
          >
            <button onClick={() => page > 0 && setPage(page - 1)}>&#60;</button>
            <Box sx={{ marginLeft: "1em", marginRight: "1em" }}>
              Page {page + 1} of {numPages}
            </Box>
            <button onClick={() => page < numPages - 1 && setPage(page + 1)}>
              &#62;
            </button>
          </Box>
        </Box>
      )}
    </Container>
  );
}

function AddUserOrganisationModal({
  open,
  onClose,
  onUserSelect,
}: {
  open: boolean;
  onClose: () => void;
  onUserSelect: (user: ExtendedThalamosUser) => void;
}) {
  const [searchResults, setSearchResults] = React.useState<
    ExtendedThalamosUser[]
  >([]);
  const [userValue, setUserValue] = React.useState<
    string | ExtendedThalamosUser
  >("");

  return (
    <>
      <PopupDialog open={open} onClose={onClose}>
        <PopupDialogTitle
          titleText={`Add User to Organisation`}
          closeDialog={onClose}
        />
        <Typeahead
          options={searchResults}
          name="addUser"
          enableFuse={false}
          inputValue={
            typeof userValue === "string" ? userValue : userValue?.email
          }
          optionsKey="name"
          getOptionLabel={(option) =>
            typeof option === "string"
              ? option
              : `${option.name} (${option.email})`
          }
          onInputChange={async (value: string) => {
            const match = searchResults.find(
              (u) => value === `${u.name} (${u.email})`,
            );
            if (match) {
              setUserValue(match);
            } else {
              setUserValue(value);
            }
            if (value.length > 1) {
              const results = await api.users.search(value, 10, 0);

              const filtered = results.data?.results.filter(
                (u) => !isGuestUser(u),
              );

              setSearchResults(filtered);

              if (match) {
                setUserValue(match);
              }
            } else {
              setSearchResults([]);
            }
          }}
        />
        <Button
          label={"Add"}
          disabled={typeof userValue === "string"}
          onClick={() => onUserSelect(userValue as ExtendedThalamosUser)}
        />
      </PopupDialog>
    </>
  );
}
