import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { CaseloadWork } from "@aspire/common";
import { useTranslation } from "react-i18next";
import { StatusTableCells } from "./StatusTableCells.js";

export default function RenderedTable({
  work,
  showNhsNumber,
}: {
  work: CaseloadWork;
  showNhsNumber: boolean;
}) {
  const { t } = useTranslation();
  const cellStyles = { py: "10px", fontWeight: "bold" };
  return (
    <TableContainer sx={{ mb: 2, overflowX: "auto" }} component={Paper}>
      <Table
        key={work.count}
        sx={{ minWidth: 850 }}
        aria-label="caseload table"
      >
        <TableHead>
          <TableRow sx={{ backgroundColor: "common.lightGray" }}>
            <TableCell align="left" sx={{ ...cellStyles }}>
              {t("pages.caseload.table.headingOne")}
            </TableCell>
            <TableCell align="left" sx={{ ...cellStyles }}>
              {t("pages.caseload.table.headingTwo")}
            </TableCell>
            {showNhsNumber && (
              <TableCell align="left" sx={{ ...cellStyles }}>
                {t("pages.caseload.table.headingThree")}
              </TableCell>
            )}
            <TableCell align="left" sx={{ ...cellStyles }}>
              {t("pages.caseload.table.headingFour")}
            </TableCell>
            <TableCell align="left" sx={{ ...cellStyles }}>
              {t("pages.caseload.table.headingFive")}
            </TableCell>
            <TableCell align="left" sx={{ ...cellStyles }}>
              {t("pages.caseload.table.headingSix")}
            </TableCell>
            <TableCell align="left" sx={{ ...cellStyles }}>
              {t("pages.caseload.table.headingSeven")}
            </TableCell>
          </TableRow>
        </TableHead>
        {work?.work?.map((workItem) => (
          <StatusTableCells
            key={workItem.formContextId}
            item={workItem}
            showNhsNumber={showNhsNumber}
          />
        ))}
      </Table>
    </TableContainer>
  );
}
