import {
  FormContextData,
  FormContextMiniForm,
  SelectedFormsObject,
} from "@aspire/common";
import { Box, Checkbox, Radio } from "@mui/material";
import React from "react";
import { LoggedInUserContext } from "../../Contexts.js";
import { AdmissionFormRow } from "../FormProgressPage/admission/AdmissionFormRow.js";

export function FormCheckboxRow({
  setSelectedForms,
  setPdfViewFormId,
  form,
  formContext,
  checked,
  isMultiSelect,
  isSingleSelectCheckBox,
  lastMerged,
}: {
  setSelectedForms: (params: any) => void;
  setPdfViewFormId: (params: any) => void;
  form: FormContextMiniForm;
  formContext: Omit<FormContextData, "patient">;
  checked: boolean;
  isMultiSelect: boolean;
  isSingleSelectCheckBox: boolean;
  lastMerged?: string;
}) {
  const userContext = React.useContext(LoggedInUserContext);
  return (
    <Box display="flex" alignItems="center">
      {isMultiSelect ? (
        <Checkbox
          sx={{ color: "primary.main", maxHeight: 100 }}
          data-testid={`form-row-checkbox:${form.id}`}
          onClick={() => {
            if (isSingleSelectCheckBox) {
              setSelectedForms((prevSelectedForms: SelectedFormsObject[]) => {
                const isSelected = prevSelectedForms.some(
                  (item) => item.id === form.id,
                );
                if (isSelected) {
                  return [];
                } else {
                  return [
                    {
                      id: form.id,
                      templateId: form.formTemplate.id,
                    },
                  ];
                }
              });
            } else {
              if (checked) {
                setSelectedForms((prevSelectedForms: SelectedFormsObject[]) => {
                  return prevSelectedForms.filter(
                    (item) => item.id !== form.id,
                  );
                });
              } else {
                setSelectedForms((prevSelectedForms: SelectedFormsObject[]) => {
                  return [
                    ...prevSelectedForms,
                    {
                      id: form.id,
                      templateId: form.formTemplate.id,
                    },
                  ];
                });
              }
            }
          }}
          checked={checked}
        />
      ) : (
        <Radio
          sx={{ color: "primary.main", maxHeight: 100 }}
          data-testid={`form-row-checkbox:${form.id}`}
          onClick={() =>
            setSelectedForms([
              {
                id: form.id,
                templateId: form.formTemplate.id,
              },
            ])
          }
          checked={checked}
        />
      )}

      <AdmissionFormRow
        setPdfViewFormId={setPdfViewFormId}
        form={form}
        formContext={formContext!}
        user={userContext?.user!}
        createDraftFn={() => Promise.resolve()}
        lastMerged={lastMerged}
        // We do not need to pass the timeline in this scenario as it is
        // used only for determining amend status in data edge cases, and this
        // should never apply here (ASP-1605)
        patientTimeline={null}
      />
    </Box>
  );
}
