import { domainRegex } from "@aspire/common";
import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { Banner, BannerList } from "~/components/design-system/index.js";
import { api, apiHooks } from "../../api.js";

export function ApprovedEmailDomainManage({}: {}) {
  const [{ data: domains }, refetchDomains] =
    apiHooks.configuration.getApprovedEmailDomains();

  const [newPattern, setNewPattern] = useState("");

  return domains ? (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <TextField
          sx={{ width: "100%" }}
          value={newPattern}
          onChange={(e) => setNewPattern(e.target.value)}
        />
        <Button
          disabled={!!(newPattern && !domainRegex.test(newPattern))}
          sx={{ marginLeft: "2em" }}
          variant={"contained"}
          onClick={async () => {
            await api.configuration.addApprovedEmailDomain(newPattern);
            setNewPattern("");
            refetchDomains();
          }}
        >
          <Add sx={{ marginRight: "0.25em" }} /> Add
        </Button>
      </Box>
      <Box sx={{ marginBottom: "1em" }}>
        <Banner
          title={
            "When adding an approved email, do not include characters that precede the domain (e.g. '@' or '.')"
          }
          bannerType={BannerList.INFO}
          dense={true}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Domain</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {domains.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.pattern}
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={async () => {
                      await api.configuration.removeApprovedEmailDomain(
                        row.pattern,
                      );
                      refetchDomains();
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : (
    <>Loading...</>
  );
}
