import { AuditTrailEntry, ExtendedThalamosUser } from "@aspire/common";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { ReactElement } from "react";

import { FormContextData } from "@aspire/common";
import { getNextColor, myTeamColor } from "./helpers.js";
import { DateSeparator, TeamSeparator } from "./Separator.js";

export function entries(
  user: ExtendedThalamosUser,
  rawAuditData: AuditTrailEntry[],
  getMessage: (
    audit: AuditTrailEntry,
    formContextData?: FormContextData,
  ) => string | string[] | null,
  formContextData?: FormContextData,
): ReactElement[] {
  const sortedEntries = rawAuditData.sort((a, b) => {
    return dayjs(a.timestamp).isBefore(dayjs(b.timestamp)) ? -1 : 1;
  });

  const auditData = sortedEntries.filter((auditEntry) =>
    getMessage(auditEntry, formContextData),
  );

  const elements: ReactElement[] = [];

  // Should be impossible
  if (auditData.length === 0) return [];

  let initialDate = dayjs(auditData[0].timestamp).format("DD/MM/YYYY");
  let initialTeam = auditData[0].teamName;
  const myTeamId = user.sessionContext!.teamId;

  elements.push(<DateSeparator date={initialDate} />);
  elements.push(
    <TeamSeparator
      team={initialTeam!}
      myTeam={auditData[0].teamId === myTeamId}
    />,
  );

  const participantColours = new Map<string, string>();

  participantColours.set(myTeamId, myTeamColor);

  for (const auditEntry of auditData) {
    const message = getMessage(auditEntry, formContextData);

    if (message) {
      const newTimestamp = dayjs(auditEntry.timestamp).format("DD/MM/YYYY");
      const newTeam = auditEntry.teamName;

      if (newTimestamp !== initialDate) {
        elements.push(<DateSeparator date={newTimestamp} />);
        initialDate = newTimestamp;
      }

      const myTeam = myTeamId === auditEntry.teamId;

      if (newTeam !== initialTeam) {
        elements.push(<TeamSeparator team={newTeam!} myTeam={myTeam} />);
        initialTeam = newTeam;
      }

      if (!participantColours.has(auditEntry.teamId!)) {
        participantColours.set(
          auditEntry.teamId!,
          getNextColor(participantColours),
        );
      }

      elements.push(
        <Box
          sx={{
            backgroundColor: participantColours.get(auditEntry.teamId!),
            display: "grid",
            mb: "0.5em",
            borderRadius: "0.5em",
            padding: "0.5em",
            paddingBottom: "0.7em",
            fontSize: "smaller",
            width: "80%",
            alignSelf: myTeam ? "flex-end" : "flex-start",
          }}
        >
          <Box sx={{ paddingBottom: 1 }}>
            {typeof message === "string"
              ? message
              : message.map((s) => (
                  <>
                    {s}
                    <br />
                  </>
                ))}
          </Box>
          <Box sx={{ justifySelf: "flex-end", fontSize: "7pt", mb: -1 }}>
            {auditEntry.userName
              ? `${auditEntry.organisationName === "Thalamos" ? "Thalamos Admin" : auditEntry.userName}: `
              : ""}
            {dayjs(auditEntry.timestamp).format("DD/MM/YYYY HH:mm")}
          </Box>
        </Box>,
      );
    }
  }

  return elements;
}
