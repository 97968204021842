import { PatientIndexSearchResult } from "@aspire/common";
import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { LoadingSpinner } from "~/components/design-system/LoadingSpinner.js";
import { PatientSearchResultBanner } from "../PatientIndex/SearchResultBanners.js";
import { SearchResult } from "./SearchResult.js";

export type SearchResultsProps = {
  searchResult?: PatientIndexSearchResult | "loading";
  onSelectedIndexChanged?: (index: number | null) => void;
};

export const SearchResults = ({
  searchResult,
  onSelectedIndexChanged,
}: SearchResultsProps) => {
  // This is where we store the index of the selected search result.
  // null means no result is selected.
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);

  // This is called whenever a search result is selected or deselected.
  const onResultSelected = useCallback(
    (index: number | null) => {
      // If the user has picked the index which was already selected, deselect it
      const targetIndex = selectedIndex === index ? null : index;

      setSelectedIndex(targetIndex);
      onSelectedIndexChanged?.(targetIndex);
    },
    [onSelectedIndexChanged, selectedIndex],
  );

  if (searchResult === undefined) {
    return null;
  }

  // If data is still pending, show a loading spinner
  if (searchResult === "loading") {
    return <LoadingSpinner />;
  }

  // Turn the search results into data required by the frontend components
  const searchResults = (searchResult.matchedPatients ?? []).map((patient) => ({
    type: "aspire-patient" as const,
    patient,
  }));

  return (
    <Stack gap="1rem">
      <PatientSearchResultBanner outcome={searchResult.outcome} />
      {searchResults.map((sr, i) => (
        <SearchResult
          key={i}
          searchResult={sr}
          selected={selectedIndex === i}
          setSelected={() => onResultSelected(i)}
        />
      ))}
    </Stack>
  );
};
