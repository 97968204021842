import { Box } from "@mui/material";
import React from "react";
import { Banner, BannerList } from "~/components/design-system/index.js";

export function TokenUsed({}: {}) {
  return (
    <Box data-testid="token-used">
      <Banner
        title="Token has already been used - please contact the Thalamos programme team to issue a new one"
        bannerType={BannerList.ERROR}
      />
    </Box>
  );
}
