import { DateFormBuilderField } from "@aspire/common";
import { Box } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect } from "react";
import { FieldProps } from "../../pages/FieldProps.js";
import { Banner, BannerList } from "../design-system/Banner/Banner.js";
import { DateField } from "../design-system/DateField/DateField.js";
import { HelperText } from "../design-system/HelperText/HelperText.js";

export type DateFormFieldProps<Data extends { [k: string]: any }> = {
  field: DateFormBuilderField<Data>;
  fieldProps: FieldProps<Data>;
};

export function DateFormField<Data extends { [k: string]: any }>({
  field,
  fieldProps,
}: DateFormFieldProps<Data>) {
  const isError = !!(
    fieldProps.touched[field.field] && fieldProps.errors[field.field]
  );

  const dateValue =
    fieldProps.values[field.field] && dayjs(fieldProps.values[field.field]);

  useEffect(() => {
    // if a field value is not set, make the value null so it does not auto populate
    if (!fieldProps.values[field.field]) {
      fieldProps.setValues({
        ...fieldProps.values,
        [field.field]: null,
      });
    }
  }, [field.type === "date"]);

  return (
    <Box data-testid={`field:${field.field}`}>
      <DateField
        inputFormat={"DD/MM/YYYY"}
        errorMessage={
          isError ? (fieldProps.errors[field.field] as string) : undefined
        }
        minDate={field.minimum && field.minimum(dayjs())}
        maxDate={field.maximum && field.maximum(dayjs())}
        label={field.label}
        onChange={(value: Dayjs | null) => {
          fieldProps.setFieldTouched(field.field, true, false);
          fieldProps.setValues({
            ...fieldProps.values,
            [field.field]:
              value && value?.isValid()
                ? value?.format("YYYY-MM-DD")
                : value?.toString() || null,
          });
        }}
        value={fieldProps.values[field.field] || null}
        name={field.field}
        disabled={field.disabled !== undefined ? field.disabled : false}
      />
      {field.warningFn &&
        dateValue?.isValid() &&
        field.warningFn(dateValue) && (
          <Box sx={{ mb: 4 }}>
            <Banner
              bannerType={BannerList.WARNING}
              title={field.warningFn(dateValue)!}
            />
          </Box>
        )}
      {field.afterLabel && <HelperText subtext={field.afterLabel} />}
    </Box>
  );
}
