import {
  DataSharingPartnershipId,
  DataSharingPartnershipIdSchema,
  DataSharingPartnershipWithDataControllers,
} from "@aspire/common";
import { HighlightOffOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDialogs } from "@toolpad/core/useDialogs";
import React from "react";
import { v4 } from "uuid";
import { api, apiHooks } from "~/api.js";
import {
  Button,
  ButtonIcon,
  PopupDialog,
  PopupDialogTitle,
  renderErrorToast,
  renderSuccessToast,
  TextField,
} from "~/components/design-system/index.js";
import { OrganisationPicker } from "../OrganisationPicker.js";

export default function DataSharingPartnership() {
  const dialogs = useDialogs();
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showEditDialog, setShowEditDialog] = React.useState(false);
  const [editingPartnershipId, setEditingPartnershipId] =
    React.useState<DataSharingPartnershipId | null>(null);

  const [dataPartnershipName, setDataPartnershipName] =
    React.useState<string>("");

  const [
    { data: dataSharingPartnerships, loading: loadingDataSharingPartnerships },
    refetchDataSharingPartnerships,
  ] = apiHooks.dataSharingPartnership.getAll();

  const selectedDataSharingPartnership = dataSharingPartnerships?.find(
    (partnership) => editingPartnershipId === partnership.id,
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "1rem",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h4">Data Sharing Partnerships</Typography>
        <Button
          label="Create"
          endIcon={ButtonIcon.add}
          onClick={() => setShowCreateDialog(true)}
        />
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Name</strong>
              </TableCell>
              <TableCell>
                <strong>Data Controllers</strong>
              </TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSharingPartnerships?.map((partnership) => (
              <TableRow key={partnership.id}>
                <TableCell component="th" scope="row">
                  {partnership.name}
                  <IconButton
                    onClick={async () => {
                      const confirmed = await dialogs.confirm(
                        `Are you sure you want to delete "${partnership.name}"?`,
                        {
                          okText: "Delete",
                          cancelText: "Cancel",
                        },
                      );

                      if (confirmed) {
                        const result = await api.dataSharingPartnership.delete(
                          partnership.id,
                        );

                        if (result.status === 204) {
                          refetchDataSharingPartnerships();
                          renderSuccessToast({
                            message:
                              "Data Sharing Partnership removed successfully",
                          });
                        } else {
                          renderErrorToast({
                            message: `Data Sharing Partnership removal failed ${
                              result.data
                            }`,
                          });
                        }
                      }
                    }}
                  >
                    <HighlightOffOutlined />
                  </IconButton>
                </TableCell>
                <TableCell>
                  {partnership.dataControllers.map((d, index, array) => (
                    <div key={d.id}>
                      {d.name}
                      {index !== array.length - 1 && ", "}
                      <IconButton
                        onClick={async () => {
                          const confirmed = await dialogs.confirm(
                            `Are you sure you want to remove "${d.name}" as a data controller?`,
                            { okText: "Remove", cancelText: "Cancel" },
                          );
                          if (confirmed) {
                            const result =
                              await api.dataSharingPartnership.removeDataController(
                                partnership.id,
                                d.id,
                              );

                            if (result.status === 204) {
                              refetchDataSharingPartnerships();
                              renderSuccessToast({
                                message:
                                  "Data Sharing Partnership controller removed successfully",
                              });
                            } else {
                              renderErrorToast({
                                message: `Data Sharing Partnership controller removal failed ${
                                  result.data
                                }`,
                              });
                            }
                          }
                        }}
                      >
                        <HighlightOffOutlined />
                      </IconButton>
                    </div>
                  ))}
                </TableCell>
                <TableCell align="right">
                  <Button
                    label="Add"
                    onClick={() => {
                      setShowEditDialog(true);
                      setEditingPartnershipId(partnership.id ?? null);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showCreateDialog && (
        <PopupDialog
          title={"Create Data Sharing Partnership"}
          open={true}
          onClose={() => {
            setShowCreateDialog(false);
          }}
        >
          <PopupDialogTitle
            titleText={`Create Data Sharing Partnership`}
            closeDialog={() => setShowCreateDialog(false)}
          />

          <TextField
            name="name"
            useFullWidth
            value={dataPartnershipName}
            onChange={setDataPartnershipName}
          />

          <Button
            label="Add"
            disabled={!dataPartnershipName}
            onClick={async () => {
              if (!dataPartnershipName) return;
              const result = await api.dataSharingPartnership.create(
                DataSharingPartnershipIdSchema.parse(v4()),
                dataPartnershipName,
              );

              setShowCreateDialog(false);
              if (result.status === 204) {
                refetchDataSharingPartnerships();
                renderSuccessToast({
                  message: "Data Sharing Partnership created successfully",
                });
              } else {
                renderErrorToast({
                  message: `Data Sharing Partnership creation failed ${
                    result.data
                  }`,
                });
              }
            }}
          />
        </PopupDialog>
      )}

      {showEditDialog && (
        <DataSharingPartnershipAddDialog
          setShowDialog={setShowEditDialog}
          refetchDataSharingPartnerships={refetchDataSharingPartnerships}
          selectedDataSharingPartnership={selectedDataSharingPartnership}
          showOnlyDataControllers={true}
          editingPartnershipId={editingPartnershipId}
          setEditingPartnershipId={setEditingPartnershipId}
        />
      )}
    </>
  );
}

function DataSharingPartnershipAddDialog({
  setShowDialog,
  refetchDataSharingPartnerships,
  selectedDataSharingPartnership,
  showOnlyDataControllers,
  editingPartnershipId,
  setEditingPartnershipId,
}: {
  setShowDialog: (show: boolean) => void;
  refetchDataSharingPartnerships: () => void;
  selectedDataSharingPartnership?:
    | DataSharingPartnershipWithDataControllers
    | undefined;
  showOnlyDataControllers?: boolean;
  editingPartnershipId: DataSharingPartnershipId | null;
  setEditingPartnershipId: (id: DataSharingPartnershipId | null) => void;
}) {
  const [orgData, setOrgData] = React.useState<{
    orgId?: string;
    orgName?: string;
  }>({});

  const isAddButtonDisabled = !orgData.orgId;

  return (
    <PopupDialog
      title={"Add"}
      open={true}
      onClose={() => {
        setShowDialog(false);
        if (setEditingPartnershipId) setEditingPartnershipId(null);
      }}
    >
      <PopupDialogTitle
        titleText={`Add Data Sharing Partnership ${selectedDataSharingPartnership ? `(${selectedDataSharingPartnership.name})` : ""}`}
        closeDialog={() => setShowDialog(false)}
      />
      <OrganisationPicker
        error={null}
        idField={"orgId"}
        nameField={"orgName"}
        values={orgData}
        setValues={setOrgData}
        label={"Select an organisation"}
        showOnlyDataControllers={showOnlyDataControllers}
      />

      <Button
        label="Add"
        disabled={isAddButtonDisabled}
        onClick={async () => {
          if (!orgData.orgId || !orgData.orgName || !editingPartnershipId)
            return;

          const result = await api.dataSharingPartnership.addDataController(
            editingPartnershipId,
            orgData.orgId,
          );

          setShowDialog(false);
          if (result.status === 204) {
            refetchDataSharingPartnerships();
            renderSuccessToast({
              message: "Data Sharing Partnership created successfully",
            });
          } else {
            renderErrorToast({
              message: `Data Sharing Partnership creation failed`,
            });
          }
        }}
      />
    </PopupDialog>
  );
}
