import {
  ExtendedThalamosUser,
  findFormsMerged,
  FormContextData,
  FormContextMiniForm,
  formIsApplication,
  formIsMedRec,
  formIsRecordOfDetention,
  formIsSingleMedRec,
  getBaseFormTemplate,
  getFullFormName,
  getLatestVersion,
  PatientTimelineResponse,
  UUID,
} from "@aspire/common";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Box, FormControlLabel, Switch, useTheme } from "@mui/material";
import React, { ReactElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import {
  Banner,
  BannerList,
  Button,
  ButtonIcon,
  FormTitle,
} from "~/components/design-system/index.js";
import { FormFooterSection } from "~/components/form/index.js";
import { PatientBanner } from "~/components/layout/index.js";
import { Container, ExpandMore } from "~/components/layout/styleWrappers.js";
import { userCanEditMhaStatus } from "~/pages/EditMhaStatus/EditMhaStatusPage.js";
import { api } from "../../../api.js";
import { FormCategoryRow } from "../../../components/FormCategoryRow.js";
import { LoggedInUserContext } from "../../../Contexts.js";
import { useScreenDetection } from "../../../hooks/ScreenDetection/useScreenDetection.js";
import { routeFns } from "../../../routes.js";
import { triggerDownload } from "../../../util.js";
import { AdmissionPatientDemographicsOutdatedBanner } from "../../FormDraft/helpers/comparePatientDemographics.js";
import { NewFormDialog } from "../../NewFormDialog.js";
import {
  admissionFormContextLogic,
  canContinueAdmissionForm,
  canStartAdmissionForm,
} from "../helpers/formContextLogic.js";
import { VersionFormsPdfViewer } from "../helpers/FormContextPdfViewer.js";
import { ParticipantsList } from "../helpers/ParticipantsList.js";
import { Section4Banners } from "../helpers/Section4Banners.js";
import { WorkItemStatusCard } from "../helpers/WorkItemStatusCard.js";
import { AdmissionFormRow } from "./AdmissionFormRow.js";
import { createDraftAndHandleResult } from "./helpers/index.js";

export function hasNoActiveWorkItemOrRejectedOrCancelled(
  formContext: FormContextData,
) {
  return (
    !formContext.activeTeamworkWorkItem ||
    ["rejected", "cancelled"].includes(
      formContext.activeTeamworkWorkItem.status,
    )
  );
}

function newForms({
  navigate,
  formContext,
  user,
  t,
  setShowLaunchRecordOfDetentionDialog,
  setShowLaunchMedRecDialog,
  setShowLaunchApplicationDialog,
}: {
  navigate: NavigateFunction;
  t: any;
  formContext: FormContextData;
  user: ExtendedThalamosUser;
  setShowLaunchRecordOfDetentionDialog: (show: boolean) => void;
  setShowLaunchMedRecDialog: (show: boolean) => void;
  setShowLaunchApplicationDialog: (show: boolean) => void;
}) {
  if (!formContext.activeTeamworkWorkItem) return [];

  const {
    canLaunchRecordOfDetention,
    canLaunchApplication,
    canLaunchMedicalRecommendation,
    canRequestApplication,
    canRequestMedicalRecommendation,
    showDisabledLaunchMedRecButton,
    showDisabledLaunchApplicationButton,
    showDisabledRequestMedRecButton,
    showDisabledRequestApplicationButton,
    showDisabledLaunchRecordOfDetentionButton,
  } = admissionFormContextLogic(formContext, user);

  const elements: ReactElement[] = [];
  if (canLaunchRecordOfDetention || showDisabledLaunchRecordOfDetentionButton) {
    elements.push(
      <FormCategoryRow
        buttonTestId="start-new-form-button--record-of-detention"
        title={t("pages.formPage.formRowCategory.recordofDetentionTitle")}
        subtitle={`${t("pages.formPage.formRowCategory.h3Subtitle")} ${t(
          "pages.formPage.formRowCategory.multiSections",
        )}`}
        onLaunchForm={() => {
          setShowLaunchRecordOfDetentionDialog(true);
        }}
        launchFormLabel={t("pages.formPage.startForm")}
        disabled={showDisabledLaunchRecordOfDetentionButton}
      />,
    );
  }

  if (canLaunchMedicalRecommendation || showDisabledLaunchMedRecButton) {
    elements.push(
      ...[
        <FormCategoryRow
          buttonTestId="start-new-form-button--medical-recommendation"
          title={t("pages.formPage.formRowCategory.medicalRecommendationTitle")}
          subtitle={t("pages.formPage.formRowCategory.multiSections")}
          onLaunchForm={() => {
            setShowLaunchMedRecDialog(true);
          }}
          launchFormLabel={t("pages.formPage.startForm")}
          disabled={showDisabledLaunchMedRecButton}
        />,
        <FormCategoryRow
          buttonTestId="no-form-button--medical-recommendation"
          title={"I will not make a medical recommendation"}
          subtitle={
            "Confirm if you choose not to make a medical recommendation"
          }
          onLaunchForm={() => {
            navigate(
              routeFns.formContextReasonForNotProceedingDialog(
                formContext.id,
                formContext.patientId,
              ),
            );
          }}
          launchFormLabel={"No recommendation"}
          disabled={showDisabledLaunchMedRecButton}
        />,
      ],
    );
  }

  if (canLaunchApplication || showDisabledLaunchApplicationButton) {
    elements.push(
      ...[
        <FormCategoryRow
          buttonTestId="start-new-form-button--application"
          title={t("pages.formPage.application")}
          subtitle={t("pages.formPage.formRowCategory.multiSections")}
          onLaunchForm={() => {
            setShowLaunchApplicationDialog(true);
          }}
          launchFormLabel={t("pages.formPage.startForm")}
          disabled={showDisabledLaunchApplicationButton}
        />,
        <FormCategoryRow
          buttonTestId="no-form-button--application"
          title={"I will not make an application"}
          subtitle={"Confirm if you choose not to make an application"}
          onLaunchForm={() => {
            navigate(
              routeFns.formContextReasonForNotProceedingDialog(
                formContext.id,
                formContext.patientId,
              ),
            );
          }}
          launchFormLabel={"No application"}
          disabled={showDisabledLaunchApplicationButton}
        />,
      ],
    );
  }

  if (canRequestMedicalRecommendation || showDisabledRequestMedRecButton) {
    elements.push(
      <FormCategoryRow
        buttonTestId="request-form-button--medical-recommendation"
        title={t(
          "pages.formPage.formRowCategory.requestMedicalRecommendationTitle",
        )}
        subtitle={t("pages.formPage.formRowCategory.multiSections")}
        onLaunchForm={() => {
          navigate(
            routeFns.formContextPageSendRequest(
              formContext.id,
              formContext.patientId,
              { requestType: "medical-recommendation" },
            ),
          );
        }}
        launchFormLabel={t("pages.formPage.requestForm")}
        disabled={showDisabledRequestMedRecButton}
      />,
    );
  }

  if (canRequestApplication || showDisabledRequestApplicationButton) {
    elements.push(
      <>
        <FormCategoryRow
          buttonTestId="request-form-button--application"
          title={t("pages.formPage.formRowCategory.requestApplicationTitle")}
          subtitle={t("pages.formPage.formRowCategory.multiSections")}
          onLaunchForm={() => {
            navigate(
              routeFns.formContextPageSendRequest(
                formContext.id,
                formContext.patientId,
                { requestType: "application" },
              ),
            );
          }}
          launchFormLabel={t("pages.formPage.requestForm")}
          disabled={showDisabledRequestApplicationButton}
        />
      </>,
    );
  }

  return elements;
}

export function AdmissionsFormPage({
  formContext,
  reloadFormContext,
  user,
  patientTimeline,
  reloadPatientTimeline,
}: {
  formContext: FormContextData;
  reloadFormContext: () => void;
  user: ExtendedThalamosUser;
  patientTimeline: PatientTimelineResponse | null;
  reloadPatientTimeline: () => void;
}) {
  const expiredAmendForm = formContext.forms.find(
    (f) => f.hasAmendExpired === true,
  );

  const nhsNumber = patientTimeline?.patient?.nhsNumber;

  const template = getBaseFormTemplate(
    expiredAmendForm?.formTemplate?.id || "",
  )!;
  const section = template?.section;
  const isSectionTwoOrThree = section === "2" || section === "3";

  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [pdfViewFormId, setPdfViewFormId] = useState<null | string>(null);
  const [
    showLaunchRecordOfDetentionDialog,
    setShowLaunchRecordOfDetentionDialog,
  ] = useState(false);
  const [showLaunchMedRecDialog, setShowLaunchMedRecDialog] = useState(false);
  const [showLaunchApplicationDialog, setShowLaunchApplicationDialog] =
    useState(false);

  const admissionData = admissionFormContextLogic(formContext, user);
  const {
    admissionHasCompletedApplication,
    inProgressForms,
    previewableForms,
    usedForms,
    unusedForms,
    amAmhpUser,
    canScrutiniseAdmission,
    admissionNeedsScrutiny,
    canSendOrShareAdmission,
    signedPartOneRecordOfDetention,
    admissionHasCompletedRecordOfDetention,
    recordOfDetentionsInProgress,
  } = admissionData;

  const newDraftId = useMemo(() => v4(), []);

  const [showUnusedForms, setShowUnusedForms] = useState(false);

  const [showInProgressForms, setShowInProgressForms] = useState(true);
  const [showForms, setShowForms] = useState(true);
  const [showCompletedForms, setShowCompletedForms] = useState(true);

  const createDraftFn = async (formId: string) => {
    createDraftAndHandleResult(newDraftId, formId, navigate, formContext);
  };

  const showFinaliseConfirmation = () => {
    navigate(
      routeFns.formContextPageConfirmFinaliseDialog(
        formContext.id,
        formContext.patientId,
        {
          itemName: "admission",
        },
      ),
    );
  };

  const showMhaStatusConfirmation = () => {
    navigate(
      routeFns.formContextPageEditMhaStatusDialog(
        formContext.id,
        formContext.patientId,
        {
          itemName: "admission",
        },
      ),
    );
  };

  const showDeleteConfirmation = (draftFormId: UUID) => {
    navigate(
      routeFns.formContextPageConfirmDeleteDraftDialog(
        formContext.id,
        formContext.patientId,
        { draftFormId },
      ),
    );
  };

  const showRequestAmendDialog = (formId: UUID, formTemplateId: string) => {
    navigate(
      routeFns.formContextPageRequestAmendDialog(
        formContext.id,
        formContext.patientId,
        { formId, formTemplateId },
      ),
    );
  };

  const newFormElements = newForms({
    navigate,
    t,
    formContext,
    setShowLaunchRecordOfDetentionDialog,
    setShowLaunchMedRecDialog,
    setShowLaunchApplicationDialog,
    user: user,
  });

  // Get all admission forms data
  const formData = formContext.forms
    .map((f) => {
      return getLatestVersion(f)?.data[0]?.data;
    })
    .filter((data) => data);

  const isTeamWorkItemCompletedAndFormFinalised =
    formContext?.activeTeamworkWorkItem?.status === "completed" &&
    formContext.forms?.[0]?.status === "finalised";

  const { isMobileView } = useScreenDetection();
  const showEditMhaStatus = userCanEditMhaStatus(user);

  return (
    <Box
      data-testid="form-context:admission"
      data-formcontextid={formContext.id}
    >
      {showLaunchMedRecDialog && patientTimeline && (
        <NewFormDialog
          formTemplateFilterFn={
            user.sessionOrganisationConfiguration
              ?.mhaAdmissionsJointMedRecsEnabled
              ? formIsMedRec
              : formIsSingleMedRec
          }
          allowGuestToLaunchAnyForm={true}
          existingFormContext={formContext}
          allowRequestForm={false}
          title={t("pages.formPage.admissionStandardTitle")}
          patientId={formContext.patientId}
          closeDialog={() => setShowLaunchMedRecDialog(false)}
          patientTimeline={patientTimeline}
        />
      )}
      {showLaunchApplicationDialog && patientTimeline && (
        <NewFormDialog
          formTemplateFilterFn={formIsApplication}
          maxWidth="lg"
          allowGuestToLaunchAnyForm={true}
          existingFormContext={formContext}
          allowRequestForm={false}
          title={t("pages.formPage.applicationFormTitle")}
          patientId={formContext.patientId}
          closeDialog={() => setShowLaunchApplicationDialog(false)}
          patientTimeline={patientTimeline}
        />
      )}
      {showLaunchRecordOfDetentionDialog && patientTimeline && (
        // Assume we just link to application for now
        <NewFormDialog
          allowGuestToLaunchAnyForm={true}
          formTemplateFilterFn={formIsRecordOfDetention}
          maxWidth="lg"
          existingFormContext={formContext}
          allowRequestForm={false}
          title={t("pages.formPage.recordDetentionFormTitle")}
          patientId={formContext.patientId}
          dialogType={"record-of-detention"}
          closeDialog={() => setShowLaunchRecordOfDetentionDialog(false)}
          patientTimeline={patientTimeline}
        />
      )}
      {pdfViewFormId && (
        <VersionFormsPdfViewer
          inModal={true}
          forms={previewableForms}
          setFormId={setPdfViewFormId}
          formId={pdfViewFormId}
        />
      )}
      <Box sx={{ mb: 2 }}>
        {patientTimeline && (
          <PatientBanner
            patient={formContext.patient}
            nhsNumber={nhsNumber ?? undefined}
            disableSticky
            isHorizontalLineHidden
            reloadPatientTimeline={reloadPatientTimeline}
            patientTimeline={patientTimeline}
          />
        )}
      </Box>

      {formData.length > 0 &&
        !admissionNeedsScrutiny &&
        !isTeamWorkItemCompletedAndFormFinalised && (
          <AdmissionPatientDemographicsOutdatedBanner
            patient={formContext.patient}
            formData={formData}
          />
        )}

      {recordOfDetentionsInProgress &&
        (() => {
          const recordOfDetentionPart = getLatestVersion(
            recordOfDetentionsInProgress,
          )?.data?.length;

          if (!recordOfDetentionPart) {
            return null;
          }

          const { formData, completedRecordOfDetention } =
            admissionFormContextLogic(formContext, user);

          const { draftIdOnNextPart, isAmend, myFormWorkItem, canLaunchDraft } =
            formData.get(recordOfDetentionsInProgress?.id) ?? {};

          const currentUserIsAssigned =
            formContext.activeTeamworkWorkItem?.assignedUserId === user.id;

          const canUserContinueForm = canContinueAdmissionForm(
            draftIdOnNextPart!,
            currentUserIsAssigned,
            completedRecordOfDetention,
            isAmend,
          );

          const canUserStartForm = canStartAdmissionForm(
            canLaunchDraft,
            myFormWorkItem,
            currentUserIsAssigned,
          );

          if (canUserContinueForm || canUserStartForm) {
            return (
              <RecordOfDetentionBanner
                recordOfDetentionsInProgress={recordOfDetentionsInProgress}
                formContext={formContext}
                createDraftFn={createDraftFn}
              />
            );
          }

          return null;
        })()}

      <Section4Banners formContext={formContext} />

      {section === "4" && (
        <Box sx={{ mb: 2 }}>
          <Banner
            title={t("pages.admissionFormPage.bannerTitle")}
            rightButtonTestId="amend-period-expired"
            body={[t("pages.admissionFormPage.amendPeriodExpired72Hours")]}
            bannerType={BannerList.WARNING}
          />
        </Box>
      )}

      {isSectionTwoOrThree && (
        <Box sx={{ mb: 2 }}>
          <Banner
            title={t("pages.admissionFormPage.bannerTitle")}
            rightButtonTestId="amend-period-expired"
            body={[t("pages.admissionFormPage.amendPeriodExpired14Day")]}
            bannerType={BannerList.WARNING}
          />
        </Box>
      )}

      {canScrutiniseAdmission && (
        <Box sx={{ mb: 2 }}>
          <Banner
            title={t("pages.admissionFormPage.scrutiniseAdmissionBannerTitle")}
            rightButtonLabel={t("buttonLabels.complete")}
            rightButtonTestId="mark-scrutiny-complete-button"
            onRightButton={
              showEditMhaStatus
                ? showMhaStatusConfirmation
                : showFinaliseConfirmation
            }
            bannerType={BannerList.INFO}
          />
        </Box>
      )}

      <WorkItemStatusCard
        formContext={formContext}
        reloadFormContext={reloadFormContext}
        user={user}
        amAmhpUser={amAmhpUser}
      />
      {newFormElements.length > 0 && (
        <Container addMarginBottom>
          <Box display={"flex"} alignItems="center" sx={{ mb: 2 }}>
            <FormTitle
              subtitleText={t("common.newAdmissionForm")}
              hasContainerMarginBottom={false}
              subTitleTextFontSize={2}
            />
            <ExpandMore
              role="button"
              isExpanded={showForms}
              onClick={() => setShowForms(!showForms)}
              aria-expanded={showForms}
              aria-label={t("components.cardExtended.expandMoreLabel")}
            >
              <ExpandMoreIcon sx={{ color: "primary.main" }} />
            </ExpandMore>
          </Box>
          {showForms && (
            <Box sx={{ mt: 1 }}>
              <>{newFormElements}</>
            </Box>
          )}
        </Container>
      )}

      {/* In-progress (draft) form rows */}
      {!!inProgressForms.length && (
        <Container addMarginBottom>
          <Box display={"flex"} alignItems="center" sx={{ mb: 2 }}>
            <FormTitle
              subtitleText={`In-progress forms (${inProgressForms.length})`}
              hasContainerMarginBottom={false}
              subTitleTextFontSize={2}
            />
            <ExpandMore
              role="button"
              isExpanded={showInProgressForms}
              onClick={() => setShowInProgressForms(!showInProgressForms)}
              aria-expanded={showInProgressForms}
              aria-label={t("components.cardExtended.expandMoreLabel")}
            >
              <ExpandMoreIcon sx={{ color: "primary.main" }} />
            </ExpandMore>
          </Box>
          {showInProgressForms && (
            <>
              {!!inProgressForms.length && (
                <Box sx={{ mt: theme.spacing(2) }}>
                  {inProgressForms.map((f) => {
                    const merged = findFormsMerged(
                      patientTimeline?.mergedPatientData,
                      formContext,
                      f,
                    );
                    return (
                      <AdmissionFormRow
                        key={f.id}
                        setPdfViewFormId={setPdfViewFormId}
                        form={f}
                        formContext={formContext}
                        createDraftFn={() => createDraftFn(f.id)}
                        showDeleteConfirmation={showDeleteConfirmation}
                        user={user}
                        lastMerged={merged?.lastMerged}
                        patientTimeline={patientTimeline}
                      />
                    );
                  })}
                </Box>
              )}
            </>
          )}
        </Container>
      )}

      {/* "Used" completed form rows */}
      {!!usedForms.length && (
        <Container addMarginBottom>
          <Box display={"flex"} alignItems="center" sx={{ mb: 2 }}>
            <FormTitle
              subtitleText={`Completed forms (${usedForms.length})`}
              hasContainerMarginBottom={false}
              subTitleTextFontSize={2}
            />
            {admissionHasCompletedApplication &&
              signedPartOneRecordOfDetention && (
                <Button
                  variant={"outlined"}
                  color={"primary"}
                  label="Download All"
                  endIcon={ButtonIcon.download}
                  sx={{ ml: 2, height: 40 }}
                  onClick={async () => {
                    const result = await api.forms.getPdf(
                      signedPartOneRecordOfDetention.linkedForms[0].id,
                      true,
                    );
                    if (result.status === 200) {
                      const dataUri = `data:application/pdf;base64,${result.data}`;
                      triggerDownload(dataUri, "form.pdf");
                    }
                  }}
                />
              )}
            <ExpandMore
              role="button"
              isExpanded={showCompletedForms}
              onClick={() => setShowCompletedForms(!showCompletedForms)}
              aria-expanded={showCompletedForms}
              aria-label={t("components.cardExtended.expandMoreLabel")}
            >
              <ExpandMoreIcon sx={{ color: "primary.main" }} />
            </ExpandMore>
          </Box>

          {showCompletedForms && !!usedForms.length && (
            <Box sx={{ mt: theme.spacing(2) }}>
              {usedForms.map((f) => {
                const merged = findFormsMerged(
                  patientTimeline?.mergedPatientData,
                  formContext,
                  f,
                );
                return (
                  <AdmissionFormRow
                    key={f.id}
                    setPdfViewFormId={setPdfViewFormId}
                    form={f}
                    formContext={formContext}
                    user={user}
                    showRequestAmendDialog={showRequestAmendDialog}
                    createDraftFn={() => createDraftFn(f.id)}
                    lastMerged={merged?.lastMerged}
                    patientTimeline={patientTimeline}
                  />
                );
              })}
            </Box>
          )}
        </Container>
      )}

      {/* "Unused" completed form rows */}
      {unusedForms.length > 0 && (
        <Box sx={{ mt: theme.spacing(2) }}>
          <Container addMarginBottom>
            <FormTitle
              subtitleText={`Unused forms (${unusedForms.length})`}
              hasContainerMarginBottom={false}
              subTitleTextFontSize={2}
            />
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Switch
                  checked={!showUnusedForms}
                  onClick={() => setShowUnusedForms(!showUnusedForms)}
                />
              }
              label={"Hide forms"}
            />
            {showUnusedForms && (
              <Box sx={{ mt: theme.spacing(2) }}>
                {unusedForms.map((f) => {
                  const merged = findFormsMerged(
                    patientTimeline?.mergedPatientData,
                    formContext,
                    f,
                  );
                  return (
                    <AdmissionFormRow
                      key={f.id}
                      setPdfViewFormId={setPdfViewFormId}
                      form={f}
                      formContext={formContext}
                      user={user}
                      createDraftFn={() => createDraftFn(f.id)}
                      lastMerged={merged?.lastMerged}
                      showRequestAmendDialog={showRequestAmendDialog}
                      patientTimeline={patientTimeline}
                    />
                  );
                })}
              </Box>
            )}
          </Container>
        </Box>
      )}
      <ParticipantsList formContext={formContext} />

      <FormFooterSection
        hideSubmit={!canSendOrShareAdmission}
        {...(user.sessionOrganisationConfiguration?.uploadFormsEnabled &&
          !admissionHasCompletedRecordOfDetention &&
          !hasNoActiveWorkItemOrRejectedOrCancelled(formContext) && {
            secondaryLeftButton: (
              <Button
                fullWidth={isMobileView}
                variant="outlined"
                sx={{ backgroundColor: "white" }}
                label={"Upload form"}
                onClick={() =>
                  navigate(
                    routeFns.formUploadSelectedTemplate(
                      formContext.id,
                      formContext.patientId,
                    ),
                  )
                }
              />
            ),
          })}
        isSticky={true}
        onSave={() => {
          navigate(
            routeFns.formContextPageSendRequest(
              formContext.id,
              formContext.patientId,
              {},
            ),
          );
        }}
        saveLabel={t("buttonLabels.send")}
        discardLabel={t("buttonLabels.activityLog")}
        onCancel={() => {
          navigate(
            routeFns.formContextPageActivityLog(
              formContext.id,
              formContext.patientId,
            ),
          );
        }}
      />
    </Box>
  );
}

function RecordOfDetentionBanner({
  recordOfDetentionsInProgress,
  formContext,
  createDraftFn,
}: {
  recordOfDetentionsInProgress: FormContextMiniForm;
  formContext: FormContextData;
  createDraftFn: (id: string) => Promise<void>;
}) {
  const userContext = React.useContext(LoggedInUserContext);
  const user = userContext?.user;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const recordOfDetentionTemplate = getBaseFormTemplate(
    recordOfDetentionsInProgress?.formTemplate?.id || "",
  )!;

  const recordOfDentionFullFormName = getFullFormName(
    recordOfDetentionTemplate,
  );

  const activeWorkItemForm = formContext?.activeTeamworkWorkItem?.forms.find(
    (f) => f.id === recordOfDetentionsInProgress?.id!,
  );

  const workItemDraftId = activeWorkItemForm?.formDraftId;

  return (
    <Box sx={{ mb: 2 }}>
      <Banner
        bannerType={BannerList.INFO}
        title={`Continue ${recordOfDentionFullFormName}`}
        disabledButtonRight={
          formContext?.activeTeamworkWorkItem?.assignedUserId !== user?.id
        }
        onRightButton={() => {
          workItemDraftId
            ? navigate(
                routeFns.formDraftsComplete(
                  workItemDraftId,
                  formContext.patientId,
                ),
              )
            : createDraftFn(recordOfDetentionsInProgress.id);
        }}
        rightButtonLabel={t("buttonLabels.continue")}
      />
    </Box>
  );
}
